<template>
  <el-dialog
    v-if="detailVisible"
    :title="tit"
    :visible.sync="detailVisible"
    :append-to-body="true"
    :close-on-click-modal="false"
    width="1100px"
    top="20vh"
    @close="closeDialog"
  >
    <div
      style="height: 50vh; overflow-y: scroll; padding-right: 20px"
      class="scrollElement"
    >
      <el-row>
        <el-col :span="10">
          <div class="detailBox">
            <h4>报警类型：
              {{
                detailData.sosType
                  ?( sosType.find((item) => item.value == detailData.sosType)
                    ? sosType.find((item) => item.value == detailData.sosType)
                        .label
                    : "-")
                  : "-"
              }}
            </h4>
            <p>人员名称：{{ detailData.userName }}</p>
            <p>设备号：{{ detailData.code }}</p>
            <p>报警时间：{{ detailData.sosTime }}</p>
            <p>经纬度：({{ detailData.ypoint }}, {{ detailData.xpoint }})</p>
            <p>地址：{{ detailData.address }}</p>
          </div>
        </el-col>
        <el-col :span="14">
          <div class="mapBox">
            <!-- 高德地图 -->
            <Map v-if="mapVisible" ref="map" :point="detailData.point" />
          </div>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer"></span>
  </el-dialog>
</template>
<script>
import Map from "@/components/map";
export default {
  components: {
    Map,
  },
  props: ["detailData",'sosType'],
  data() {
    return {
      tit: "报警详情",
      detailVisible: false,
      mapVisible: false,
    };
  },
  methods: {
    init() {
      this.detailVisible = true;
      this.$nextTick(() => {
        this.mapVisible = true;
      });
    },
    closeDialog() {
      this.detailVisible = false;
      this.mapVisible = false;
      this.$emit("closeInfo");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.detailBox {
  height: 44vh;
  background: #65809bb3;
  border-radius: 5px;
  padding: 25px 15px;
  color: #fff;
  h4 {
    margin: 0;
    font-size: 15px;
  }
}
.mapBox {
  padding-left: 20px;
}
</style>
