<template>
	<div>
		<el-upload v-loading="loading" :class="{'el-disabled': (!isMultiple && comsFileUploadImageList.length > 0)}"
			:action="url" :file-list="comsFileUploadImageList" :on-success="comsFileUploadImageSuccess"
			:on-remove="comsFileUploadImageRemove" :on-preview="comsFileUploadImagePreview"
			:before-upload="comsFileUploadImageBefore" :multiple="isMultiple" class="components-file-upload"
			list-type="text">
			<el-button v-if="!(!isMultiple && comsFileUploadImageList.length > 0)" size="small" type="primary"><i
					class="el-icon-plus" />点击上传</el-button>

		</el-upload>
		<!-- <el-dialog :visible.sync="comsFileUploadPreviewVisible" append-to-body>
      <img :src="comsFileUploadPreviewImageUrl" width="100%" alt>
    </el-dialog> -->
	</div>
</template>

<script>
	export default {
		props: {
			md5s: {
				type: String
			},
			url: {
				type: String
			},
			multiple: {
				type: Boolean,
				default: false
			},
			callback: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				comsFileUploadPreviewVisible: false,
				comsFileUploadPreviewImageUrl: null, // 文件地址
				comsFileUploadImageList: [], // 选择的文件列表
				hasDownloadOldImages: false, //  是否下载图片
				loading: false
			}
		},
		computed: {
			isMultiple: {
				get() {
					return this.multiple
				}
			}
		},
		watch: {
			async md5s(newVal) {
				// 直接赋值
				this.comsFileUploadImageList = [{
					url: newVal,
					name: newVal
				}]

				if (this.md5s && this.md5s != '' && !this.hasDownloadOldImages) {
					this.comsFileUploadImageList = [{
						url: this.md5s,
						name: this.md5s
					}]
					this.hasDownloadOldImages = true
				} else {
					if (!this.md5s || this.md5s == '') {
						this.comsFileUploadImageList = []
					}
					this.hasDownloadOldImages = false
				}
			}
		},
		async created() {
			if (this.md5s && this.md5s != '') {
				this.comsFileUploadImageList = [{
					url: this.md5s,
					name: this.md5s
				}]
				this.hasDownloadOldImages = true
			} else {
				this.comsFileUploadImageList = []
			}
		},
		methods: {
			async comsFileUploadImageGetUrl(md5s) {
				if (!md5s || md5s == '') return []
				this.loading = true
				const res = await this.$http({
					url: this.url,
					method: 'POST',
					data: {
						md5s: md5s
					}
				})
				this.loading = false
				if (res.code == 0) {
					return res.data.list
				}
				return []
			},
			comsFileUploadImageSuccess(res, file) {
				if (res.code === 200 && res.data.imgurl) {
					this.$emit('update:md5s', res.data.imgurl)
					if (this.callback) {
            this.comsFileUploadImageRemove(res, file)
						this.$emit(this.callback, {
							md5s: res.data.imgurl
						})
					}
				} else {
					this.$message.error(res.msg ? res.msg : '文件上传失败')
				}
			},
			comsFileUploadImageRemove(res, fileList) {
				// if (res.code === 0) {
				let _md5s = []
				fileList.forEach(item => {
					if (item.md5) {
						_md5s.push(item.md5)
					} else {
						if (item.response.data.success_list) {
							item.response.data.success_list.forEach(val => {
								_md5s.push(val.md5)
							})
						}
					}
				})
				if (_md5s.length === 0) this.comsFileUploadImageList = []
				_md5s = _md5s.join(',')
				setTimeout(() => {
					this.$emit('update:md5s', _md5s)
					if (this.callback) {
						this.$emit(this.callback, {
							md5s: _md5s
						})
					}
				}, 500)
				// }
			},
			comsFileUploadImagePreview(file) {
				this.comsFileUploadPreviewImageUrl = file.url
				this.comsFileUploadPreviewVisible = true
				window.open(file.url, '_blank');
			},
			comsFileUploadImageBefore(file) {
				var isJPG = false
				const typeArr = ["apk", "bmp", "gif", "jpg", "jpeg", "png", "swf", "flv", "mp3", "wav", "wma", "wmv", "mid", "avi", "mpg", "asf", "rm", "rmvb", "mp4", "amr","doc", "docx", "xls", "xlsx", "ppt", "pptx", "html", "htm", "txt","rar", "zip", "gz", "bz2","pdf"]
				const nameType = file.name.split('.')
				isJPG = typeArr.includes(nameType[nameType.length - 1])
				console.log(isJPG,'isJPG');
				if (!isJPG) {
					this.$message.error('文件不符合类型')
					return false
				}

				const isLt2M = file.size / 1024 / 1024 < 2
				// if (!isJPG) {
				//   this.$message.error("图片只能是 JPG/JPEG/PNG 格式!");
				// }
				if (!isLt2M) {
					this.$message.error('文件大小不能超过 2MB!')
					return false
				}
				return isLt2M && isJPG // isJPG &&
			}
		}
	}
</script>
<style lang="scss" scoped>
	.components-file-upload {
		.el-upload--picture-card {
			height: 100px;
			width: 100px;
			line-height: 104px;
		}

		.el-upload-list--picture-card .el-upload-list__item {
			height: 100px;
			width: 100px;
		}
	}

	.el-disabled .el-upload--picture-card {
		display: none;
	}

	/deep/ .el-upload--text {
		float: left;
	}

	/deep/ .el-upload-list__item-name {
		color: #fff !important;
	}

	/deep/ .el-upload-list__item-name:hover {
		color: #000 !important;
	}
</style>
