<template>
  <el-dialog
    :title="'维保项详情'"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    width="950px"
    top="15vh"
    @close="selectunitDialogClose"
  >
    <div class="admin-project-myproject-container" style="height: 40vh; overflow-y: scroll; padding-right: 20px">
      <el-form
        label-position="right"
        label-width="120px"
        ref="mainterItemForm"
        :model="mainterItemForm"
      >
        <el-row style="margin: 10px 0">
          <el-col :span="12">
            <el-form-item label="维保项名称:" prop="name">
              <el-input
                disabled
                v-model="mainterItemForm.name"
                placeholder="请输入"
                class="wid90"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="维保类型:" prop="type">
              <el-select
                disabled
                class="wid90"
                v-model="mainterItemForm.type"
                placeholder="请输入"
              >
                <el-option
                  v-for="(item, index) in maintainType"
                  :key="index"
                  :label="item.label"
                  :value="Number(item.value)"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <template v-for="(item, index) in mainterItemForm.mainItemsArr">
              <el-form-item
                :label="`标题${index + 1}:`"
                :key="index"
                :prop="'mainItemsArr.' + index + '.value'"
                :rules="{
                  required: true,
                  message:
                    '标题至少需要中文，字母，数值，特殊符号，长度为5-200',
                  trigger: 'change',
                  min: 5,
                  max: 200,
                }"
              >
                <el-input
                  disabled
                  v-model="item.value"
                  placeholder="请输入"
                  class="wid70 mr20"
                />
                <el-checkbox disabled v-model="item.checked"
                  >是否拍照</el-checkbox
                >
              </el-form-item>
            </template>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="selectunitDialogClose">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      visible: false,
      loading: true,
      selectData: [],

      // 表格参数
      searchForm: {
        name: "",
        current: 1,
        size: 10,
      },
      mainterItemForm: {
        name: '',
        mainItemsArr: [{ value: '', checked: false }],
      },
      maintainType: []
    };
  },
  created() {
    this.getDictionar(['MAINTAIN_TYPE'])
  },
  methods: {
    init(name, callback) {
      this.visible = true;
      this.loading = false;
      this.searchForm.name = name
      this.$nextTick(() => {
        this.selectUnitListGet();
      });
    },

    selectUnitListGet() {
      const data = JSON.parse(JSON.stringify(this.searchForm));
      this.loading = true;
      this.$http.post('/api/ele/web/maintainItem/getList', data).then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res;
            this.loading = false;
            console.log(data.records[0]);
            this.mainterItemForm = JSON.parse(JSON.stringify(data.records[0]))
            this.$set(
                this.mainterItemForm,
                'mainItemsArr',
                JSON.parse(data.records[0].itemCodes)
            )
          }
        });
    },
    
    // 获取字典数据
    getDictionar(code) {
      // code = code.join()
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          console.log('字典', res)
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res

            this.maintainType = data.MAINTAIN_TYPE
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },

    selectunitDialogClose() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.searchBtn {
  margin-left: 15px;
}
.wid90 {
  width: 95%;
}
.wid70 {
  width: 70%;
}
.mr20 {
  margin-right: 20px;
}
/deep/.el-checkbox {
  color: #fff;
}
/deep/.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #c7c7c7;
}
</style>
