<template>
  <el-dialog
    :title="'选择维保项'"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    width="1400px"
    @close="selectunitDialogClose"
  >
    <div class="admin-project-myproject-container">
      <el-table
        ref="selectUnitList"
        :data="selectUnitList"
        border
        stripe
        fit
        max-height="500px"
        highlight-current-row
        row-key="id"
        @select="handleSelectionChange"
        @select-all="selectAll"
      >
        <el-table-column
          type="selection"
          reserve-selection
          width="55px"
          align="center"
        />
        <el-table-column
          label="序号"
          type="index"
          prop="index"
          width="70px"
          align="center"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="value"
          label="维保项描述"
          min-width="300"
        />
      </el-table>
    </div>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        type="primary"
        @click="selectunitConfirm"
      >确 定</el-button>
      <el-button
        size="small"
        @click="visible = false"
      >取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      visible: false,
      loading: true,
      selectunitCallback: null,
      unitId: [],
      selectData: [],
      initArr: [],
      updateArr: null,
    }
  },
  props: {
    selectUnitList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  methods: {
    init(selectData, callback) {
      // console.log(id, '1231321313213213')
      this.selectunitCallback = callback
      // this.unitId = id ?? []
      this.initArr = selectData
      this.updateArr = selectData
      this.visible = true
      this.loading = false
      // this.$nextTick(() => {
      //   if (selectData.length > 0) {
      //     console.log(selectData,'selectData');
      //     console.log(this.selectUnitList,'this.selectUnitList');
      //     this.selectUnitList.forEach((ele) => {
      //       selectData.forEach((item) => {
      //         if (item.label === ele.label) {
      //           this.$refs.selectUnitList.toggleRowSelection(ele, true)
      //         }
      //       })
      //     })
      //   }
      // })
    },

    //全选
    selectAll(selection) {
      let stateArr = []

      console.log(selection, 'selection')
      if (selection.length > 0) {
        stateArr = JSON.parse(JSON.stringify(selection))
        const arr = [ ...this.initArr, ...selection]
        // 去重
        const res = new Map()
        this.updateArr = arr.filter(
          (a) => !res.has(a.label) && res.set(a.label, 1)
        )
      } else {
        stateArr = JSON.parse(JSON.stringify(this.selectUnitList))
        stateArr.map((item) => {
          console.log(this.initArr, stateArr, 'this.updateArr')
          if (this.initArr.find((row) => row.label === item.label)) {
            this.updateArr = this.updateArr.filter(
              (row) => row.label !== item.label
            )
          }
        })
      }
      console.log(this.updateArr, 'selection')
    },

    handleSelectionChange(val, row) {
      console.log(row, 'row')
      console.log(this.initArr, 'this.initArr')
      if (this.initArr.find((item) => item.value === row.value)) {
        this.updateArr = this.initArr.filter((item) => item.value !== row.value)
      } else {
        this.updateArr.push(row)
      }
      console.log(this.initArr, 'this.initArr')
    },

    async selectunitConfirm() {
      console.log(this.updateArr, 'this.initArr')
      if (this.initArr.length > 0) {
        this.$emit('transferUnit', this.updateArr)
        this.visible = false
      } else {
        this.$message({
          message: '请选择单位',
          type: 'error',
        })
      }
    },

    selectunitDialogClose() {
      this.$refs.selectUnitList.setCurrentRow()
      this.$refs.selectUnitList.clearSelection()
    },
  },
}
</script>
