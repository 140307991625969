<template>
  <div>
    <div class="page-top">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item>
          <span slot="label">
            <span class="lab-span">人员名称</span>
          </span>
          <el-input
            v-model="formInline.name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">登录名</span>
          </span>
          <el-input
            v-model="formInline.userName"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="onRefer(true)"
          >搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="dictionarTotal!=0">
      <el-row>
        <el-col :span="12">
          <div
            class="messageList scrollElement"
            style="overflow-y: scroll;"
          >
            <div
              v-for="(item, index) in tableData"
              :key="item.id"
              :class="index == idx ? 'chooseBoxStyle' : ''"
              class="messageBox"
              @click="chooseBox(item, index)"
            >
              <div class="messageTop">
                <div>
                  {{item.name}}
                </div>
                <div>
                  {{item.createTime}}
                </div>
              </div>
              <div class="messageContent">{{item.content}}</div>
            </div>
          </div>
          <Pagination
            :total="dictionarTotal"
            :page.sync="formInline.current"
            :limit.sync="formInline.size"
            @pagination="dictionarPage"
          />
        </el-col>
        <el-col :span="12">

          <div class="messageList messageData">
            <!-- 聊天记录 0 自己 1 对方 -->
            <div
              class="message-box scrollElement"
              id="message-boxs"
              ref="message-boxss"
              style="overflow-y: scroll;"
            >
              <template v-for="item in messageList">
                <el-col
                  :key="item.id"
                  :span="24"
                  class="message-item"
                  :class="item.userId != userId ? 'message-myself': '' "
                  style="color: #fff"
                >
                  <!-- 名称、时间、内容 -->
                  <div class="message-container">
                    <div class="message-time">
                      <span
                        class="span01"
                        v-if="item.userId == userId"
                        v-text="item.name"
                      />
                      <span
                        class="span02"
                        v-if="item.userId != userId"
                        v-text="item.name"
                      />
                    </div>
                    <div :class="item.userId == userId ? 'message-content' : 'message-contents'">
                      <div>
                        <span
                          v-if="+item.msgType !== 2"
                          v-text="item.msg"
                        />
                        <el-image
                          v-else
                          :src="+item.msgType === 2 ? item.msg : item.jpg"
                          @click="previewFile(item)"
                        />
                      </div>
                    </div>
                  </div>
                </el-col>
              </template>
            </div>
            <!-- 输入框 -->
            <div class="input-box">
              <el-input
                v-model="replyMessageForm.msg"
                type="textarea"
                :rows="5"
                style="height:11.5vh;"
              />
              <!-- 发送视频，图片 -->
              <div class="textBottom">
                <div class="upload-resource">
                  <!-- 照片 -->
                  <el-upload
                    class="avatar-uploader"
                    :action="url"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    accept=".jpg, .jpeg, .png"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      v-text="'图片'"
                    />
                  </el-upload>
                </div>
                <el-button
                  size="small"
                  type="primary"
                  :loading="loading"
                  @click="replyMessageConfirm"
                  v-text="'发送'"
                />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div
      class="nullDate"
      v-else
    >
      <img
        src="@/assets/nullDate.png"
        style="width: 260px;"
      />
    </div>
  </div>
</template>
<script>
// 页面对应的type
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      // 表格参数
      formInline: {
        current: 1,
        size: 10,
      },
      tableData: [], // 留言列表
      // 分页参数
      dictionarTotal: 0,
      messageList: [], // 信息列表
      idx: 0, // 选中聊天框
      userId: null, // 聊天框用户id
      // replyMessageForm: [],
      replyMessageForm: {
        feedbackId: '', // 反馈列表的user_id
        msgType: 1, // 消息类型:1文字,2图片,3视频
        msg: '', // 消息内容
      },
      url: '/api/file/web/upload',
      loading: false,
    }
  },
  methods: {
    // 重置按钮事件
    reset() {
      console.log('重置')
      this.searchForm = {
        current: 1,
        size: 10,
      }
      this.onRefer()
    },
    // 分页
    dictionarPage(data) {
      this.formInline.current = data.page
      this.formInline.size = data.limit
      this.onRefer()
    },
    // 表格方法
    onRefer(ishm) {
      this.$http
        .post('/api/system/web/feedback/getList', this.formInline)
        .then((res) => {
          if (res.data.success) {
            this.tableData = res.data.data.records
            this.dictionarTotal = res.data.data.total
            this.getMessageList(this.tableData[0].id)
            this.userId = this.tableData[0].userId
            this.replyMessageForm.feedbackId = this.tableData[0].id
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    // 信息列表查询方法
    getMessageList(id) {
      const data = {
        feedbackId: id,
      }
      this.$http.post('/api/system/web/feedback/getInfo', data).then((res) => {
        if (res.data.success) {
          this.messageList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 选择留言框
    chooseBox(item, index) {
      this.idx = index
      this.userId = item.userId
      this.replyMessageForm.feedbackId = item.id
      this.replyMessageForm.msg = '' // 清空消息框
      this.replyMessageForm.msgType = 1 // 默认就设置为1 文字类型
      this.getMessageList(item.id)
    },
    // 发送信息
    manageButton() {},

    // 照片
    // 上传文件
    handleAvatarSuccess(res, file) {
      if(!res.msg){
        this.$message.success('文件发送失败')
      }
      if (res.success) {
          // 赋值照片地址
          this.replyMessageForm.urlmsg = res.data.imgurl
          this.replyMessageForm.msgType = 2
          // 发送图片消息
          this.replyMessageConfirm()
        }
      },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      return isLt2M
    },
    // 发送消息
    async replyMessageConfirm() {

      // 请求参数
      const data = JSON.parse(JSON.stringify(this.replyMessageForm))
      if (data.msgType === 2) {
        data.msg = data.urlmsg
      }
      if (data.msg) {
        this.loading = true
        // 发送消息
        this.$http
          .post('/api/system/web/feedback/detail/add', data)
            .then((res) => {
              if (res.data.success) {
                this.replyMessageForm.msg = '' // 清空消息框
                this.replyMessageForm.msgType = 1 // 每次提交完默认就设置为1 文字类型
                // 重新请求消息列表
                setTimeout(() => {
                  this.getMessageList(this.replyMessageForm.feedbackId)
                }, 500)
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          // const res = await send_msg(data)
          // if (res.code === 0) {
          //   this.$message({
          //     message: res.msg,
          //     type: 'success',
          //   })
          // }
          this.loading = false
      }
    },
  },
  created() {
    this.onRefer()
  },
}
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-top {
  width: 100%;
  margin-bottom: 20px;
}

.page-bom {
}
.messageList {
  height: 65vh;
  max-height: 65vh;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
  margin-right: 15px;
  margin-bottom: 15px;
  overflow: hidden;
  .messageBox {
    padding: 15px 20px;
    border-bottom: 1px solid #ccc;
    .messageTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px 0;
    }
    .messageContent {
      margin-top: 10px;
    }
  }
  .chooseBoxStyle {
    background: #1282a5;
  }
}

.messageData {
  margin: 0 0 0 15px;
}
/deep/.upload-resource {
  display: flex;
  z-index: 2;
  margin-right: 15px;
}

.span01 {
  width: 82px;
  height: 15px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #fff;
  margin-left: 5px;
  margin-right: 8px;
}

.span02 {
  width: 113px;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #2aaab3;
  margin-left: 8px;
  margin-right: 5px;
}

// 聊天记录
.message-box {
  height: 45vh;
  overflow-y: auto;
  padding: 15px 10px 10px;
  // @include scrollBar();
}

// 聊天记录选项
.message-item {
  margin-bottom: 24px;

  .message-avatar {
    // @include float(left);
    width: 42px;
  }

  .message-container {
    // width: calc(100% - 48px);
    // @include clearfix(); // 清除浮动

    .message-time {
      text-align: left;
    }

    .message-contents:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      right: 8.5px;
      top: -9px;
      border-bottom: 9px solid #1282a5;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
    }

    .message-contents {
      margin-top: 10px;
      position: relative;
      display: inline-block;
      width: fit-content;
      background: #eef0f0;
      padding: 12px;
      border-radius: 10px;
      right: 15px;
      float: right;
      top: 5px;

      .el-image {
        /deep/img {
          height: 120px;
          width: 120px;
        }
      }
    }

    .message-content:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      left: 8.5px;
      top: -9px;
      border-bottom: 9px solid #1282a5;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
    }

    .message-content {
      margin-top: 10px;
      position: relative;
      display: inline-block;
      width: fit-content;
      // background: #eef0f0;
      padding: 12px;
      border-radius: 10px;
      left: 15px;
      top: 5px;

      .el-image {
        /deep/img {
          height: 120px;
          width: 120px;
        }
      }
    }

    // 图片需要个默认高度
    .message-file {
      height: 100px;

      .el-image {
        /deep/img {
          width: auto;
        }
      }
    }
  }
}

// 自己发送
.message-myself {
  .message-avatar {
    // @include float(right);
  }

  .message-container {
    // @include float(right);

    .message-time {
      text-align: right;
    }

    .message-content {
      // @include float(inherit);
    }

    .message-contents {
      // @include float(inherit);
    }
  }
}

.send-message-icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  cursor: pointer;
  color: #fff;
}

.system-notification {
  background: #eef0f0;
  text-align: center;
}

.upload-resource {
  // @include flex('', '');
}

.input-box {
  text-align: right;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 10px;
  background-color: #02395a !important;
  display: flex;
  flex-direction: column;
  .textBottom {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 15px;
    margin-top: 0px;
    position: relative;
    bottom: 1vh;
  }
}

/deep/.el-textarea__inner {
  background-color: #02395a !important;
}

/deep/.message-item .message-container .message-content {
  background: #1282a5;
}

/deep/.message-item .message-container .message-contents {
  background: #1282a5;
}

/deep/.el-textarea__inner {
  color: #fff;
  border: none;
  resize: none;
}
</style>
