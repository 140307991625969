<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="searchForm" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">人员名称：</span>
					</span>
					<el-input v-model="searchForm.name" placeholder="请输入" clearable />
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">维保单位：</span>
					</span>
					<el-input v-model="searchForm.orgName" placeholder="请输入" clearable />
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">设备编号：</span>
					</span>
					<el-input v-model="searchForm.capCode" placeholder="请输入" clearable />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" class="searchBtn" @click="searchOnRefer">查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div>
			<!-- <el-button v-focus type="success" size="small" @click="addDj"
        >新增</el-button
      > -->
			<template v-if="personnelTotal != 0">
				<el-table
					ref="multipleTable"
					:data="tableData"
					style="width: 100%; margin-top: 20px; margin-bottom: 20px"
					:height="$store.state.tabHeight"
				>
					<el-table-column type="selection" width="55px" />
					<el-table-column type="index" label="序号" />
					<el-table-column align="center" prop="name" label="人员名称"  width="150px"/>
					<el-table-column align="center" prop="capCode" label="设备编号" width="150px"/>
					<el-table-column align="center" prop="contactWay" label="状态">
						<template slot-scope="scope">
							{{ scope.row.capStatus == 4 ? "通话中" : (
								scope.row.capStatus == 3 ? "监控中" : (
									scope.row.capStatus == 2 ? "本地录制" : (
										scope.row.capStatus == 1 ? "在线" : '离线'
									)
								)
							) }}
						</template>
					</el-table-column>
					<el-table-column align="center" prop="orgName" label="维保单位"  width="150px"/>
					<el-table-column align="center" prop="lastLogoutTime" label="最近离线时间"  width="180px"/>
					<el-table-column align="center" prop="phoneNumber" label="SIM卡"  width="150px"/>
					<el-table-column align="center" prop="monthSimData" label="本月流量(Mb)" width="150px"/>
					<el-table-column fixed="right" align="center" prop="address" label="操作" width="200px">
						<template slot-scope="scope">
							<el-button @click="edit(scope.row)" type="warning" size="small">编辑设备</el-button>
							<el-button @click="check(scope.row)" type="primary" size="small">查看监控</el-button>
						</template>
					</el-table-column>
				</el-table>
				<Pagination :total="personnelTotal" :page.sync="searchForm.current" :limit.sync="searchForm.size"
					@pagination="personnelPage" />
			</template>

			<div class="nullDate" v-else>
				<img src="@/assets/nullDate.png" style="width: 260px" />
			</div>
		</div>
		<el-dialog v-if="dialogVisible" :title="tit" :visible.sync="dialogVisible" :close-on-click-modal="false"
			width="600px" top="20vh">
			<PersonAction :tit="tit" :personnelForm="personnelForm" @subForm="subForm" @colseForm="colseForm" />
			<span slot="footer" class="dialog-footer"></span>
		</el-dialog>

		<MonitorTab ref="MonitorInfo" v-if="MonitorInfoVisible" :useId="useId" :activeNameProps="'ssjk'"
			:eleInfo="eleInfo" @closeMonitor="closeMonitor" />
	</div>
</template>
<script>
	import Pagination from "../../../components/Pagination";
	import PersonAction from "./components/personAction";
	import MonitorTab from "./components/monitorTab";
	export default {
		components: {
			Pagination,
			PersonAction,
			MonitorTab,
		},
		data() {
			return {
				eleInfo: null,
				useId: null, // id
				// 表格参数
				searchForm: {
					current: 1,
					size: 10,
				},
				tableData: [],
				// 分页参数
				personnelTotal: 0,
				dialogVisible: false, // 编辑弹框
				personnelForm: {}, // 编辑表单信息
				MonitorInfoVisible: false, // 详情弹框
				getDeTime: null
			};
		},
		filters: {},
		methods: {
			// 分页
			personnelPage(data) {
				this.searchForm.current = data.page;
				this.searchForm.size = data.limit;
				this.onRefer();
			},
			// 弹出框提交
			subForm(data) {
				console.log(data, "5555");
				const {
					capCode,
					id,
					capId
				} = data;
				const subData = {
					code: capCode,
					userId: id,
					capId: capId,
				};
				this.$http.post("/api/cap/web/capUser/submit", subData).then((res) => {
					if (res.data.success) {
						this.onRefer();
						this.getRenovate()
						this.dialogVisible = false;
						this.$message.success(res.data.msg);
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},

			// 搜索
			searchOnRefer() {
				this.searchForm.current = 1;
				this.onRefer();
			},
			// 重置
			reset() {
				console.log("重置");
				this.searchForm = {
					current: 1,
					size: 10,
				};
				this.onRefer();
			},

			// 获取人员方法
			onRefer() {
				const data = JSON.parse(JSON.stringify(this.searchForm));
				this.$http
					.post("/api/system/web/appUser/getAppCapUserPage", data)
					.then((res) => {
						if (res.data.success) {
							const {
								data: {
									data
								},
							} = res;
							this.tableData = data.records ?? [];
							this.personnelTotal = data.total ?? 0;
							this.$nextTick(() => {
								if (this.personnelTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							});
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},

			// 修改
			edit(item) {
				this.tit = "编辑人员";
				this.dialogVisible = true;
				this.personnelForm = JSON.parse(JSON.stringify(item));
				clearInterval(this.getDeTime)
			},
			// 新增
			// addDj() {
			//   this.tit = "新增人员";
			//   this.dialogVisible = true;
			//   this.personnelForm = {
			//     unitName: "",
			//   }
			// },
			colseForm() {
				this.dialogVisible = false;
				this.getRenovate()
			},

			// 查看设备
			check(item) {
				this.eleInfo = item;
				this.useId = item.id;
				console.log(this.useId, 'this.useId');
				// this.deviceCode = item.deviceCode;
				this.MonitorInfoVisible = true;
				clearInterval(this.getDeTime)
			},
			//关闭查看
			closeMonitor() {
				this.getRenovate()
				console.log("closeDialog");
				this.MonitorInfoVisible = false;
			},
			// 刷新列表
			getRenovate(){
				this.getDeTime = setInterval(() => {
					this.onRefer()
				}, 30000)
			}
		},
		created() {},
		mounted() {
			this.$nextTick(() => {
				this.onRefer();
				this.getRenovate()
			});
		},
		beforeDestroy() {
			console.log('销毁刷新');
			clearInterval(this.getDeTime)
		},
		destroyed() {
			clearInterval(this.getDeTime)
		}
	};
</script>
<style lang="scss" scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.searchBtn {
		margin-left: 15px;
	}
</style>