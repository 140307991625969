<template>
    <div>
        <div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">报警名称</span>
					</span>
					<el-input v-model="formInline.sosName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getTable">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
        <div v-if="total!=0">
			<el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" :height="$store.state.tabHeight">
				<el-table-column type="index" label="序号" width="50px" />
				<el-table-column align='center' prop="sosType" label="报警名称"  >
                    <template slot-scope="scope">
						{{getStatusText(scope.row.sosType)}}
					</template>
				</el-table-column>
				<el-table-column align='center' label="是否推送" >
					<template slot-scope="scope">
						<el-switch :disabled="pushId==scope.row.id" :active-value="1"
                        :inactive-value="0" @change="pushChange(scope.row)" v-model="scope.row.push"></el-switch>
					</template>
				</el-table-column>
                <el-table-column align='center' label="弹窗" >
					<template slot-scope="scope">
						<el-switch disabled :active-value="1"
                        :inactive-value="0" :value="1"></el-switch>
					</template>
				</el-table-column>
                <el-table-column align='center' label="声音" >
					<template slot-scope="scope">
						<el-switch :disabled="voiceId==scope.row.id" :active-value="1"
                        :inactive-value="0" @change="voiceChange(scope.row)" v-model="scope.row.voice"></el-switch>
					</template>
				</el-table-column>
			</el-table>
			<!-- <Pagination :total="total" :page.sync="formInline.current" :limit.sync="formInline.size"
				@pagination="dictionarPage" /> -->
		</div>
		<div class="nullDate" v-else>
			<img src="@/assets/nullDate.png" style="width: 260px;" />
		</div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading:false,
            formInline:{
                sosName:'',
            },
            tableData:[],
            pushId:'',
            voiceId:'',
            statusList:[]
        }
    },
    computed:{
        total(){
            return this.tableData.length
        }
    },
    methods:{
        //获取状态字典
        async getDictionary(code='SOS_TYPE') {
            const res=await this.$http.post(`/api/system/web/dict/dictionary?codes=${code}`)
            if(res.status==200&&res.data.code==200){
                this.statusList=res.data.data.SOS_TYPE
            }
            this.getTable()
        },
        getStatusText(status){
            const list=this.statusList.filter(item=>item.value==status)
            return list.length>0?list[0].label:status
        },
        getTable(){
            this.loading=true
            this.$http.post(`/api/system/web/user/getSosConfig`,{type:this.formInline.sosName})
            .then(res=>{
                if(res.status==200&&res.data.code==200){
                    this.tableData=res.data.data
                    sessionStorage.removeItem('sosConfig');
                    sessionStorage.setItem('sosConfig', JSON.stringify(res.data.data))
                }
                this.loading=false
            })
        },
        editSosConfig(data){
            this.loading=true
            this.$http.post(`/api/system/web/user/editSosConfig`,data)
            .then(res=>{
                if(res.status==200&&res.data.code==200){
                    this.$message({
                        type: 'success',
                        message: "修改成功"
                    })
                    this.getTable()
                }
            })
        },
        pushChange(row){
            this.pushId=row.id
            this.editSosConfig(row)
            this.pushId=''
        },
        voiceChange(row){
            this.voiceId=row.id
            this.editSosConfig(row)
            this.voiceId=''
        }
    },
    created(){
        this.getDictionary()
    }
}
</script>
<style scoped>
.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>