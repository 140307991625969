<template>
	<div>
		<div class="topBox">
			<div class="leftCharBox">
				<div class="boxTitle">
					<span style="color: #08c9d8;font-size: 19px;">当前风险等级分布</span>
					<el-button @click="showRules" style="text-decoration:underline;" type="text">风险等级规则</el-button>
				</div>
				<div id="bztsq"></div>
			</div>
			<div class="rightCharBox">
				<div id="zxtsq"></div>
			</div>
		</div>
		<div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯ID</span>
					</span>
					<el-input v-model="formInline.elevatorId" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯名称</span>
					</span>
					<el-input v-model="formInline.eleName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<!-- <el-form-item>
					<span slot="label">
						<span class="lab-span">评分日期</span>
					</span>
					<el-date-picker style="width: 220px;" v-model="formInline.allTime" type="daterange"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item> -->
				<el-form-item>
					<span slot="label">
						<span class="lab-span">风险等级</span>
					</span>
					<el-select v-model="formInline.riskLevel" placeholder="请输入" class="einp" clearable>
						<el-option v-for="item in wbType" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onRefer(true)">查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div v-if="dictionarTotal!=0">
			<el-table :data="tableData" ref="dictionarTable" style="width: 100%;margin-bottom: 20px;" max-height="350">
				<el-table-column align='center' prop="elevatorId" label="电梯ID" />
				<el-table-column align='center' prop="eleCode" label="电梯注册代码" />
				<el-table-column align='center' prop="eleName" label="电梯名称" />
				<el-table-column align='center' prop="maintainUnitName" label="维保单位名称" />
				<el-table-column align='center' label="风险等级">
					<template slot-scope="scope">
						{{
							scope.row.riskLevel==1?"Ⅰ":
							scope.row.riskLevel==2?"Ⅱ":
							scope.row.riskLevel==3?"Ⅲ":"Ⅳ"
						}}级风险
					</template>
				</el-table-column>
				<!-- <el-table-column align='center' prop="createTime" label="更新时间" /> -->
				<el-table-column fixed="right" align='center' label="操作" width="150px">
					<template slot-scope="scope">
						<el-button v-focus @click="showScore(scope.row)" type="primary" size="small">评级历史记录</el-button>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
				@pagination="dictionarPage" />
		</div>
		<div class="nullDate" v-else>
			<img src="@/assets/nullDate.png" style="width: 260px;" />
		</div>

		<el-dialog title="风险等级规则" :visible.sync="dioRules" :close-on-click-modal="false" width="1000px" top="20vh">
			<div style="height: 40vh;width: 100%;overflow-y: scroll;" class="scrollElement">
				<img src="@/assets/rule.png" />
			</div>
			<span slot="footer" class="dialog-footer" style="justify-content: center;display: flex;">
				<el-button size="small" type="primary" @click="dioRules=false">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="评级历史记录" :visible.sync="dialogVisible" :close-on-click-modal="false" width="80%" top="10vh">
			<div style="height: 60vh;width: 100%;overflow-y: scroll;" class="scrollElement">
				<div class="page-top">
					<el-form :inline="true" :model="formDio" class="demo-form-inline">
						<el-form-item>
							<span slot="label">
								<span class="lab-span">电梯名称</span>
							</span>
							<el-input v-model="formDio.eleName" placeholder="请输入" clearable></el-input>
						</el-form-item>
						<el-form-item>
							<span slot="label">
								<span class="lab-span">评分日期</span>
							</span>
							<el-date-picker style="width: 220px;" v-model="formDio.allTime" type="daterange"
								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item>
							<span slot="label">
								<span class="lab-span">风险等级</span>
							</span>
							<el-select v-model="formDio.riskLevel" placeholder="请输入" class="einp" clearable>
								<el-option v-for="item in wbType" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item>
							<el-button type="primary" @click="getScore(true)">查询</el-button>
							<el-button type="warning" @click="resetDio">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
				<div v-if="dictionarTotal!=0">
					<el-table :data="dioData" ref="multipleTable" style="width: 100%;margin-bottom: 20px;" max-height="400">
						<el-table-column type="expand">
							<template slot-scope="props">
								<div style="display: flex;flex-wrap: wrap;width: 90%;margin-left: 5%;">
									<div style="width: 30%;margin-left: 3%;margin-bottom: 10px;"
										v-for="item in props.row.detailList">
										{{item.name}}:{{item.point}}
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column align='center' type="index" label="序号" width="50px" />
						<el-table-column align='center' prop="eleName" label="电梯名称" />
						<el-table-column align='center' prop="createTime" label="评分日期" />
						<el-table-column align='center' label="风险等级">
							<template slot-scope="scope">
								{{scope.row.riskLevel}}级风险
							</template>
						</el-table-column>
						<el-table-column align='center' prop="totalPoints" label="总得分" />
						<el-table-column align='center' prop="createTime" label="创建时间" />
					</el-table>
					<Pagination :total="dioDataTlt" :page.sync="formDio.current" :limit.sync="formDio.size"
						@pagination="dictionarPageDio" />
				</div>
				<div class="nullDateNew" v-else>
					<img src="@/assets/nullDate.png" style="width: 260px;" />
				</div>
			</div>
			<span slot="footer" class="dialog-footer" style="justify-content: center;display: flex;">
				<el-button size="small" type="primary" @click="editShow">确 定</el-button>
			</span>
		</el-dialog>

	</div>
</template>
<script>
	var valifailureDesc = (rule, value, callback) => {
		let operation = /^.{4,600}$/
		inpYz(rule, value, callback, true, operation, '可输入中文，英文，数值，特殊符号，长度为4至600')
	};

	import * as echarts from 'echarts';
	import Pagination from '@/components/Pagination'
	export default {
		components: {
			Pagination,
		},
		data() {
			return {
				dioRules: false,
				// 表格参数
				formInline: {
					current: 1,
					size: 10,
				},
				tableData: [],
				// 分页参数
				dictionarTotal: 0,
				// 维保类型
				wbType: [],
				// 弹窗数据
				formDio: {
					current: 1,
					size: 10,
				},
				dioData: [],
				dioDataTlt: 0,
				dialogVisible: false
			}
		},
		methods: {
			showRules() {
				this.dioRules = true
			},
			setZxt(data) {
				var chartDom = document.getElementById('zxtsq');
				var myChart = echarts.init(chartDom);
				var option = {
					title: {
						textStyle: {
							color: '#09bdcd',
							fontSize: '22'
						},
						text: '最近7日风险等级走势'
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						right: '5%',
						textStyle: {
							color: '#fff',
						},
						data: ['Ⅰ级风险', "Ⅱ级风险", "Ⅲ级风险", "Ⅳ级风险"]
					},
					grid: {
						left: '1%',
						right: '5%',
						bottom: '10%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: data.time,
						"axisLine": {
							lineStyle: {
								color: '#fff'
							}
						},
					},
					yAxis: {
						type: 'value',
						"axisLine": {
							lineStyle: {
								color: '#fff'
							}
						},
					},
					series: data.data
				};
				myChart.setOption(option);

			},
			setBxt(data) {
				var ydata = [{
					name: 'Ⅳ级风险',
					value: data.level_4
				}, {
					name: 'Ⅲ级风险',
					value: data.level_3
				}, {
					name: 'Ⅱ级风险',
					value: data.level_2
				}, {
					name: 'Ⅰ级风险',
					value: data.level_1
				}];
				var color = ["#0F8200", "#409eff", "#FFEA00", "#B51000", ]
				var xdata = ["Ⅳ级风险", "Ⅲ级风险", "Ⅱ级风险", 'Ⅰ级风险'];


				var chartDom = document.getElementById('bztsq');
				var myChart = echarts.init(chartDom);
				var option = {

					color: color,
					legend: {
						orient: 'vertical',
						top: "middle",
						right: "5%",
						textStyle: {
							color: '#f2f2f2',
							fontSize: 20,

						},
						selectedMode: false,
						formatter: function(name) {
							console.log('112233', name)
							let NumName = name
							ydata.forEach((qs) => {
								if (name == qs.name) {
									NumName = name + '：' + qs.value + '条'
								}
							})
							return NumName
						}
					},
					series: [{
						type: 'pie',
						clockwise: false, //饼图的扇区是否是顺时针排布
						minAngle: 2, //最小的扇区角度（0 ~ 360）
						radius: ["50%", "80%"],
						center: ["30%", "45%"],
						avoidLabelOverlap: false,
						itemStyle: { //图形样式
							normal: {
								borderColor: '#1e2d4f',
								borderWidth: 6,
							},
						},
						label: {
							normal: {
								show: false,
								position: 'center',
								formatter: '{text|{b}}\n{d}%',
								rich: {
									text: {
										color: "#3c93ed",
										fontSize: 20,
										align: 'center',
										verticalAlign: 'middle',
										padding: 8
									},
									value: {
										color: "#8693F3",
										fontSize: 22,
										align: 'center',
										verticalAlign: 'middle',
									},
								}
							},
							emphasis: {
								show: true,
								textStyle: {
									fontSize: 18,
								}
							}
						},
						data: ydata
					}]
				};
				myChart.setOption(option);
				setTimeout(function() {
					myChart.on('mouseover', function(params) {
						if (params.name == ydata[0].name) {
							myChart.dispatchAction({
								type: 'highlight',
								seriesIndex: 0,
								dataIndex: 0
							});
						} else {
							myChart.dispatchAction({
								type: 'downplay',
								seriesIndex: 0,
								dataIndex: 0
							});
						}
					});

					myChart.on('mouseout', function(params) {
						myChart.dispatchAction({
							type: 'highlight',
							seriesIndex: 0,
							dataIndex: 0
						});
					});
					myChart.dispatchAction({
						type: 'highlight',
						seriesIndex: 0,
						dataIndex: 0
					});
				}, 1000);
			},
			editShow() {
				this.dialogVisible = false
			},
			getScore(ishm) {
				if (ishm) {
					this.formDio.current = 1
				}
				console.log(111, this.formDio.allTime)
				if (this.formDio.allTime != null && this.formDio.allTime != undefined) {
					this.formDio.start = new Date(this.formDio.allTime[0]).getTime()
					this.formDio.end = new Date(this.formDio.allTime[1]).getTime()
				} else {
					this.formDio.start = null
					this.formDio.end = null
				}
				this.$http
					.post("/api/ele/web/riskGradeRecord/list", this.formDio)
					.then((res) => {
						if (res.data.success) {
							res.data.data.records.forEach((qs) => {
								console.log('qs', qs)
								qs.detailList = JSON.parse(qs.detail)
							})
							this.dioData = res.data.data.records
							this.dioDataTlt = res.data.data.total
							this.$nextTick(() => {
								if (this.dioDataTlt > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})

			},
			showScore(item) {
				this.formDio = {
					eleName: item.eleName,
					current: 1,
					size: 10,

				}
				this.getScore()
				this.dialogVisible = true
			},
			// 重置按钮事件
			reset() {
				this.formInline = {
					current: 1,
					size: 10,
				}
				this.onRefer()
			},
			// 弹窗重置按钮事件
			resetDio() {
				this.formDio = {
					current: 1,
					size: 10,
				}
				this.getScore()
			},
			// 弹窗分页
			dictionarPageDio(data) {
				this.formDio.current = data.page
				this.formDio.size = data.limit
				this.getScore()
			},
			// 分页
			dictionarPage(data) {
				this.formInline.current = data.page
				this.formInline.size = data.limit
				this.onRefer()
			},
			// 表格方法
			onRefer(ishm) {
				if (ishm) {
					this.formInline.current = 1
				}
				console.log(this.formInline.allTime)
				if (this.formInline.allTime != null && this.formInline.allTime != undefined) {
					this.formInline.start = new Date(this.formInline.allTime[0]).getTime()
					this.formInline.end = new Date(this.formInline.allTime[1]).getTime()
				} else {
					this.formInline.start = null
					this.formInline.end = null
				}
				if (this.formInline.elevatorId && !/^[0-9]*$/.test(this.formInline.elevatorId)) {
					this.$message.error('电梯ID只能输入数字')
					return
				}
				this.$http
					.post("/api/ele/web/riskGradeRecord/getList", this.formInline)
					.then((res) => {
						console.log('列表', res.data)
						if (res.data.success) {
							res.data.data.records.forEach((qs) => {
								qs.statusName = this.getType(qs.status, 2)
							})
							this.tableData = res.data.data.records
							this.dictionarTotal = res.data.data.total
							
							this.$nextTick(() => {
								if (this.dictionarTotal > 0 && this.$refs.dictionarTable) {
									this.$refs.dictionarTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
			// 获取类型
			getType(type, sta) {
				let obj
				this.wbType.forEach((qs) => {
					if (qs.value == type) {
						obj = qs.label
					}
				})
				return obj
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.$http.get(`/api/ele/web/bigScreen/jg/fxdj`).then((res) => {
					this.setBxt(res.data.data)
				})
				this.$http.get(`/api/ele/web/riskGradeRecord/getTendencyChart`).then((res) => {
					this.setZxt(res.data.data)
					console.log('qsdascxas', res)
				})
			})
		},
		created() {
			this.$http
				.post(`/api/system/web/dict/dictionary?codes=ELE_RISK_LEVEL`)
				.then((res) => {
					if (res.data.success) {
						this.wbType = res.data.data.ELE_RISK_LEVEL
						this.onRefer()
					}
				})
		}
	}
</script>

<style scoped>
	#zxtsq {
		margin: 2.5%;
		width: 95%;
		height: 95%;
	}

	#bztsq {
		width: 100%;
		height: 77%;
	}

	.boxTitle {
		width: 90%;
		height: 10%;
		margin: 5%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.leftCharBox {
		width: 35%;
		height: 90%;
		border: 1px solid #00a9ad;
		margin-right: 2%;
	}

	.rightCharBox {
		width: 63%;
		height: 90%;
		border: 1px solid #00a9ad;
	}

	.topBox {
		width: 100%;
		height: 40vh;
		display: flex;
	}

	.pBox {
		font-size: 18px;
		display: flex;
	}

	.pSpan {
		width: 100px;
	}

	.nullDateNew {
		width: 100%;
		height: 50vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-top {
		width: 100%;
		margin-bottom: 20px;
	}

	.page-bom {}
</style>
