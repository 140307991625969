<template>
	<div>
		<div class="page-top">
			<div>
				<el-button v-focus type="success" @click="addDj" size="small">新增</el-button>
			</div>
		</div>
		<div v-if="dictionarTotal != 0">
			<el-table ref="multipleTable" :data="tableData" style="width: 100%; margin-bottom: 20px" :height="$store.state.tabHeight">
				<el-table-column type="index" label="主键" width="50px" />
				<el-table-column align="center" prop="name" label="名称" />
				<el-table-column align="center" prop="riskElementName" label="风险因素" />
				<el-table-column align="center" prop="totalPoints" label="总分" />
				<el-table-column align="center" label="状态">
					<template slot-scope="scope">
						{{
							scope.row.status==1?'参与记分':'不参与记分'
						}}
					</template>
				</el-table-column>
				<el-table-column align="center" prop="minPoints" label="分级最小值" />
				<el-table-column align="center" prop="gradePoints" label="分级得分" />
				<el-table-column align="center" prop="defaultPoints" label="无数据时默认得分" />
				<el-table-column align="center" prop="dataType" label="统计数据单位类型" />
				<el-table-column align="center" label="统计时间范围类型">
					<template slot-scope="scope">
						{{
							scope.row.statisticsStatus==1?'考虑范围':'不考虑范围'
						}}
					</template>
				</el-table-column>
				<el-table-column align="center" prop="dateScope" label="时间范围值" />
				<el-table-column align="center" prop="remark" label="备注" />
				<el-table-column align="center" prop="createUserName" label="创建人" />
				<el-table-column align="center" prop="createTime" label="创建时间" />
				<el-table-column align="center" prop="updateUserName" label="更新人" />
				<el-table-column align="center" prop="updateTime" label="更新时间" />
				<el-table-column fixed="right" align="center" label="操作" width="150px">
					<template slot-scope="scope">
						<el-button v-focus @click="upd(scope.row)" type="primary" size="small">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="nullDate" v-else>
			<img src="@/assets/nullDate.png" style="width: 260px" />
		</div>
		<el-dialog v-if="dialogVisible" :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible"
			width="950px" top="20vh">
			<div style="height: 30vh;overflow-y: scroll;" class="scrollElement">
				<el-form :rules="rules" ref="ruleForm" label-position="right" label-width="150px" :model="fign">
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="名称:" prop="name">
								<el-input v-model="fign.name" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="风险因素:" prop="riskElement">
								<el-select v-model="fign.riskElement" placeholder="请选择" class="einp" clearable>
									<el-option v-for="item in fxTypeList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="总分:" prop="totalPoints">
								<el-input v-model="fign.totalPoints" placeholder="请输入正整数" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="状态:" prop="status">
								<el-select v-model="fign.status" placeholder="请选择" class="einp" clearable>
									<el-option :key="1" label="参与计分" :value="1"></el-option>
									<el-option :key="2" label="不参与计分" :value="2"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="分级最小数:" prop="minPoints">
								<el-input v-model="fign.minPoints" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="分级得分:" prop="gradePoints">
								<el-input v-model="fign.gradePoints" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="无数据时默认得分:" prop="defaultPoints">
								<el-input v-model="fign.defaultPoints" placeholder="请输入正整数" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="统计数据单位类型:" prop="dataType">
								<el-input v-model="fign.dataType" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="统计时间范围类型:" prop="statisticsStatus">
								<el-select v-model="fign.statisticsStatus" placeholder="请选择" class="einp" clearable>
									<el-option :key="1" label="考虑范围" :value="1"></el-option>
									<el-option :key="2" label="不考虑范围" :value="2"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="时间范围值:" prop="dateScope">
								<el-input type="number" v-model="fign.dateScope" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="备注:" prop="remark">
								<el-input v-model="fign.remark" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" type="primary" @click="dioSub">确 定</el-button>
				<el-button size="small" @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			let _this = this
			return {
				// 下拉数据
				fxTypeList: [],
				// 地区选择器
				props: {
					label: 'name',
					value: 'areaCode',
					checkStrictly: true,
				},
				// 表格参数
				formInline: {
					current: 1,
					size: 10000
				},
				tableData: [],
				// 分页参数
				dictionarTotal: 0,
				// dialog参数
				tit: '',
				dialogVisible: false,
				fign: {},
				// 表单限制
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
					totalPoints: [{
						required: true,
						message: '请输入总分（值为正整数）',
						trigger: 'blur'
					}],
					minPoints: [{
						required: true,
						message: '请输入分级最小数',
						trigger: 'blur'
					}],
					gradePoints: [{
						required: true,
						message: '请输入分级得分',
						trigger: 'blur'
					}],
					defaultPoints: [{
						required: true,
						message: '请输入默认得分',
						trigger: 'blur'
					}],
					dataType: [{
						required: true,
						message: '请输入数据类型',
						trigger: 'blur'
					}],
					dateScope: [{
						required: true,
						message: '请输入时间范围',
						trigger: 'blur'
					}],
					remark: [{
						required: true,
						message: '请输入备注',
						trigger: 'blur'
					}],
					riskElement: [{
						required: true,
						message: '请选择风险因素',
						trigger: 'change'
					}],
					status: [{
						required: true,
						message: '请选择状态',
						trigger: 'change'
					}],
					statisticsStatus: [{
						required: true,
						message: '请选择统计范围状态',
						trigger: 'change'
					}],
				},
			}
		},
		methods: {
			// 分页
			dictionarPage(data) {
				this.formInline.current = data.page
				this.formInline.size = data.limit
				this.onRefer()
			},
			// 弹出框提交
			dioSub() {
				console.log('this.fign', this.fign)
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						let data = JSON.parse(JSON.stringify(this.fign))
						let url = '/api/ele/web/riskGradeRule/submit'
						this.$http.post(url, data).then((res) => {
							if (res.data.success) {
								this.onRefer()
								this.dialogVisible = false
								this.$message.success(res.data.msg)
							} else {
								this.$message.error(res.data.msg)
							}
						})
					} else {
						this.$message.warning('请验证填写内容!')
						return false
					}
				})
			},

			// 表格方法
			onRefer(ishm) {
				if (ishm) {
					this.formInline.current = 1
				}
				console.log('this.formInline', this.formInline)
				this.$http
					.post('/api/ele/web/riskGradeRule/list', this.formInline)
					.then((res) => {
						console.log('列表', res.data)
						if (res.data.success) {
							res.data.data.records.forEach((qs) => {
								this.fxTypeList.forEach((qs1) => {
									if (qs.riskElement == qs1.value) {
										qs.riskElementName = qs1.label
									}
								})
							})
							this.tableData = res.data.data.records
							this.dictionarTotal = res.data.data.total
							this.$nextTick(() => {
								if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
			// 修改
			upd(item) {
				this.tit = '修改'
				this.dialogVisible = true
				this.fign = JSON.parse(JSON.stringify(item))
				console.log('item', this.fign)
			},
			addDj() {
				this.tit = '新增'
				this.dialogVisible = true
			},
		},
		created() {
			// fxTypeList
			this.$http
				.post(`/api/system/web/dict/dictionary?codes=ELE_RISK_TYPE`)
				.then((res) => {
					if (res.data.success) {
						this.fxTypeList = res.data.data.ELE_RISK_TYPE
						this.onRefer()
					}
				})
		},
	}
</script>
<style scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-top {
		width: 100%;
		margin-bottom: 20px;
	}

	.page-bom {}
</style>
