<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">人员名称</span>
					</span>
					<el-input v-model="formInline.name" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">用户名称</span>
					</span>
					<el-input v-model="formInline.userName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">操作时间</span>
					</span>
					<el-date-picker v-model="formInline.zTime" type="datetimerange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
						class="einp">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onRefer(true)">查询</el-button>
					<el-button type="primary" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div v-if="dictionarTotal!=0">
			<el-table :data="tableData" ref="multipleTable" style="width: 100%;margin-bottom: 20px;" :height="$store.state.tabHeight">
				<el-table-column type="index" label="序号" width="50px" />
				<el-table-column align='center' prop="name" label="人员名称" />
				<el-table-column align='center' prop="userName" label="用户名称" />
				<el-table-column align='center' prop="msg" label="内容" />
				<el-table-column fixed="right" align='center' prop="createTime" label="操作时间" />
				</el-table-column>
			</el-table>
			<Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
				@pagination="dictionarPage" />
		</div>
		<div class="nullDate" v-else>
			<img src="@/assets/nullDate.png" style="width: 260px;" />
		</div>
	</div>
</template>
<script>
	// 页面对应的type
	import Pagination from '@/components/Pagination'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				// 地区选择器
				props: {
					label: 'name',
					value: 'areaCode',
					checkStrictly: true
				},
				// 表格参数
				formInline: {
					current: 1,
					size: 10
				},
				tableData: [],
				// 分页参数
				dictionarTotal: 0,
				fign: {},
			}
		},
		methods: {
			// 重置按钮事件
			reset() {
				this.formInline = {
					current: 1,
					size: 10
				}
				this.onRefer()
			},
			// 分页
			dictionarPage(data) {
				this.formInline.current = data.page
				this.formInline.size = data.limit
				this.onRefer()
			},
			// 表格方法
			onRefer(ishm) {
				if (ishm) {
					this.formInline.current = 1
				}
				this.formInline.start = null
				this.formInline.end = null
				if (this.formInline.zTime) {
					let atime = JSON.parse(JSON.stringify(this.formInline.zTime))
					this.formInline.start = new Date(atime[0]).getTime()
					this.formInline.end = new Date(atime[1]).getTime()
					let celTime = this.formInline.end - this.formInline.start
					if (celTime > 60 * 60 * 24 * 30 * 1000) {
						this.$message.error('最多查询30天的日志')
						return
					}
				}

				if (ishm) {
					this.formInline.current = 1
				}

				this.$http
					.post("/api/system/web/operatorLog/getList", this.formInline)
					.then((res) => {
						if (res.data.success) {
							this.tableData = res.data.data.records
							this.dictionarTotal = res.data.data.total
							this.$nextTick(() => {
								if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
		},
		created() {
			this.onRefer()
		}
	}
</script>
<style scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-top {
		width: 100%;
		margin-bottom: 20px;
	}

	.page-bom {}
</style>
