<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">图片名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">拍摄时间</span>
          </span>
          <el-date-picker
            v-model="searchForm.allTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">人员名称</span>
          </span>
          <el-input
            v-model="searchForm.userName"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer"
            >查询</el-button
          >
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <template v-if="total != 0">
        <el-row>
          <template v-for="(item, index) in tableData">
            <el-col :span="6" :key="index" class="col20">
              <div @click="openDetail(item)">
                <div class="imgBox">
                  <el-image
                    style="width: 100%; height: 180px"
                    :src="item.url"
                    fit="cover"
                  />
                  <p class="boxTime">拍摄时间：{{ item.shootingTime }}</p>
                </div>
                <h5>{{ item.name }}</h5>
                <h5>
                  {{ item.userName }} ({{
                    item.capCode.substring(
                      item.capCode.length - 4,
                      item.capCode.length
                    )
                  }})
                </h5>
              </div>
            </el-col>
          </template>
        </el-row>
        <Pagination
          :total="total"
          :layout="'total, sizes, prev, pager, next'"
          :page.sync="searchForm.current"
          :limit.sync="searchForm.size"
          @pagination="videoRulePage"
        />
      </template>

      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </div>
    <DetailInfo
      v-if="detailInfoVisible"
      ref="detailInfo"
      :info="info"
      @detailClose="detailClose"
    />
  </div>
</template>
<script>
import Pagination from "../../../components/Pagination";
import DetailInfo from "./components/detailInfo";
export default {
  components: {
    Pagination,
    DetailInfo,
  },
  data() {
    return {
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },
      tableData: [],
      // 分页参数
      total: 0,
      detailInfoVisible: false,
      info: {},
    };
  },
  filters: {},
  methods: {
    // 分页
    personnelPage(data) {
      this.searchForm.current = data.page;
      this.searchForm.size = data.limit;
      this.onRefer();
    },

    // 搜索
    searchOnRefer() {
      console.log(this.searchForm, "this.searchForm");
      this.searchForm.current = 1;
      this.onRefer();
    },
    // 重置
    reset() {
      console.log("重置");
      this.searchForm = {
        current: 1,
        size: 10,
      };
      this.onRefer();
    },
    // 视频
    videoRulePage(data) {
      this.searchForm.current = data.page;
      this.searchForm.size = data.limit;
      this.onRefer();
    },

    // 获取人员方法
    onRefer() {
      const data = JSON.parse(JSON.stringify(this.searchForm));
      data.start = this.searchForm.allTime
        ? this.searchForm.allTime[0].getTime()
        : "";
      data.end = this.searchForm.allTime
        ? this.searchForm.allTime[1].getTime() + 24 * 60 * 60 * 1000 - 1000
        : "";
      this.$http.post("/api/cap/web/images/getCapImages", data).then((res) => {
        if (res.data.success) {
          const {
            data: { data },
          } = res;
          this.tableData = data.records ?? [];
          this.total = data.total ?? 0;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 报警详情
    detail(row) {
      this.detailVisible = true;
    },

    openDetail(row) {
      this.info = row;
      this.detailInfoVisible = true;
      this.$nextTick(() => {
        this.$refs.detailInfo.init("tpxq");
      });
    },

    // 详情弹框关闭
    detailClose() {
      this.detailInfoVisible = false;
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.onRefer();
    });
  },
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.detailBox {
  height: 44vh;
  background: #65809bb3;
  border-radius: 5px;
  padding: 25px 15px;
  color: #fff;
  h4 {
    margin: 0;
    font-size: 15px;
  }
}
.mapBox {
  height: 50vh;
  background: #000;
  margin-left: 20px;
  border-radius: 5px;
}

.searchBtn {
  margin-left: 15px;
}
.col20 {
  width: 20%;
  padding: 0 15px;
  color: #fff;
  margin-bottom: 10px;
  .imgBox {
    position: relative;
    overflow: hidden;
    .boxTime {
      position: absolute;
      bottom: 4px;
      margin: 0;
      background: #5c5c5cdb;
      padding: 8px;
      font-size: 14px;
      width: 100%;
    }
  }
  h5 {
    margin: 10px 0;
    line-height: 17px;
  }
}
</style>
