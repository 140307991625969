<template>
  <el-dialog
    title="更换单位"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="1400px"
    top="8vh"
    center
    @close="closeDialog"
  >
    <div class="searchBox scrollElement">
      <el-form :inline="true" :model="personnelData" class="demo-form-inline">
        <el-form-item label="人员名称">
          {{ personnelData.name }}
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">单位名称</span>
          </span>
          <el-input
            v-model="personnelData.orgNewName"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">单位类型</span>
          </span>
          <el-select v-model="type" placeholder="请输入" clearable>
            <el-option
              v-for="(item, index) in unitList"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchPersonnel" class="searchBtn"
            >搜索</el-button
          >
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="dataList">
        <el-table
          :data="unitData"
          ref="unitTable"
          border
          stripe
          fit
          style="width: 100%; margin-top: 20px"
          max-height="500px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center" width="55px" />
          <el-table-column type="index" align="center" label="序号" width="50px" />
          <el-table-column
            prop="name"
            label="单位名称"
            min-width="150px"
            align="center"
          />
          <el-table-column
            prop="address"
            label="单位地址"
            min-width="200px"
            align="center"
          />
          <el-table-column
            prop="type"
            label="单位类型"
            min-width="120px"
            align="center"
          >
            <template slot-scope="scope">
              {{
                unitList.find((item) => item.value === scope.row.type)
                  ? unitList.find((item) => item.value === scope.row.type).label
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="contact"
            label="联系人"
            min-width="120px"
            align="center"
          />
          <el-table-column
            prop="phone"
            label="联系方式"
            min-width="150px"
            align="center"
          />
          <el-table-column
            prop="areaPidName"
            label="所属区域"
            min-width="120px"
            align="center"
          />
          <el-table-column
            prop="createUserName"
            label="创建人"
            min-width="120px"
            align="center"
          />
        </el-table>
        <Pagination
          :total="unitTotal"
          :page.sync="unitQuery.current"
          :limit.sync="unitQuery.size"
          @pagination="unitPage"
        />
      </div>
    </div>
    <span slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" @click="saveChange">保存</el-button>
      <el-button type="indo" @click="closeDialog">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Pagination from "../../../../components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      visible: false,
      unitData: [],
      type: "",
      unitTotal: 0,
      unitQuery: {
        current: 1,
        size: 10,
      },
      addBrandCallback: null, // 弹窗回调
      selectRow: []

    };
  },
  props: {
    personnelData: {
      type: Object,
      default: () => {
        return {
          dictValue: "",
          code: "",
          parentId: "",
        };
      },
    },
    unitList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    changeUnitType: {
      type: String,
      default: "",
    },
  },

  mounted() {},

  methods: {
    // 父字典id，父字典数据，回调
    init(callback) {
      this.addBrandCallback = callback;
      this.type = "";
      this.visible = true;
      this.loading = false;
      this.unitQuery.current = 1;
      this.$nextTick(() => {
        this.getPersonnel();
      });
    },
    // 选中行
    handleSelectionChange(val) {
      console.log(val, "选中行");
      this.selectRow = val;
      this.unitData.forEach((item) => {
        if (val[val.length - 1] === item) {
          this.$refs.unitTable.toggleRowSelection(item, true);
        } else {
          this.$refs.unitTable.toggleRowSelection(item, false);
        }
      });
      console.log(this.selectRow, "this.selectRow");
    },

    searchPersonnel() {
      this.unitQuery.current = 1;
      this.search = true;
      this.getPersonnel();
      console.log("搜索单位");
    },

    // 重置
    reset() {
      console.log("重置");
      this.searchForm = {
        current: 1,
        size: 10,
      };
      this.personnelData.orgNewName = "";
      this.type = "";
      this.getPersonnel();
    },

    // 单位分页
    unitPage(data) {
      this.unitQuery.current = data.page;
      this.unitQuery.size = data.limit;
      this.getPersonnel();
    },
    // 保存更换
    saveChange() {
      if (this.selectRow.length === 0) {
        this.$message.error('请选择单位');
        return
      }
      const data = {
        ...this.personnelData,
        orgId: this.selectRow[0].id,
      };
      if (this.changeUnitType !== "更换单位") {
        data.orgName = this.selectRow[0].name;
        this.$emit("getUnitInfo", data);
        this.visible = false;
      } else {
        this.$http.post("/api/system/web/appUser/submit", data).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "更换成功！",
              type: "sussess",
            });
            if (this.addBrandCallback) {
              this.addBrandCallback(true);
            }
            this.visible = false;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },

    // 获取单位列表
    getPersonnel() {
      const data = this.unitQuery;
      data.type = this.type ? this.type : this.personnelData.type;
      data.name = this.personnelData.orgNewName;
      this.$http.post("/api/system/web/org/list", data).then((res) => {
        const {
          data: { data },
        } = res;
        if (res.data.code === 200) {
          this.unitData = JSON.parse(JSON.stringify(data.records));
          this.unitTotal = data.total;
          this.$nextTick(() => {
            if (this.unitTotal > 0 && this.$refs.multipleTable) {
              this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
            }
            this.unitData.forEach((ele) => {
              if (this.personnelData.orgName === ele.name) {
                this.$refs.unitTable.toggleRowSelection(ele, true);
              }
            });
          });
          this.search = false;
        }
      });
    },
    closeDialog() {
      console.log("closeDialog");
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.searchBox {
  /deep/.el-form-item__label {
    color: #00f6ff !important;
    font-size: 14px;
  }
  .searchBtn {
    margin-left: 15px;
  }
}
/deep/.el-table__header-wrapper .el-checkbox {
  display: none;
  position: relative;
}
</style>
