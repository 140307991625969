<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯名称</span>
					</span>
					<el-input v-model="formInline.eleName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯注册代码</span>
					</span>
					<el-input v-model="formInline.eleCode" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">应急状态</span>
					</span>
					<el-select v-model="formInline.status" placeholder="请输入" class="einp" clearable>
						<el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">应急类型</span>
					</span>
					<el-select v-model="formInline.sosType" placeholder="请输入" class="einp" clearable>
						<el-option v-for="item in typeDate.SOS_TYPE " :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">所属小区</span>
					</span>
					<el-input v-model="formInline.plotName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<!-- <el-form-item>
					<span slot="label">
						<span class="lab-span">查询时间</span>
					</span>
					<el-date-picker v-model="formInline.allTime" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期"
						class="einp">
					</el-date-picker>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="onRefer(true)">查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
			<el-button type="success" @click="addDj" v-focus size="small">新增</el-button>
		</div>
		<div v-if="dictionarTotal!=0">
			<el-table :data="tableData" ref="multipleTable" style="width: 100%;margin-bottom: 20px;" :height="$store.state.tabHeight-80">
				<el-table-column type="index" label="序号" width="50px" />
				<el-table-column align='center' prop="eleName" label="电梯名称" width="200px" />
				<el-table-column align='center' label="报警时间" width="200px">
					<template slot-scope="scope">
						{{ scope.row.sosTime ? scope.row.sosTime : '-' }}
					</template>
				</el-table-column>
				<el-table-column align='center' prop="sosTypeName" label="应急类型" width="120px" />
				<el-table-column align='center' label="接警时间" width="200px">
					<template slot-scope="scope">
						{{ scope.row.callTime ? scope.row.callTime : '-' }}
					</template>
				</el-table-column>
				<el-table-column align='center' label="到场时间" width="200px">
					<template slot-scope="scope">
						{{ scope.row.presentTime ? scope.row.presentTime : '-' }}
					</template>
				</el-table-column>
				<el-table-column align='center' label="完成时间" width="200px">
					<template slot-scope="scope">
						{{ scope.row.finishTime ? scope.row.finishTime : '-' }}
					</template>
				</el-table-column>
				<el-table-column align='center' label="所属小区" width="180px" >
					<template slot-scope="scope">
						{{scope.row.plotName ? scope.row.plotName : '-'}}
					</template>
				</el-table-column>
				<el-table-column align='center' prop="sosFloor" label="报警楼层" width="100px" />
				<el-table-column align='center' label="所用时长" width="200px">
					<template slot-scope="scope">
						{{ scope.row.useTime ? scope.row.useTime : '-' }}
					</template>
				</el-table-column>
				<el-table-column align='center' label="应急状态" width="100px">
					<template slot-scope="scope">
						{{scope.row.newStatus ? stateList.find(item => item.value == scope.row.newStatus).label : ''}}
					</template>
				</el-table-column>
				<el-table-column align='center' prop="eleCode" label="电梯注册代码" width="200px" />
				<el-table-column align='center' prop="propertyUnitName" label="物业单位" width="200px" />
				<el-table-column align='center' prop="maintainUnitName" label="维保单位" width="200px" />
				<el-table-column align='center' label="维保人员" width="200px"  >
					<template slot-scope="scope">
							{{ scope.row.maintainUsers ? scope.row.maintainUsers.split(';').slice(0,2).join() : '-' }}
						</template>
					</el-table-column>
				<el-table-column align='center' prop="eleAddress" label="电梯安装位置" width="250px" />
				<el-table-column align='center' prop="createTime" label="创建时间" width="200px" />
				<el-table-column fixed="right" align='center' prop="address" label="操作" width="120px">
					<template slot-scope="scope">
						<el-button @click="showDetails(scope.row)" type="primary" size="small">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
				@pagination="dictionarPage" />
		</div>
		<div class="nullDate" v-else>
			<img src="@/assets/nullDate.png" style="width: 260px;" />
		</div>

		<el-dialog v-if='dioDetails' :close-on-click-modal='false' :visible.sync="dioDetails" width="1200px" top='15vh'>
			<div class="tabBut">
				<!-- <template v-for="item in detailsItem.seleArr">
					<el-button @click="detailsItem.seleId=item.value" 
						:type='item.value==detailsItem.seleId?"primary":""'>{{item.label}}</el-button>
				</template> -->

				<el-button @click="detailsItem.seleId=1" :type='1 == detailsItem.seleId ? "primary" : ""'>报警消息
				</el-button>
				<el-button v-show="dioDetailsItem.sosTypeName == '安全回路报警' || dioDetailsItem.sosTypeName == '电梯运行异常告警'" @click="detailsItem.seleId=2"
					:type='2 == detailsItem.seleId ? "primary" : ""'>运行参数</el-button>
				<el-button @click="detailsItem.seleId=3" :type='3==detailsItem.seleId ? "primary" : ""'>处置流程</el-button>
			</div>
			<div style="height: 55vh;margin-top: 10px;" class="scrollElement"
				:class="[detailsItem.seleId==1?'yeScroll':'noScroll']">
				<alarmMessage v-if="detailsItem.seleId==1" :dioData="dioDetailsItem" :stateList="stateList" />
				<operational v-else-if="detailsItem.seleId==2" :dioData="dioDetailsItem" />
				<process v-else :dioData="dioDetailsItem" :stateListDown="stateList" />
			</div>
			<span slot="footer" class="dialog-footer"
				style="display: flex;align-items: center;justify-content: center;">
				<!-- <div v-if="detailsItem.seleId==1">
					<el-button :disabled='dioDetailsItem.sosStatus!=1' size="mini" type="primary" @click="sosClick(1)">
						<span>人为触发(无故障)</span>
						<p style="margin: 10px 0 0 0;">确认关闭</p>
					</el-button>
					<el-button :disabled='dioDetailsItem.sosStatus!=1' size="mini" type="info" @click="sosClick(2)">
						<span>电梯故障(无困人)</span>
						<p style="margin: 10px 0 0 0;">派发维修工单</p>
					</el-button>
					<el-button :disabled='dioDetailsItem.sosStatus!=1' size="mini" type="warning" @click="sosClick(3)">
						<span>困人</span>
						<p style="margin: 10px 0 0 0;">立即派发维修工单</p>
					</el-button>
				</div> -->
			</span>
		</el-dialog>

		<el-dialog v-if='dialogVisible' :close-on-click-modal='false' :title="tit" :visible.sync="dialogVisible"
			width="950px" top='20vh'>
			<div style="height: 20vh;">
				<el-form :rules="rules" ref="ruleForm" label-position="right" label-width="100px" :model="fign">
					<el-row style="margin:10px 0">
						<el-col :span="12">
							<el-form-item label="电梯名称:" prop="eleName">
								<el-input :placeholder="fign.eleName || '暂无内容'" disabled v-model="fign.eleName" class="einp">
									<el-button slot="append" type="primary" @click="selectElevator()">选择电梯</el-button>
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="应急类型:" prop="sosType">
								<el-select v-model="fign.sosType" placeholder="请输入" class="einp">
									<el-option v-for="item in typeDate.SOS_TYPE" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin:10px 0">
						<el-col :span="12">
							<el-form-item label="故障来源:" prop="sourceType">
								<el-select v-model="fign.sourceType" placeholder="请输入" class="einp">
									<el-option v-for="item in gzList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" type="primary" @click="dioSub">确 定</el-button>
				<el-button size="small" @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>

		<SelectElevator v-if="selectOtherDialogVisible" ref="SelectElevator" @transferElevator="selectOtherResult" />

	</div>
</template>
<script>
	import process from './components/process'
	import operational from './components/operational'
	import alarmMessage from './components/alarmMessage'
	import Pagination from '@/components/Pagination'
	import SelectElevator from './components/selectElevator'
	// import { formatDate } from "@/util";

	// let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
	// let current_time1 = new Date(current_time).getTime();
	// let starTime = new Date(current_time1 - 30 * 24 * 60 * 60 * 1000);
	// let endTime = new Date(current_time1);
	export default {
		components: {
			process,
			Pagination,
			operational,
			alarmMessage,
			SelectElevator
		},
		data() {
			return {
				// 选择电梯参数
				selectOtherDialogVisible: false, // 选择电梯
				// dialog参数
				tit: '',
				dialogVisible: false,
				fign: {},
				// 表单限制
				rules: {
					eleName: [{
						required: true,
						message: '请选择电梯',
						trigger: 'change'
					}],
					sosType: [{
						required: true,
						message: '请选择应急类型',
						trigger: 'blur'
					}],
					sourceType: [{
						required: true,
						message: '请选择故障来源',
						trigger: 'blur'
					}],
					areaCodeArr: [{
						required: true,
						message: '请选择所属区域',
						trigger: 'change'
					}]
				},
				// 报警详情
				detailsItem: {
					seleId: 1,
					seleArr: [{
						value: 1,
						label: '报警消息'
					}, {
						value: 2,
						label: '运行参数'
					}, {
						value: 3,
						label: '处置流程'
					}]
				},
				dioDetails: false,
				dioDetailsItem: null,
				// 表格参数
				formInline: {
					current: 1,
					size: 10,
       				// allTime: [starTime, endTime]
				},
				tableData: [],
				// 分页参数
				dictionarTotal: 0,
				// 状态
				typeDate: [], //字典表
				stateList: [{
					value: 1,
					label: '待处理'
				}, {
					value: 2,
					label: '已处理'
				}, {
					value: 3,
					label: '进行中'
				}, {
					value: 4,
					label: '已完成'
				}],
				// 故障来源
				gzList: [{
					value: 1,
					label: '维保发现'
				}, {
					value: 2,
					label: '救援发现'
				}, {
					value: 3,
					label: '巡逻发现'
				}, {
					value: 4,
					label: '乘梯发现'
				// }, {
				// 	value: 5,
				// 	label: '平台发现'
				}, {
					value: 6,
					label: '监控发现'
				}]
			}
		},
		methods: {
			// 选择电梯返回
			selectOtherResult(data) {
				console.log('选择电梯返回', data)
				this.fign.eleData = data
				this.fign.eleId = data.id
				this.$set(this.fign, 'eleName', data.lab)
			},
			selectElevator() {
				// 选择电梯
				console.log('选择电梯')
				this.selectOtherDialogVisible = true
				this.$nextTick(() => {
					this.$refs.SelectElevator.init(
						this.fign.eleData, (refresh) => {
							if (refresh) {}
						}
					)
				})
			},
			// 详情操作按钮
			sosClick(type) {
				this.dioDetailsItem.sosStatus = 2
				this.$http
					.post("/api/ele/app/sos/submit", {
						id: this.dioDetailsItem.id,
						submitType: type,
						sosTime: this.dioDetailsItem.sosTime
					})
					.then((res) => {
						if (res.data.success) {
							this.$message.success(res.data.msg)
						} else {
							this.$message.error(res.data.msg)
						}
						this.onRefer()
					})
			},
			// 详情
			showDetails(item) {
				this.$http
					.post("/api/ele/web/rescueTask/getInfo", {
						id: item.id,
						sosTime: item.sosTime,
					})
					.then((res) => {
						if (res.data.success) {
							res.data.data.sosVideo.forEach((qs) => {
								let url = qs.videoUrl.split('?');
								qs.videoUrl = url[0]
								qs.videoName = url[1].split('&')[0].split('=')[1]
								// qs.videoName = url[8]
							})

							res.data.data.sosTypeName = item.sosTypeName
							res.data.data.sosStatusName = this.stateList.find(item => item.value === res.data.data
									.sosStatus) ? this.stateList.find(item => item.value === res.data.data.sosStatus)
								.label : ''
							res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
							res.data.data.speedarr = res.data.data.speedRecord.split(',');

							this.detailsItem.seleId = 1
							this.dioDetailsItem = res.data.data
							this.dioDetails = true

						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
			// 重置按钮事件
			reset() {
				console.log('重置')
				this.formInline = {
					current: 1,
					size: 10,
       				// allTime: [starTime, endTime]
				}
				this.onRefer()
			},
			// 分页
			dictionarPage(data) {
				this.formInline.current = data.page
				this.formInline.size = data.limit
				this.onRefer()
			},
			// 获取类型
			getType(type, sta) {
				let obj
				let arr = []
				if (sta == 1) {
					arr = this.typeDate.SOS_TYPE
				} else {
					arr = this.stateList
				}
				arr.forEach((qs) => {
					if (qs.value == type) {
						obj = qs.label
					}
				})
				return obj
			},
			// 表格方法
			onRefer(ishm) {
				if (ishm) {
					this.formInline.current = 1
				}
				// if (this.formInline.allTime) {
				// 	let atime = JSON.parse(JSON.stringify(this.formInline.allTime))
				// 	this.formInline.start = new Date(atime[0]).getTime()
				// 	this.formInline.end = new Date(atime[1]).getTime() + 24 * 60 * 60 * 1000 - 1000 
				// } else {
				// 	this.$message.error('请选择查询时间')
				// 	return
				// }
				this.$http
					.post("/api/ele/web/rescueTask/getList", this.formInline)
					.then((res) => {
						console.log('列表', res.data)
						if (res.data.success) {
							res.data.data.records.forEach((qs) => {
								qs.sosTypeName = this.getType(qs.sosType, 1)
								qs.sosStatusName = this.getType(qs.sosStatus, 2)
								qs.newStatus = qs.status == 2 ? 3 :qs.status
							})
							this.tableData = res.data.data.records
							this.dictionarTotal = res.data.data.total
							this.$nextTick(() => {
								if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
			addDj() {
				this.tit = '新增'
				this.fign = {}
				this.dialogVisible = true
			},
			// 弹出框提交
			dioSub() {
				console.log('this.fign', this.fign)

				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						let data = JSON.parse(JSON.stringify(this.fign))
						let url = '/api/ele/web/rescueTask/add'
						this.$http
							.post(url, data)
							.then((res) => {
								if (res.data.success) {
									this.onRefer()
									this.dialogVisible = false
									this.$message.success(res.data.msg)
								} else {
									this.$message.error(res.data.msg)
								}
							})
					} else {
						this.$message.warning('请验证填写内容!');
						return false;
					}
				})
			},
		},
		created() {
			this.$http
				.post(`/api/system/web/dict/dictionary?codes=SOS_TYPE`)
				.then((res) => {
					if (res.data.success) {
						this.typeDate = res.data.data
						this.onRefer()
					}
				})
		}
	}
</script>
<style scoped>
	.yeScroll {
		overflow-y: scroll;
	}

	.noScroll {
		overflow-y: none;
	}

	.tabBut {
		width: 80%;
		margin-left: 10%;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-top {
		width: 100%;
		margin-bottom: 20px;
	}

	.page-bom {}
</style>
