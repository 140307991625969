<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯ID</span>
					</span>
					<el-input v-model="formInline.elevatorId" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯名称</span>
					</span>
					<el-input v-model="formInline.eleName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">评分日期</span>
					</span>
					<el-date-picker style="width: 220px;" v-model="formInline.allTime" type="daterange"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">风险等级</span>
					</span>
					<el-select v-model="formInline.riskLevel" placeholder="请输入" class="einp" clearable>
						<el-option v-for="item in wbType" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onRefer(true)">查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div v-if="dictionarTotal!=0">
			<el-table :data="tableData" ref="multipleTable" style="width: 100%;margin-bottom: 20px;" :height="$store.state.tabHeight">
				<el-table-column fixed="left" type="index" label="序号" width="50px" />
				<el-table-column align='center' prop="elevatorId" label="电梯ID" />
				<el-table-column align='center' prop="eleName" label="电梯名称" />
				<el-table-column align='center' prop="createTime" label="评分日期" />
				<el-table-column align='center' label="风险等级">
					<template slot-scope="scope">
						{{scope.row.riskLevel}}级风险
					</template>
				</el-table-column>
				<el-table-column align='center' prop="totalPoints" label="总得分" />
				<el-table-column align='center' prop="createTime" label="创建时间" />
				<el-table-column fixed="right" align='center' label="操作" width="120px">
					<template slot-scope="scope">
						<el-button v-focus @click="showScore(scope.row)" type="primary" size="small">评分明细</el-button>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
				@pagination="dictionarPage" />
		</div>
		<div class="nullDate" v-else>
			<img src="@/assets/nullDate.png" style="width: 260px;" />
		</div>

		<el-dialog title="评分明细" :visible.sync="dialogVisible" :close-on-click-modal="false" width="800px" top="20vh">
			<div style="height: 40vh;width: 100%;overflow-y: scroll;" class="scrollElement">
				<div class="pBox">
					<div class="pSpan">电梯ID:</div>
					<div>{{dioData.elevatorId}}</div>
				</div>
				<div class="pBox">
					<div class="pSpan">电梯名称:</div>
					<div>{{dioData.eleName}}</div>
				</div>
				<div class="pBox">
					<div class="pSpan">评分日期:</div>
					<div>{{dioData.createTime}}</div>
				</div>
				<div class="pBox">
					<div class="pSpan">风险等级:</div>
					<div>{{dioData.riskLevel}}级风险</div>
				</div>
				<div class="pBox">
					<div class="pSpan">总得分:</div>
					<div>{{dioData.totalPoints}}分</div>
				</div>
				<el-table :data="dioData.detailList" style="width: 98%;margin-top: 20px;" max-height="600">
					<el-table-column align='center' type="index" label="编号" width="50px" />
					<el-table-column align='center' prop="name" label="风险因素" />
					<el-table-column align='center' prop="value" label="统计数值" />
					<el-table-column align='center' prop="point" label="得分" />
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer" style="justify-content: center;display: flex;">
				<el-button size="small" type="primary" @click="editShow">确 定</el-button>
			</span>
		</el-dialog>

	</div>
</template>
<script>
	var valifailureDesc = (rule, value, callback) => {
		let operation = /^.{4,600}$/
		inpYz(rule, value, callback, true, operation, '可输入中文，英文，数值，特殊符号，长度为4至600')
	};

	import Pagination from '@/components/Pagination'
	export default {
		components: {
			Pagination,
		},
		data() {
			return {
				// 表格参数
				formInline: {
					current: 1,
					size: 10,
				},
				tableData: [],
				// 分页参数
				dictionarTotal: 0,
				// 维保类型
				wbType: [],
				// 弹窗数据
				dioData: {},
				dialogVisible: false
			}
		},
		methods: {
			editShow() {
				this.dialogVisible = false
			},
			showScore(item) {
				item.detailList = JSON.parse(item.detail)
				this.dioData = item
				this.dialogVisible = true
			},
			// 重置按钮事件
			reset() {
				this.formInline = {
					current: 1,
					size: 10,
				}
				this.onRefer()
			},
			// 分页
			dictionarPage(data) {
				this.formInline.current = data.page
				this.formInline.size = data.limit
				this.onRefer()
			},
			// 表格方法
			onRefer(ishm) {
				if (ishm) {
					this.formInline.current = 1
				}
				console.log(this.formInline.allTime)
				if (this.formInline.allTime != null && this.formInline.allTime != undefined) {
					this.formInline.start = new Date(this.formInline.allTime[0]).getTime()
					this.formInline.end = new Date(this.formInline.allTime[1]).getTime()
				} else {
					this.formInline.start = null
					this.formInline.end = null
				}
				this.$http
					.post("/api/ele/web/riskGradeRecord/list", this.formInline)
					.then((res) => {
						console.log('列表', res.data)
						if (res.data.success) {
							res.data.data.records.forEach((qs) => {
								qs.statusName = this.getType(qs.status, 2)
							})
							this.tableData = res.data.data.records
							this.dictionarTotal = res.data.data.total
							this.$nextTick(() => {
								if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
			// 获取类型
			getType(type, sta) {
				let obj
				this.wbType.forEach((qs) => {
					if (qs.value == type) {
						obj = qs.label
					}
				})
				return obj
			}
		},
		created() {
			this.$http
				.post(`/api/system/web/dict/dictionary?codes=ELE_RISK_LEVEL`)
				.then((res) => {
					if (res.data.success) {
						this.wbType = res.data.data.ELE_RISK_LEVEL
						this.onRefer()
					}
				})
		}
	}
</script>
<style scoped>
	.pBox {
		font-size: 18px;
		display: flex;
	}

	.pSpan {
		width: 100px;
	}

	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-top {
		width: 100%;
		margin-bottom: 20px;
	}

	.page-bom {}
</style>
