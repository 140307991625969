<template>
  <el-dialog
    :title="'选择生效单位'"
    :visible.sync="visible"
    append-to-body
    :close-on-click-modal="false"
    width="1400px"
    @close="selectunitDialogClose"
  >
    <div class="admin-project-myproject-container">
      <el-form
        :inline="true"
        ref="searchForm"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item>
          <span slot="label">
            <span class="lab-span">维保单位</span>
          </span>
          <el-input
            v-model="searchForm.orgName"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="searchBtn"
            @click="searchOnRefer"
          >查询</el-button>
          <el-button
            type="warning"
            @click="reset"
          >重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="selectUnitList"
        :data="selectUnitList"
        border
        stripe
        fit
        max-height="500px"
        highlight-current-row
        row-key="id"
        @select="handleSelectionChange"
        @select-all="selectAll"
      >
        <el-table-column
          type="selection"
          reserve-selection
          width="55px"
          align="center"
        />
        <el-table-column
          label="序号"
          type="index"
          prop="index"
          width="50px"
          align="center"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="单位名称"
          min-width="150px"
        />
        <el-table-column
          align="center"
          prop="address"
          label="单位地址"
          min-width="180px"
        />
        <el-table-column
          align="center"
          prop="type"
          label="单位类型"
          min-width="120px"
        >
          <template slot-scope="scope">
            <span>{{
             scope.row.type === 'MAINTAIN_UNIT' ? '维保单位' : ''
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="contact"
          label="联系人"
          min-width="120px"
        />
        <el-table-column
          align="center"
          prop="phone"
          label="联系方式"
          min-width="150px"
        />
        <el-table-column
          align="center"
          prop="areaName"
          label="所属区域"
          min-width="120px"
        />
        <el-table-column
          align="center"
          prop="createUserName"
          label="创建人"
          min-width="120px"
        />
      </el-table>
      <Pagination
        v-show="selectUnitListTotal > 0"
        :total="selectUnitListTotal"
        :page.sync="searchForm.current"
        :limit.sync="searchForm.size"
        @pagination="selectUnitListGet"
      />
    </div>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="small"
        type="primary"
        @click="selectunitConfirm"
      >确 定</el-button>
      <el-button
        size="small"
        @click="selectunitDialogClose"
      >取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Pagination from '../../../../components/Pagination'

export default {
  components: {
    Pagination,
  },

  data() {
    return {
      visible: false,
      loading: true,
      selectUnitListQuery: {
        current: 1,
        size: 10,
      },
      selectUnitList: [],
      selectUnitListTotal: 0,
      selectunitCallback: null,
      selectRow: {},
      unitId: [],
      selectData: [],
      initArr: [],

      // 表格参数
      searchForm: {
        orgName: '',
        current: 1,
        size: 10,
      },
    }
  },
  methods: {
    init(id, selectData, callback) {
      console.log(id, '1231321313213213')
      this.selectunitCallback = callback
      this.unitId = id ?? []
      this.initArr = selectData
      this.visible = true
      this.loading = false
      // this.selectUnitListQuery = {
      //   current: 1,
      //   size: 10,
      // }
      this.$nextTick(() => {
        this.selectUnitListGet()
      })
    },

    // 搜索
    searchOnRefer() {
      this.searchForm.current = 1
      this.selectUnitListGet()
    },
    // 重置
    reset() {
      console.log('重置')
      this.searchForm = {
        orgName: '',
      }
      this.selectUnitListGet()
    },

    // 全选
    selectAll(selection) {
      let stateArr = []
      if (selection.length > 0) {
        stateArr = JSON.parse(JSON.stringify(selection))
        const arr = [...selection, ...this.initArr]
        // 去重
        const res = new Map()
        this.initArr = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1))
        console.log(stateArr)
      } else {
        stateArr = JSON.parse(JSON.stringify(this.selectElevatorList))
        stateArr.map((item) => {
          if (this.initArr.find((row) => row.id === item.id)) {
            this.initArr = this.initArr.filter((row) => row.id !== item.id)
          }
        })
      }
      console.log(this.initArr, 'selection')
    },
    // 选择
    handleSelectionChange(val, row) {
      console.log(row, 'row')
      console.log(this.initArr, 'this.initArr')
      if (this.initArr.find((item) => item.id === row.id)) {
        this.initArr = this.initArr.filter((item) => item.id !== row.id)
      } else {
        this.initArr.push(row)
      }
    },

    selectUnitListGet() {
      const params = this.searchForm
      this.loading = true
      this.$http
        .post('/api/ele/web/maintainRules/getOrgList', params)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.loading = false
            this.selectUnitList = data.list ?? []
            this.selectUnitListTotal = data.total ?? 0
            this.$nextTick(() => {
              if (this.selectUnitListTotal > 0 && this.$refs.selectUnitList) {
                this.$refs.selectUnitList.bodyWrapper.scrollTop = 0;
              }
              if (this.unitId.length > 0) {
                this.selectUnitList.forEach((ele) => {
                  this.unitId.forEach((item) => {
                    if (item === ele.id) {
                      this.$refs.selectUnitList.toggleRowSelection(ele, true)
                    }
                  })
                })
              }
            })
          }
        })
    },

    async selectunitConfirm() {
      const unitData = this.initArr

      if (unitData.length > 0) {
        console.log(unitData, 'unitData')
        this.$emit('transferUnit', unitData)
        this.visible = false
      } else {
        this.$message({
          message: '请选择单位',
          type: 'error',
        })
      }
    },

    selectunitDialogClose() {
      this.$refs.selectUnitList.setCurrentRow()
      this.$refs.selectUnitList.clearSelection()
      this.searchForm.orgName = ''
      this.visible = false
    },
  },
}
</script>
