<template>
  <div>
    <div
      style="height: 40vh; overflow-y: scroll; padding-right: 20px"
      class="scrollElement"
    >
      <el-form
        label-position="right"
        label-width="120px"
        ref="personnelForm"
        :model="personnelForm"
        :rules="formRules"
      >
        <el-row style="margin: 10px 0">
          <el-col :span="12">
            <el-form-item label="人员名称:" prop="name">
              <el-input
                v-model="personnelForm.name"
                placeholder="请输入"
                class="wid90"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式:" prop="contactWay">
              <el-input
                class="wid90"
                v-model="personnelForm.contactWay"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin: 10px 0">
          <el-col :span="12">
            <el-form-item label="app账号:" prop="account">
              <el-input
                class="wid90"
                v-model="personnelForm.account"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="app密码:" prop="oriPassword" :rules="tit == '添加人员' ? formRules.oriPassword : editformRules.oriPassword">
              <el-input
                class="wid90"
                v-model="personnelForm.oriPassword"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin: 10px 0">
          <el-col :span="12">
            <el-form-item label="证书编号:" prop="certificateCode">
              <el-input
                class="wid90"
                v-model="personnelForm.certificateCode"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证书有效期:" prop="validityTime">
              <el-date-picker
                class="wid90"
                v-model="personnelForm.validityTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                @blur="changeDate"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin: 10px 0">
          <el-col :span="12">
            <el-form-item label="是否启用账号:" prop="isLogin">
              <el-select
                class="wid90"
                v-model="personnelForm.isLogin"
                placeholder="请输入"
                @change="changeLogin"
              >
                <el-option :key="0" label="是" :value="0" />
                <el-option :key="1" label="否" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户类型:" prop="type">
              <el-select
                class="wid90"
                multiple
                v-model="changeUseType"
                placeholder="请输入"
                @change="changeType"
              >
                <el-option
                  v-for="item in personnelType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="职位:" prop="position">
              <el-select
                class="wid90"
                v-model="personnelForm.position"
                placeholder="请输入"
              >
                <el-option
                  v-for="item in positionType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="tit === '添加人员'" :span="12">
            <el-form-item label="是否加入单位">
              <el-input
                disabled
                :placeholder="personnelForm.unitName || '暂无内容'"
                v-model="personnelForm.unitName"
                class="wid90"
              >
                <el-button slot="append" type="primary" @click="selectUnit()"
                  >选择单位</el-button
                >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col v-else-if="tit === '添加人员'" :span="12">
            <el-form-item label="是否加入单位">
              <el-input
                disabled
                :placeholder="personnelForm.unitName || '暂无内容'"
                v-model="personnelForm.unitName"
                class="wid90"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <ChangeUnit
        ref="changeUnit"
        :personnelData="personnelData"
        :unitList="unitChangeList"
        :changeUnitType="changeUnitType"
        @getUnitInfo="getUnitInfo"
      />
    </div>
    <div style="text-align: right; padding-right: 20px" class="dialog-footer">
      <el-button size="small" type="primary" :loading="loading" @click="dioSub"
        >确 定</el-button
      >
      <el-button size="small" @click="dialogClose">取 消</el-button>
    </div>
  </div>
</template>
<script>
const pageType = "EMPLOYEE_MANAGE";
import ChangeUnit from "./changeUnit";
export default {
  components: {
    ChangeUnit,
  },
  props: ["tit",'useType', "personnelForm"],
  data() {
    var validateName = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,6}$/,
        "人员名称可输入长度为2-6的中文，字母，数值",
        callback
      );
    };
    var validatePhone = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^[0-9-()（）]{8,15}$/,
        "联系方式输入11位的数值或 输入完整的座机号码!",
        callback
      );
    };
    var validateAccount = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^[a-zA-Z0-9]{4,9}$/,
        "app账号可输入长度为4-9的数值，字母!",
        callback
      );
    };
    var validateoriPwd = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%&^*()_——\-+=<>;？?:""{}|「」,.\\/'\[\]·！￥（）《》？：“”【】、；‘’，。]).{8,16}$/,
        "密码长度为：8-16位，且必须包含大小写字母，数字与特殊符号",
        callback
      );
    };
    var validateEditoriPwd = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        false,
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%&^*()_——\-+=<>;？?:""{}|「」,.\\/'\[\]·！￥（）《》？：“”【】、；‘’，。]).{8,16}$/,
        "密码长度为：8-16位，且必须包含大小写字母，数字与特殊符号",
        callback
      );
    };
    var validateCode = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        false,
        /^[a-zA-Z0-9]{6,24}$/,
        "证书编号输入长度为6-24的字母，数值!",
        callback
      );
    };
    return {
      // 人员类型
      personnelType: [],
      loading: false,
      selectRow: [], // 选中人员
      personnelData: {}, // 人员数据
      formRules: {
        name: [
          {
            required: true,
            validator: validateName,
            trigger: "blur",
          },
        ],
        contactWay: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            // validator: validateAccount,
            validator: this.changeAccount,
            trigger: "blur",
          },
        ],
        oriPassword: [
          {
            required: true,
            validator: validateoriPwd,
            trigger: "blur",
          },
        ],
        certificateCode: [
          {
            validator: validateCode,
            trigger: "blur",
          },
        ],
        isLogin: [
          {
            required: true,
            message: "这里不能为空",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "类型不能为空",
            trigger: "blur",
          },
        ],
        position: [
          {
            required: true,
            message: "职位不能为空",
            trigger: "blur",
          },
        ],
      },
      editformRules: {
        oriPassword: [
          {
            required: false,
            validator: validateEditoriPwd,
            trigger: "blur",
          }
        ]
      },
      unitChangeList: [],
      changeUnitType: "", // 进入单位选择类型
      changeUseType: this.useType,
      userList: [],
      positionType: []
    };
  },
  filters: {},
  methods: {
    // 获取字典数据
    getDictionar(code) {
      // code = code.join()
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res;

            this.personnelType = [...data.RELATED_CODES];
            data.UNIT_LIST.forEach((element) => {});
            this.unitList = data.UNIT_LIST;
            this.positionType = data.POSITION
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    
    changeAccount(rule, value, callback) {
      console.log(value,'value123213');
      if (value && value !== '') {
        if (/^[a-zA-Z0-9]{4,9}$/.test(value)) {
          const data = {
            account: value
          }
          data.id = this.tit == '编辑人员' ? this.personnelForm.id : null
          this.$http.post("/api/system/web/appUser/getUserExists", data).then((res) => {
            if (res.data) {
              callback(new Error('已存在app账号'))
            } else {
              callback()
            }
          });
        } else {
          callback(new Error('app账号只可输入长度为4-9的数值或字母!'))
        }
      } else {
        callback(new Error('app账号不能为空'))
      }
    },
    // 弹出框提交
    dioSub() {
      this.loading = true;
      this.$refs.personnelForm.validate(async (valid) => {
        if (valid) {
          this.personnelForm.certificateStartTime = this.personnelForm
            .validityTime
            ? this.personnelForm.validityTime[0]
            : "";
          this.personnelForm.certificateEndTime = this.personnelForm
            .validityTime
            ? this.personnelForm.validityTime[1]
            : "";
          this.$emit("subForm", this.personnelForm);
        }
      });
      this.loading = false;
    },
    dialogClose() {
      this.$emit("colseForm");
    },
    // 改变启用账号
    changeLogin(val) {
      this.$forceUpdate();
      this.$set(this.personnelForm, "isLogin", val);
    },
    changeType(data) {
      this.changeUseType = data
      this.$emit('changeUseType', this.changeUseType)
      this.personnelForm.type = data.join();
    },
    selectUnit() {
      this.personnelForm.type = this.changeUseType.join()
      if (this.changeUseType.length === 0) {
        this.$message.error("请先选择用户类型");
        return;
      }
      this.changeUnitType = "加入单位";
      this.unitChangeList = [];
      this.$nextTick(() => {
        let arr = [];
        this.personnelData = {
          type: this.personnelForm.type,
          orgName: this.personnelForm.unitName
        };
        if (this.personnelForm.type.indexOf(",") > -1) {
          arr = this.personnelForm.type.split(",");
        } else {
          arr.push(this.personnelForm.type);
        }
        this.unitList.map((item) => {
          arr.map((item1) => {
            if (item.value === item1) {
              this.unitChangeList.push(item);
            }
          });
        });
        this.$refs.changeUnit.init(() => {
          // this.onRefer();
        });
      });
    },
    getUnitInfo(data) {
      this.personnelForm.orgId = data.orgId;
      this.$set(this.personnelForm, "unitName", data.orgName);
    },
    // 时间改变
    changeDate() {
      this.$forceUpdate();
    },
  },
  created() {
    this.getDictionar(["RELATED_CODES", "UNIT_LIST", 'POSITION']);
  },
  mounted() {
    this.$nextTick(() => {
      // this.onRefer();
    });
  },
  destroyed(){
    console.log('销毁');
  }
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBtn {
  margin-left: 15px;
}

.wid90 {
  width: 95%;
}

/deep/.el-checkbox {
  color: #fff;
}

/deep/.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #c7c7c7;
}
</style>
