<template>
	<el-dialog title="执行状态" :visible.sync="visible" :close-on-click-modal="false" append-to-body width="1400px"
		top="8vh" center @close="closeDialog">
		<div class="admin-project-myproject-container scrollElement" style="height: 68vh;">
			<div class="dataList">
				<el-table :data="planData" ref="multipleTable" border max-height="500px" style="width: 100%;">
					<el-table-column label="序号" type="index" width="50px" align="center">
						<template slot-scope="scope">
							<span>{{ (planFrom.current - 1) * planFrom.size + scope.$index + 1 }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="eleName" align='center' label="电梯名称" min-width="150px" />
					<el-table-column prop="code" label="状态" min-width="120px" align='center' >
						<template slot-scope="scope">
							{{ statusType(scope.row.status) }}
						</template>
					</el-table-column>
					<el-table-column prop="startTime" label="任务时间" min-width="150px" align='center' />
					<el-table-column prop="signTime" label="签到时间" min-width="120px" align='center' />
					<el-table-column prop="finishTime" label="完成时间" min-width="150px" align='center' />
					<el-table-column align="center" fixed="right" label="操作" width="150px">
						<template slot-scope="scope">
							<template v-if="scope.row.status == 4 || scope.row.status == 5">
								<el-button
									v-focus
									@click="detail(scope.row)"
									type="primary"
									size="small"
								>维保详情
								</el-button>
							</template>
						</template>
					</el-table-column>
				</el-table>
				<Pagination :total="planTotal" :page.sync="planFrom.current"
					:limit.sync="planFrom.size" @pagination="elevatorInfoPage" />
			</div>
		</div><span slot="footer" class="dialog-footer">
		</span>
		
		<el-dialog
			v-if='dioDetails'
			append-to-body
			:close-on-click-modal='false'
			title="维保详情"
			:visible.sync="dioDetails"
			width="1200px"
			top='20vh'>
			<div style="height: 50vh;overflow-y: scroll;" class="scrollElement">
				<DioDetails :dioData="dioDetailsItem"></DioDetails>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button v-focus size="small" type="primary" @click="dioDetails = false">关 闭</el-button>
			</span>
		</el-dialog>
	</el-dialog>
</template>

<script>
	import Pagination from '../../../../components/Pagination'
	import DioDetails from '../../wbjl/components/dioDetails'
	export default {
		components: {
			Pagination,
			DioDetails
		},
		data() {
			return {
				eleId: null, // 合同id
				loading: true,
				visible: false,
				planData: [],
				planTotal: 0,
				planFrom: {
					current: 1,
					size: 10,
				},
				addBrandCallback: null, // 弹窗回调
				
				// 维修详情
				dioDetails: false,
				dioDetailsItem: null,
			}
		},
		props: {},

		mounted() {},
		created() {},

		methods: {
			// 电梯id，回调
			init(id, callback) {
				this.addBrandCallback = callback
				this.visible = true
				this.loading = false
				this.eleId = id
				this.$nextTick(() => {
					this.getelevatorInfo()
				})
			},
			statusType(status) {
				let type = '-'
				switch (status) {
					case 0:
						type = '待处理'
						break;
					case 1:
						type = '已签到'
						break;
					case 2:
						type = '已维保'
						break;
					case 3:
						type = '已驳回'
						break;
					case 4:
						type = '已完成'
						break;
					case 5:
						type = '已超期'
						break;
					default:
					break;
				}
				return type
			},

			// 分页
			elevatorInfoPage(data) {
				this.planFrom.current = data.page
				this.planFrom.size = data.limit
				this.getelevatorInfo()
			},

			// 获取列表
			getelevatorInfo() {
				const data = JSON.parse(JSON.stringify(this.planFrom))
				data.planId = this.eleId
				this.$http
					.post('/api/ele/web/maintainTask/getList', data)
					.then((res) => {
						if (res.data.code === 200) {
							const { data: { data } } = res
							this.planData = data.records ??[]
							this.planTotal = data.total ?? 0
							this.$nextTick(() => {
								if (this.planTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						}
					})
			},

			detail (data) {
				this.dioDetailsItem = data
				this.dioDetails = true
			},
			closeDialog() {
				console.log('closeDialog')
				this.visible = false
				this.$emit('closeExecute')
			},
		},
	}
</script>
<style lang='scss' scoped>
	.searchBox {
		/deep/.el-form-item__label {
			color: #00f6ff !important;
			font-size: 14px;
		}

		.searchBtn {
			margin-left: 15px;
		}
	}
</style>
