<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">日期</span>
          </span>
          <el-date-picker
            v-model="searchForm.allTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">报警类型</span>
          </span>
          <el-select v-model="searchForm.sosType" placeholder="请输入" class="einp" clearable>
            <el-option v-for="item in sosType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer"
            >查询</el-button
          >
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <template v-if="total != 0">
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px"
          :height="$store.state.tabHeight"
        >
          <el-table-column type="index" label="序号" />
          <el-table-column align="center" prop="userName" label="人员名称" />
          <el-table-column align="center" prop="code" label="设备编号" />
          <el-table-column align="center" label="报警类型">
            <template slot-scope="scope">
              {{
                scope.row.sosType
                  ?( sosType.find((item) => item.value == scope.row.sosType)
                    ? sosType.find((item) => item.value == scope.row.sosType)
                        .label
                    : "-")
                  : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sosTime" label="报警时间" />
          <el-table-column align="center" prop="address" label="报警位置" />
          <el-table-column
            fixed="right"
            align="center"
            prop="address"
            label="操作"
            width="300px"
          >
            <template slot-scope="scope">
              <el-button @click="detail(scope.row)" type="warning" size="small"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :layout="'total, sizes, prev, pager, next'"
          :page.sync="searchForm.current"
          :limit.sync="searchForm.size"
          @pagination="personnelPage"
        />
      </template>

      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
      <SosInfo v-if="detailVisible" :detailData="detailData" :sosType="sosType" ref="sosInfo" @closeInfo="closeInfo" />
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import SosInfo from '../../bjjl/components/info';
export default {
  components: {
    Pagination,
    SosInfo,
  },
  props: ["useId"],
  data() {
    return {
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },
      tableData: [],
      // 分页参数
      total: 0,

      dioVisible: false,
      point: null,
      detailData: {},
      detailVisible: false,
      sosType: []
    };
  },
  filters: {},
  methods: {
    // 获取字典数据
    getDictionar(code) {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res;
            this.sosType = data.CAP_SOS_TYPE;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 分页
    personnelPage(data) {
      this.searchForm.current = data.page;
      this.searchForm.size = data.limit;
      this.onRefer();
    },

    // 搜索
    searchOnRefer() {
      this.searchForm.current = 1;
      this.onRefer();
    },
    // 重置
    reset() {
      console.log("重置");
      this.searchForm = {
        current: 1,
        size: 10,
      };
      this.onRefer();
    },

    // 获取人员方法
    onRefer() {
      const data = JSON.parse(JSON.stringify(this.searchForm));
      data.userId = this.useId;
      data.start = this.searchForm.allTime
        ? this.searchForm.allTime[0].getTime()
        : "";
      data.end = this.searchForm.allTime
        ? this.searchForm.allTime[1].getTime() + 24 * 60 * 60 * 1000 - 1000
        : "";
      this.$http.post("/api/cap/web/sos/getCapSosPage", data).then((res) => {
        if (res.data.success) {
          const {
            data: { data },
          } = res;
          this.tableData = data.records ?? [];
          this.total = data.total ?? 0;
          this.$nextTick(() => {
            if (this.total > 0 && this.$refs.multipleTable) {
              this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
            }
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 报警详情
    detail(row) {
      this.detailData = row;
      this.detailVisible = true;
      this.detailData.point = [row.ypoint.toFixed(6), row.xpoint.toFixed(6)];
      this.$nextTick(() => {
        this.$refs.sosInfo.init();
      });
    },
    // 详情关闭
    closeInfo() {
      this.detailVisible = false;
    },
    closeDialog() {
      this.dioVisible = false;
    },
  },
  created() {
    this.$nextTick(() => {
      this.getDictionar("CAP_SOS_TYPE");
    });
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.detailBox {
  height: 44vh;
  background: #65809bb3;
  border-radius: 5px;
  padding: 25px 15px;
  color: #fff;
  h4 {
    margin: 0;
    font-size: 15px;
  }
}
.mapBox {
  padding-left: 20px;
}
</style>
