<template>
  <div>
    <div class="page-top">
      <el-form
        :inline="true"
        ref="searchForm"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input
            v-model="searchForm.name"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">救援识别码</span>
          </span>
          <el-input
            v-model="searchForm.rescueCode"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯注册代码</span>
          </span>
          <el-input
            v-model="searchForm.code"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">AI摄像头ID</span>
          </span>
          <el-input
            v-model="searchForm.deviceCode"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="searchBtn"
            @click="searchOnRefer"
          >搜索</el-button>
          <el-button
            type="warning"
            @click="reset"
          >重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>

      <el-row>
        <el-col :span="12">

          <template v-if="tableData.length != 0">
            <el-table
              ref="singleTable"
              :data="tableData"
              highlight-current-row
              @current-change="handleCurrentChange"
              style="width: 100%; margin: 0px 15px 20px 0 "
            >
              <el-table-column
                type="index"
                label="序号"
                width="50px"
              />
              <el-table-column
                align="center"
                prop="name"
                label="电梯名称"
                min-width="200px"
              />
              <el-table-column
                align="center"
                prop="rescueCode"
                label="救援识别码"
                min-width="180px"
              />
              <el-table-column
                align="center"
                prop="code"
                label="电梯注册代码"
                min-width="180px"
              />
              <el-table-column
                align="center"
                prop="deviceCode"
                label="AI摄像头ID"
                min-width="180px"
              />
            </el-table>
            <Pagination
              :total="maintenRuleTotal"
              :page.sync="searchForm.current"
              :limit.sync="searchForm.size"
              @pagination="maintenRulePage"
            />
          </template>
          <div
            class="nullDate"
            v-else
          >
            <img
              src="@/assets/nullDate.png"
              style="width: 260px"
            />
          </div>
        </el-col>
        <el-col :span="12">
          <div class="videoList">
            <el-form
              :inline="true"
              ref="searchVideoForm"
              :model="searchVideoForm"
              class="demo-form-inline videoSearch"
            >
              <el-form-item>
                <span slot="label">
                  <span class="lab-span">日期</span>
                </span>
                <!-- <el-date-picker
                  v-model="searchVideoForm.value"
                  type="date"
                  format="yyyy-MM-dd"
                  placeholder="选择日期"
                  style="width:200px;margin-right:15px"
                /> -->
                <el-date-picker
                  v-model="searchVideoForm.dateTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="searchBtn"
                  @click="searchVideoList"
                >搜索</el-button>
                <el-button
                  type="primary"
                  class="searchBtn"
                  @click="localVideo"
                >本地视频</el-button>
              </el-form-item>
            </el-form>
            <h4 style="margin: 0;color: #04d7c7;">视频列表</h4>
            <div>
              <template v-if="videoListTotal != 0">
                <ul
                  ref="videoList"
                  class="videoListBox scrollElement"
                >
                  <li
                    class="videoListRow"
                    v-for="(item, index) in videoList"
                    :key="index"
                    :class="index === videoIndex ? 'videoActive' : ''"
                    @click="changeVideo(item, index)"
                  >
                    <div>{{item.url.split('?')[1].split('&')[0].split('=')[1]}} {{item.sosType?'('+toTypeName(item.sosType)+')':''}}{{item.userName?'('+'手动上传-'+item.userName+')':''}}</div>
                    <!-- <div>{{item.url.split('/')[item.url.split('/').length - 1]}}</div> -->
                    <div>
                      <el-button
                        size="small"
                        type="text"
                        style="color:#fff"
                        @click="checkVideo(item, index)"
                      >查看</el-button>
                      <el-button
                        size="small"
                        type="text"
                        style="color:#fff"
                        @click="downloadVideo(item)"
                      >下载</el-button>
                    </div>
                  </li>
                </ul>
                <Pagination
                  :total="videoListTotal"
                  :layout="'total, sizes, prev, pager, next'"
                  :page.sync="videoPage.current"
                  :limit.sync="videoPage.size"
                  @pagination="videoRulePage"
                />
              </template>
              <div
                class="nullDate"
                style="height:55vh"
                v-else
              >
                <img
                  src="@/assets/nullDate.png"
                  style="width: 260px"
                />
              </div>

            </div>
          </div>
        </el-col>
      </el-row>

    </div>

    <el-dialog
      :title="tit"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="950px"
      top="20vh"
    >
      <div
        style="height: 40vh;width: 87%; margin:15px auto;"
        class="scrollElement"
      >
        <video
          v-if="!loading"
          :src="!loading ? videoList[videoIndex].url : ''"
          style="width: 100%;height: 100%;"
          controls
          autoplay
        />

      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <div class="videoBottom">
          <div>
            <el-button
              size="small"
              :loading="loading"
              :disabled="videoIndex === 0 && videoPage.current === 1"
              type="primary"
              @click="previousVideo()"
            >上一条</el-button>
            <el-button
              size="small"
              :loading="loading"
              :disabled="videoIndex === videoList.length - 1 && Math.ceil(videoListTotal / videoPage.size) === videoPage.current "
              type="primary"
              @click="nextVideo()"
            >下一条</el-button>
          </div>
          <el-button
            size="small"
            type="primary"
            @click="downloadVideo(videoList[videoIndex])"
          >下 载</el-button>
        </div>
      </span>
    </el-dialog>


    <MonitorInfo
      ref="MonitorInfo"
      v-if="MonitorInfoVisible"
      :activeNameProps="'scsp'"
      :eleInfo='eleInfo'
      :elevatorId="elevatorId"
      :deviceCode="deviceCode"
      @closeMonitor="closeMonitor"
    />
  </div>
</template>
<script>
import Pagination from '../../../components/Pagination'
import MonitorInfo from '../dtjk/components/monitorInfo';
import { formatDate } from "@/util";
let current_time = new Date()
let current_time1 = new Date();
    current_time1.setMonth(current_time1.getMonth());
let starTime =  new Date(formatDate(current_time.setDate(1), "yyyy-MM-dd") + " 00:00:00");
let endTime = new Date(formatDate(current_time1, "yyyy-MM-dd") + " 00:00:00");
export default {
  components: {
    Pagination,
    MonitorInfo
  },
  data() {
    var validateFirstParty = (rule, value, callback) => {
      inpFirstPartyName(rule, value, callback)
    }
    return {
      statusList:[],
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },
      tableData: [],
      // 分页参数
      maintenRuleTotal: 0,
      // dialog参数
      tit: '',
      dialogVisible: false,
      loading: false,
      // 视频列表参数
      searchVideoForm: {
        dateTime: [starTime, endTime]
      },

      videoPage: {
        current: 1,
        size: 10,
      },
      videoList: [],
      videoListTotal: 0,
      elevatorId: '', // 电梯id
      videoUrl: '', // 视频地址
      videoIndex: 0, // 视频下表
      currentRow: null,

      MonitorInfoVisible: false,
      eleInfo: null, //总数据
      deviceCode: '', // 设备号
    }
  },
  filters: {},
  methods: {
    closeMonitor() {
      this.MonitorInfoVisible = false
    },
    // 分页
    maintenRulePage(data) {
      this.searchForm.current = data.page
      this.searchForm.size = data.limit
      this.onRefer()
    },
    // 视频
    videoRulePage(data) {
      this.videoPage.current = data.page
      this.videoPage.size = data.limit
      this.getVideo()
    },
    // 表格选中
    handleCurrentChange(val) {
      console.log(val,'val');
      if (val) {
        this.currentRow = val
        this.videoPage = {
          current: 1,
          size: 10,
        }
        this.elevatorId = val.id
        this.eleInfo = val
        this.deviceCode = val.deviceCode
        this.getVideo()
      }
    },
    // 本地视频
    localVideo() {
      // console.log(this.elevatorId,'this.elevatorId');
      this.MonitorInfoVisible = true
    },
    // 搜索视频列表
    searchVideoList() {
      this.getVideo()
    },
    // 下载视频
    downloadVideo(item) {
      window.open(item.url, '_blank')
    },
    // 切换视频
    changeVideo(item, index) {
      this.videoIndex = index
    },
    // 查看视频
    checkVideo(item, index) {
      this.tit = item.url.split('?')[1].split('&')[0].split('=')[1]
      this.dialogVisible = true
    },
    // 上一条
    previousVideo() {
      this.videoIndex--
      if (this.videoIndex < 0) {
        this.videoPage.current--
        this.getVideo('pre')
        this.$refs.videoList.scrollTop = this.$refs.videoList.scrollHeight
      } else {
        this.$refs.videoList.scrollTop -= 47
        this.tit = this.videoList[this.videoIndex].url
          .split('?')[1]
          .split('&')[0]
          .split('=')[1]
      }
    },
    // 下一条
    nextVideo() {
      this.videoIndex++
      if (this.videoIndex === this.videoPage.size) {
        this.videoPage.current++
        this.getVideo('next')
        this.$refs.videoList.scrollTop = 0
      } else {
        this.$refs.videoList.scrollTop += 47
        this.tit = this.videoList[this.videoIndex].url
          .split('?')[1]
          .split('&')[0]
          .split('=')[1]
      }
    },

    // 获取视频列表
    getVideo(type) {
      console.log(this.searchVideoForm,'this.searchVideoForm');
      const data = {
        elevatorId: this.elevatorId,
        type: 1,
        status: 1,
      }
      data.startTime = this.searchVideoForm.dateTime[0].getTime()
      data.endTime = this.searchVideoForm.dateTime[1].getTime() + 24 * 3600 * 1000 - 1000
      if (new Date(this.searchVideoForm.dateTime[0]).getMonth() != new Date(this.searchVideoForm.dateTime[1]).getMonth()) {
        this.$message.error('查询开始时间与结束时间仅限同一个月')
        return
      }
      this.loading = true
      this.$http
        .post(
          `/api/ele/web/eleLog/getElevatorLog?current=${this.videoPage.current}&size=${this.videoPage.size}`,
          data
        )
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.videoList = data.records ?? []
            this.videoListTotal = data.total ?? 0
            if (type === 'pre') {
              this.videoIndex = this.videoPage.size - 1
              this.tit = this.videoList[this.videoList.length - 1].url
                .split('?')[1]
                .split('&')[0]
                .split('=')[1]
            } else if (type === 'next') {
              this.videoIndex = 0
              this.tit = this.videoList[0].url
                .split('?')[1]
                .split('&')[0]
                .split('=')[1]
            }
            this.loading = false
          } else {
            this.videoList = []
            this.$message.error(res.data.msg)
          }
        })
    },

    // 搜索
    searchOnRefer() {
      this.searchForm.current = 1
      this.onRefer()
    },
    // 重置
    reset() {
      this.searchForm = {
        current: 1,
        size: 10,
      }
      this.onRefer()
    },
    // 获取维保规则方法
    onRefer() {
      this.$http
        .post('/api/ele/web/elevatorInfo/getPathList', this.searchForm)
        .then((res) => {
          console.log(res.data,123456)
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.tableData = data.records ?? []
            this.maintenRuleTotal = data.total ?? 0
            this.elevatorId = data.records.length !== 0 ? this.tableData[0].id : ''
            this.eleInfo = data.records.length !== 0 ? this.tableData[0] : {}
            this.deviceCode = data.records.length !== 0 ? this.tableData[0].deviceCode : {}
            this.$nextTick(() => {
              if (data.records.length > 0 && this.$refs.singleTable) {
                this.$refs.singleTable.bodyWrapper.scrollTop = 0;
                this.$refs.singleTable.setCurrentRow(this.tableData[0])
              }
            })
            this.getVideo()
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    //获取字典
    getDictionary(codes='SOS_TYPE'){
      this.$http.post(`/api/system/web/dict/dictionary?codes=${codes}`)
      .then((res) => {
          if (res.data.success) {
              this.statusList = res.data.data.SOS_TYPE
              this.onRefer()
          }
      })
    },
    toTypeName(val){
      let arr=this.statusList.filter(item=>item.value==val)
      return arr.length>0?arr[0].label:val
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.searchVideoForm.value = new Date()
      this.getDictionary()
    })
  },
}
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.current-row .el-table__cell {
  background-color: #265a6a !important;
}
.videoList {
  padding: 20px 10px 20px 15px;
  margin-left: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  .videoSearch {
    display: flex;
    justify-content: space-between;
  }
  .videoListBox {
    padding: 0;
    height: 45vh;
    max-height: 45vh;
    overflow-y: scroll;
    .videoListRow {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      padding: 6px 13px;
      border-bottom: 1px solid #fff;
    }
    .videoActive {
      background: #1282a5;
    }
  }
}
.videoBottom {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
