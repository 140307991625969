<template>
  <div>
    <div class="page-top">
      <el-form
        :inline="true"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item>
          <span slot="label">
            <span class="lab-span">标题</span>
          </span>
          <el-input
            v-model="searchForm.title"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">信息类型</span>
          </span>
          <el-select
            v-model="searchForm.type"
            placeholder="请输入"
            clearable
          >
            <el-option
              v-for="(item, index) in noticeAdminSearch"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">更新时间</span>
          </span>
          <el-date-picker
            v-model="searchForm.updateDate"
            style="width: 100%"
            type="date"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="searchBtn"
            @click="searchOnRefer"
          >搜索</el-button>
          <el-button
            type="warning"
            @click="reset"
          >重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button
        type="success"
        size="small"
        v-focus
        @click="addDj"
      >新增</el-button>
      <template v-if="noticeTotal != 0">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="$store.state.tabHeight"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50px"
          />
          <el-table-column
            align="center"
            prop="title"
            label="标题"
            min-width="250px"
          />
          <el-table-column
            align="center"
            prop="createName"
            label="创建人"
            min-width="150px"
          />
          <el-table-column
            align="center"
            prop="type"
            label="信息类型"
            width="100px"
          >
            <template slot-scope="scope">
              {{
                noticeAdmin.find((item) => item.value === scope.row.type)
                  ? noticeAdmin.find((item) => item.value === scope.row.type)
                      .label
                  : ''
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="status"
            label="状态"
            min-width="100px"
          >
            <template slot-scope="scope">
              {{ scope.row.status ? '发布' : '草稿箱' }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="pushTime"
            label="发布时间"
            min-width="150px"
          ><template slot-scope="scope">
              {{ scope.row.status ? scope.row.pushTime : '-' }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="updateTime"
            label="更新时间"
            min-width="150px"
          />
          <el-table-column
            fixed="right"
            align="center"
            prop="address"
            label="操作"
            class="fixedRight"
            width="300px"
          >
            <template slot-scope="scope">
              <el-button
                @click="check(scope.row)"
                type="primary"
                size="small"
                v-focus
              >查看
              </el-button>
              <el-button
                v-show="!scope.row.onlyRead"
                @click="edit(scope.row)"
                type="warning"
                size="small"
                v-focus
              >编辑
              </el-button>
              <el-button
                v-show="!scope.row.onlyRead"
                @click="del(scope.row)"
                type="danger"
                size="small"
                v-focus
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="noticeTotal"
          :page.sync="searchForm.current"
          :limit.sync="searchForm.size"
          @pagination="noticePage"
        />
      </template>
      <div
        class="nullDate"
        v-else
      >
        <img
          src="@/assets/nullDate.png"
          style="width: 260px"
        />
      </div>
    </div>
    <!-- 新增修改 -->
    <el-dialog
      :title="tit"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="950px"
      top="20vh"
      @close="infoElevatorDialogClose"
    >
      <div
        style="height: 40vh; overflow-y: scroll; padding-right: 20px"
        class="scrollElement"
      >
        <el-form
          v-if="!noticeFormDisabled"
          label-position="right"
          label-width="120px"
          ref="noticeForm"
          :model="noticeForm"
          :rules="formRules"
        >
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item
                label="标题:"
                prop="title"
              >
                <el-input
                  class="wid90"
                  v-model="noticeForm.title"
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="信息类型:"
                prop="type"
              >
                <el-select
                  class="wid90"
                  v-model="noticeForm.type"
                  placeholder="请输入"
                >
                  <template v-if="roleId == 1">
                    <el-option
                      label="系统公告"
                      value="SYSTEM_NOTICE"
                    />
                  </template>
                  <template v-else-if="roleId == 2">
                    <el-option
                      v-for="(item, index) in NoticeTypeSup"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    />
                  </template>
                  <template v-else>
                    <el-option
                      v-for="(item, index) in NoticeTypeCommon"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    />
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin: 10px 0">
            <el-col :span="24">
              <el-form-item
                label="公告内容:"
                prop="detail"
              >
                <quill-editor
                  v-model="noticeForm.detail"
                  :options="editorOption"
                  class="quillClass wid90"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div
          v-else
          class="noticeFormInfo"
        >
          <el-row class="noticeFormDetail">
            <el-col :span="6">信息类型：{{
                noticeAdmin.find((item) => item.value === noticeForm.type)
                  ? noticeAdmin.find((item) => item.value === noticeForm.type)
                      .label
                  : ''
              }}</el-col>
            <el-col :span="9">发布时间：
              <span v-if="noticeForm.status">
                {{ noticeForm.createTime }}
              </span>
            </el-col>
            <el-col :span="9">更新时间：
              <span v-if="noticeForm.status">
                {{ noticeForm.updateTime }}
              </span>
            </el-col>
          </el-row>
          <el-row class="noticeFormDetail">
            <el-col :span="24">
              <div class="gundong">
                <quill-editor
                  @focus="focus($event)"
                  v-model="noticeForm.detail"
                  :options="editorOption"
                  class="quillClass wid90"
                />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          type="primary"
          v-if="!noticeFormDisabled"
          @click="dioSub('发布')"
        >发 布</el-button>
        <el-button
          size="small"
          type="warning"
          :loading="loading"
          v-if="!noticeFormDisabled && !noticeForm.status"
          @click="dioSub('保存')"
        >保 存</el-button>
        <el-button
          size="small"
          @click="infoElevatorDialogClose"
        >取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from '../../../components/Pagination'
export default {
  components: {
    Pagination,
  },
  data() {
    var validateTitle = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^[\u4e00-\u9fa5_a-zA-Z0-9]{4,50}$/,
        '标题必须是中文，字母，数值，不可输入特殊符号，长度4-50',
        callback
      )
    }
    return {
      // 富文本编辑器
      editorOption: {
        modules: {
          toolbar: [
            ['bold'], // toggled buttons

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          ],
        },
      },
      timer: null,
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
        type: '',
      },
      // 公告类型
      noticeAdminSearch: [],
      noticeAdmin: [],
      // 公告弹窗类型
      NoticeTypeCommon: [],
      NoticeTypeSup: [],
      tableData: [],
      // 分页参数
      noticeTotal: 0,
      // dialog参数
      tit: '',
      dialogVisible: false,
      loading: false,
      noticeForm: {},
      formRules: {
        title: [{ required: true, validator: validateTitle, trigger: 'blur' }],
        type: [
          { required: true, message: '公告类型不能为空', trigger: 'blur' },
        ],
        detail: [
          { required: true, message: '公告内容不能为空', trigger: 'blur' },
        ],
      },
      selectOtherDialogVisible: false, // 选择电梯
      noticeFormDisabled: false, // 查看禁点
      roleId: JSON.parse(sessionStorage.getItem('adminInfo')).roleId,
      isChange: false, // 是否修改了
    }
  },
  filters: {},
  methods: {
    // 获取字典数据
    getNotice(type, code) {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          console.log('公告', res)
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res
            this.noticeAdmin = data.NOTICE_ADMIN
            this.noticeAdminSearch = [
              ...[{ label: '全部', value: '' }],
              ...data.NOTICE_ADMIN,
            ]
            this.NoticeTypeCommon = data.NOTICE_TYPE_COMMON
            this.NoticeTypeSup = data.NOTICE_TYPE_SUP
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    // 分页
    noticePage(data) {
      this.searchForm.current = data.page
      this.searchForm.size = data.limit
      this.onRefer()
    },

    // 弹出框提交
    dioSub(type) {
      this.loading = true
      console.log(this.notice, 'this.notice')
      console.log(this.noticeForm, 'this.noticeForm')
      this.$refs.noticeForm.validate(async (valid) => {
        if (valid) {
          this.noticeForm.status = type === '发布' ? 1 : 0
          console.log(this.noticeForm, 'this.notice')
          this.$http
            .post('/api/system/web/announcement/submit', this.noticeForm)
            .then((res) => {
              console.log(res, '弹出框提交')
              if (res.data.success) {
                if (type) {
                  this.dialogVisible = false
                  this.$message.success(res.data.msg)
                }
                if (type !== '发布') {
                  this.noticeForm.id = res.data.data
                }
                this.onRefer()
              } else {
                this.$message.error(res.data.msg)
              }
            })
        }
      })
      this.loading = false
    },

    searchOnRefer() {
      const regExp = /^[\u4e00-\u9fa5_a-zA-Z0-9\n]+$/
      if (!regExp.test(this.searchForm.title)) {
        this.$message.error('标题仅可输入字母，数值，中文字符!')
        return
      }
      this.searchForm.current = 1
      this.onRefer()
    },
    // 重置
    reset() {
      console.log('重置')
      this.searchForm = {
        current: 1,
        size: 10,
      }
      this.onRefer()
    },

    // 获取公告
    onRefer() {
      const data = JSON.parse(JSON.stringify(this.searchForm))
      data.title = this.searchForm.title ? `*${this.searchForm.title}*` : ''
      console.log(this.searchForm, 'this.searchForm')
      if (this.searchForm.updateDate) {
        data.updateDate = new Date(
          new Date(Date.parse(this.searchForm.updateDate)).getTime() +
            8 * 60 * 60 * 1000
        )
      }
      this.$http.post('/api/system/web/announcement/list', data).then((res) => {
        console.log('列表', res.data)
        if (res.data.success) {
          const {
            data: { data },
          } = res
          this.tableData = data.records ?? []
          this.noticeTotal = data.total ?? 0
          this.$nextTick(() => {
            if (this.noticeTotal > 0 && this.$refs.multipleTable) {
              this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    // 查看公告
    check(item) {
      this.tit = item.title
      this.dialogVisible = true
      this.noticeFormDisabled = true
      this.noticeForm = JSON.parse(JSON.stringify(item))
      this.isChange = false
      if (this.timer !== null) {
        clearTimeout(this.timer)
      }
    },
    // 修改公告
    edit(item) {
      this.tit = '编辑公告'
      this.dialogVisible = true
      this.noticeFormDisabled = false
      this.noticeForm = JSON.parse(JSON.stringify(item))
      if (this.timer !== null) {
        clearTimeout(this.timer)
      }
    },

    // 查看电梯明细
    del(item) {
      this.$confirm('此操作将永久删除选中的公告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.tit = '删除公告'
        const data = {
          ids: item.id,
        }
        this.$http
          .delete(`/api/system/web/announcement/remove?ids=${item.id}`)
          .then((res) => {
            console.log('res123', res)
            if (res.data.success) {
              if (this.tableData.length === 1) {
                this.searchForm.current -= 1
              }
              this.onRefer()
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
          })
      })
    },

    addDj() {
      this.tit = '新增公告'
      this.dialogVisible = true
      this.noticeFormDisabled = false
      this.noticeForm = {
        status: 0,
      }
    },
    //获取焦点事件
    focus(event) {
      event.enable(false) //设置富文本编辑器不可编辑
    },
    infoElevatorDialogClose() {
      this.noticeForm = {}
      this.dialogVisible = false
    },
  },
  watch: {
    noticeForm: {
      handler(newRoom, oldRoom) {
        if (oldRoom.title && newRoom.title) {
          this.isChange = true
        }
      },
      deep: true,
    },
  },
  created() {
    this.getNotice('公告类型', [
      'NOTICE_ADMIN',
      'NOTICE_TYPE_COMMON',
      'NOTICE_TYPE_SUP',
    ])
    this.onRefer()
  },
  updated() {
    if (
      this.dialogVisible &&
      this.noticeForm.status === 0 &&
      this.tit !== this.noticeForm.title
    ) {
      if (
        this.noticeForm.title &&
        this.noticeForm.type &&
        this.noticeForm.detail &&
        this.isChange
      ) {
        if (this.timer !== null) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          console.log('防抖执行')
          this.isChange = false
          this.dioSub()
          clearTimeout(this.timer)
        }, 10000)
      }
    } else {
      this.isChange = false
    }
  },
}
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchBtn {
  margin-left: 15px;
}
.wid90 {
  width: 90%;
}
.quillClass {
  width: 96%;
  background: #1282a5;
  border-radius: 10px;
  color: #fff;
  /deep/.ql-toolbar {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  /deep/.ql-container {
    background: #1282a5;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  /deep/.ql-blank {
    height: 160px !important;
    color: #fff !important;
  }
  /deep/.ql-blank::placeholder {
    color: #fff !important;
  }
  /deep/.ql-editor {
    overflow-y: scroll;
    max-height: 22vh;
    min-height: 19vh;
  }
  /deep/.ql-snow .ql-stroke {
    stroke: #fff;
  }
  /deep/.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #fff;
  }
  /deep/.ql-snow .ql-picker-label {
    color: #fff !important;
  }
  /deep/.ql-snow .ql-picker-label::before {
    top: -8px;
    position: relative;
  }
}
.noticeFormInfo {
  color: #fff;
  font-size: 16px;
  .noticeFormDetail {
    margin-top: 20px;
    padding-top: 5px;
    .gundong {
      height: 280px; /* 父盒子有限高度 */
      overflow: auto;
      scrollbar-width: none; /* Firefox隐藏滚动条 */
      -ms-overflow-style: none; /* Internet Explorer 10+隐藏滚动条 */
      /deep/.ql-toolbar {
        display: none;
      }
      /deep/.quillClass {
        background: unset;
        .ql-container {
          background: unset;
          border-radius: 15px;
          overflow: hidden;
          border: none;
          padding: 15px 0;
          .ql-snow {
            border: none;
          }
          .ql-editor {
            max-height: 33vh !important;
            min-height: 30vh !important;
          }
        }
      }
    }
    .gundong::-webkit-scrollbar {
      width: 0; /* Safari,Chrome 隐藏滚动条 */
      height: 0; /* Safari,Chrome 隐藏滚动条 */
      display: none; /* 移动端、pad 上Safari，Chrome，隐藏滚动条 */
    }
  }
}
/deep/.el-dialog__header span {
  color: #00f6ff !important;
  width: 80%;
  text-align: center;
}
</style>
