<template>
  <div>
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
    >
      <el-tab-pane
        label="设备登录统计"
        name="first"
      >
        <div class="page-top">
          <el-form
            :inline="true"
            :model="loginCensus"
            class="demo-form-inline"
          >
            <el-form-item>
              <span slot="label">
                <span class="lab-span">电梯物联网编号</span>
              </span>
              <el-input
                v-model="loginCensus.deviceCode"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <span slot="label">
                <span class="lab-span">电梯名称</span>
              </span>
              <el-input
                v-model="loginCensus.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <span slot="label">
                <span class="lab-span">上下线时间</span>
              </span>
              <el-date-picker
                v-model="loginCensus.dateTime"
                type="daterange"
                clearable
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <span slot="label">
                <span class="lab-span">物联网设备软件版本</span>
              </span>
              <el-select
                v-model="loginCensus.caVer"
                placeholder="请输入"
                class="einp"
                clearable
              >
                <el-option
                  v-for="item in softwareData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <span slot="label">
                <span class="lab-span">APK版本</span>
              </span>
              <el-select
                v-model="loginCensus.appVer"
                placeholder="请输入"
                class="einp"
                clearable
              >
                <el-option
                  v-for="item in APKData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="onRefer(true)"
              >查询</el-button>
              <el-button
                type="warning"
                @click="reset(true)"
              >重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="total != 0">
          <el-table
            ref="loginCensusTable"
            :data="loginCensusData"
            :height="$store.state.tabHeight - 100"
            style="width: 100%;margin-bottom: 20px;"
          >
            <el-table-column
              type="index"
              align='center'
              label="序号"
            />
            <el-table-column
              align='center'
              prop="name"
              label="电梯名称"
              width="200px"
            />
            <el-table-column
              align='center'
              prop="deviceCode"
              label="电梯物联网编号"
              width="200px"
            />
            <el-table-column
              align='center'
              prop="login"
              label="上线次数"
            />
            <el-table-column
              align='center'
              prop="logout"
              label="下线次数"
            />
            <el-table-column
              align='center'
              prop="mergerName"
              label="所属区域"
              width="200px"
            />
            <el-table-column
              align='center'
              prop="eleAddress"
              label="安装位置"
              width="200px"
            />
            <el-table-column
              fixed="right"
              align='center'
              prop="address"
              label="操作"
              width="120px"
            >
              <template slot-scope="scope">
                <el-button
                  v-focus
                  @click="showDetails(scope.row)"
                  type="primary"
                  size="small"
                >查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :page.sync="loginCensus.current"
            :limit.sync="loginCensus.size"
            @pagination="dictionarPage"
          />
        </div>
        <div
          class="nullDate"
          v-else
        >
          <img
            src="@/assets/nullDate.png"
            style="width: 260px;"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="设备登录列表"
        name="second"
      >
        <div class="page-top">
          <el-form
            :inline="true"
            :model="loginList"
            class="demo-form-inline"
          >
            <el-form-item>
              <span slot="label">
                <span class="lab-span">电梯物联网编号</span>
              </span>
              <el-input
                v-model="loginList.deviceCode"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <span slot="label">
                <span class="lab-span">电梯名称</span>
              </span>
              <el-input
                v-model="loginList.name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <span slot="label">
                <span class="lab-span">电梯注册代码</span>
              </span>
              <el-input
                v-model="loginList.code"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <span slot="label">
                <span class="lab-span">上下线时间</span>
              </span>
              <el-date-picker
                v-model="loginList.dateTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <span slot="label">
                <span class="lab-span">类型</span>
              </span>
              <el-select
                v-model="loginList.type"
                placeholder="请输入"
                class="einp"
                clearable
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="onReferList(true)"
              >查询</el-button>
              <el-button
                type="warning"
                @click="reset(false)"
              >重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="listTotal != 0">
          <el-table
            ref="loginListTable"
            :data="loginListData"
			      :height="$store.state.tabHeight - 100"
            style="width: 100%;margin-bottom: 20px;"
          >
            <el-table-column
              type="index"
              align='center'
              label="序号"
            />
            <el-table-column
              align='center'
              prop="name"
              label="电梯名称"
              width="200px"
            />
            <el-table-column
              align='center'
              prop="deviceCode"
              label="电梯物联网编号"
              width="200px"
            />
            <el-table-column
              align='center'
              prop="code"
              label="电梯注册代码"
              width="100px"
            />
            <el-table-column
              align='center'
              prop="mergerName"
              label="所属区域"
              width="200px"
            />
            <el-table-column
              align='center'
              prop="eleAddress"
              label="安装位置"
              width="200px"
            />
            <el-table-column
              align='center'
              prop="createUserName"
              label="创建者账号"
            />
            <el-table-column
              align='center'
              prop="type"
              label="类型"
            />
            <el-table-column
              align='center'
              prop="createTime"
              label="创建时间"
              width="200px"
            />
          </el-table>
          <Pagination
            :total="listTotal"
            :page.sync="loginList.current"
            :limit.sync="loginList.size"
            @pagination="dictionarPage"
          />
        </div>
        <div
          class="nullDate"
          v-else
        >
          <img
            src="@/assets/nullDate.png"
            style="width: 260px;"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <Detail
      ref="Detail"
      v-if="detailVisible"
      :dataInfo="dataInfo"
      @closeVisible="closeVisible"
    />
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import Detail from './components/detail'
import { formatDate } from "@/util";
let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
let current_time1 = new Date(current_time).getTime();
// let starTime = new Date(current_time1 - 30 * 24 * 60 * 60 * 1000);

let monthFrist = formatDate(new Date(new Date().setDate(1)), "yyyy-MM-dd") + " 00:00:00";
let starTime = new Date(new Date(monthFrist).getTime());
let endTime = new Date(current_time1 + 24 * 60 * 60 * 1000 - 1000);
export default {
  components: { Pagination, Detail },
  data() {
    return {
      // tab分页
      activeName: 'first',
      // 统计列表数据
      loginCensus: {
        current: 1,
        size: 10,
        dateTime: [starTime, endTime]
      },
      loginCensusData: [],
      total: 0,

      // 统计登陆数据
      loginList: {
        current: 1,
        size: 10,
        dateTime: [starTime, endTime]
      },
      loginListData: [],
      listTotal: 0,
      // 软件版本
      softwareData: [],
      // APK版本
      APKData: [],
      typeList: [
        { value: 0, label: '全部' },
        { value: 1, label: '上线' },
        { value: 2, label: '下线' },
      ],
      // 查看详情
      detailVisible: false,
    }
  },
  methods: {
    // 获取字典数据
    getDictionar(code) {
      // code = code.join()
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          console.log('字典', res)
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res
            this.softwareData = data.SOFTWARE_VERSION
            this.APKData = data.APK_VERSION
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    handleClick(tab, event) {
      if (this.activeName === 'first') {
        this.onRefer()
      } else {
        this.onReferList()
      }
    },
    dictionarPage(data) {
      if (this.activeName === 'first') {
        this.loginCensus.current = data.page
        this.loginCensus.size = data.limit
        this.onRefer()
      } else {
        this.loginList.current = data.page
        this.loginList.size = data.limit
        this.onReferList()
      }
    },
    // 统计列表
    onRefer(ishm) {
      
      if (ishm) {
        this.loginCensus.current = 1
      }
      console.log(this.loginCensus.dateTime, 'this.loginCensus')
      if (this.loginCensus.dateTime) {
        if (this.loginCensus.dateTime[0].getTime() < new Date('2022-12-01').getTime()) {
						this.$message.error('查询时间不得早于2022年12月')
						return
					}
					if (this.loginCensus.dateTime[1].getTime() > new Date(new Date().toLocaleDateString()).getTime() + 24 * 3600 * 1000 - 1000) {
						this.$message.error('查询时间不得晚于当天')
						return
					}
        this.loginCensus.startTime = this.loginCensus.dateTime[0].getTime()
        this.loginCensus.endTime = this.loginCensus.dateTime[1].getTime() + 24 * 3600 * 1000 - 1000
      } else {
        this.loginCensus.dateTime = [starTime, endTime]
        this.loginCensus.startTime = starTime.getTime()
        this.loginCensus.endTime = endTime.getTime()
      }
      this.$http
        .post(
          `/api/ele/web/loginLog/getLoginLogCountSubPage?current=${this.loginCensus.current}&size=${this.loginCensus.size}`,
          this.loginCensus
        )
        .then((res) => {
          console.log('列表', res.data)
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.loginCensusData = data.records
            this.total = data.total
            this.$nextTick(() => {
              if (this.total > 0 && this.$refs.loginCensusTable) {
                this.$refs.loginCensusTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    // 列表
    onReferList(ishm) {
      if (ishm) {
        this.loginList.current = 1
      }
      console.log(this.loginCensus, 'this.loginCensus')
      if (this.loginList.dateTime) {
        if (this.loginList.dateTime[0].getTime() < new Date('2022-12-01').getTime()) {
						this.$message.error('查询时间不得早于2022年12月')
						return
					}
					if (this.loginList.dateTime[1].getTime() > new Date(new Date().toLocaleDateString()).getTime() + 24 * 3600 * 1000 - 1000) {
						this.$message.error('查询时间不得晚于当天')
						return
					}
        this.loginList.startTime = this.loginList.dateTime[0].getTime()
        this.loginList.endTime = this.loginList.dateTime[1].getTime() + 24 * 3600 * 1000 - 1000
      } else {
        this.loginList.dateTime = [starTime, endTime]
        this.loginList.startTime = starTime.getTime()
        this.loginList.endTime = endTime.getTime()
      }
      this.$http
        .post(
          '/api/ele/web/loginLog/getLoginLogSubPage',
          this.loginList
        )
        .then((res) => {
          console.log('列表', res.data)
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.loginListData = data.records
            this.listTotal = data.total
            this.$nextTick(() => {
              if (this.listTotal > 0 && this.$refs.loginListTable) {
                this.$refs.loginListTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    reset(type) {
      console.log('重置')
      if (type) {
        this.loginCensus = {
          current: 1,
          size: 10,
        }
        this.onRefer()
      } else {
        this.loginList = {
          current: 1,
          size: 10,
        }
        this.onReferList()
      }
    },
    showDetails(data) {
      this.detailVisible = true
      this.dataInfo = data
    },
    closeVisible() {
      this.detailVisible = false
    },
  },
  created() {
    this.getDictionar(['APK_VERSION', 'SOFTWARE_VERSION'])
    this.onRefer()
  },
}
</script>
<style  lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-tabs__item,
.el-radio,
.el-checkbox {
  color: #fff;
}

/deep/.el-tabs__item {
  width: 120px;
  text-align: center;
  padding-left: 0;
}
</style>
