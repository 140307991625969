<template>
  <el-dialog
    title="已关联电梯"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="1400px"
    top="8vh"
    center
    @close="closeDialog"
  >
    <div class="searchBox scrollElement">
      <div class="dataList">
        <el-table
          :data="personList"
          ref="unitTable"
          border
          stripe
          fit
          style="width: 100%; margin-top: 20px"
          max-height="500px"
        >
          <el-table-column type="index" align="center" label="序号" width="50px" />
          <el-table-column align='center' prop="name" label="电梯名称" width="150px"/>
          <el-table-column align='center' prop="code" label="电梯注册代码" width="150px"/>
          <el-table-column align='center' prop="rescueCode" label="救援识别码" width="150px"/>
          <el-table-column align='center' prop="plotName" label="所属小区" width="150px"/>
          <el-table-column align='center' label="使用场所" width="150px">
            <template slot-scope="scope">
              {{
                scope.row.elePlaceType ? 
                  (elePlaceList.find(item => item.value == scope.row.elePlaceType) ?
                    elePlaceList.find(item => item.value == scope.row.elePlaceType).label : '-')
                : '-'
              }}
            </template>
          </el-table-column>
          <el-table-column align='center' label="楼层学习">
            <template slot-scope="scope">
              {{
                scope.row.isInit ? '已完成' : '未完成'
              }}
            </template>
          </el-table-column>
          <el-table-column align='center' prop="eleAddress" label="电梯安装位置" width="200px"/>
        </el-table>
        <Pagination
          :total="personListTotal"
          :page.sync="unitQuery.current"
          :limit.sync="unitQuery.size"
          @pagination="unitPage"
        />
      </div>
    </div>
    <span slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="indo" @click="closeDialog">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Pagination from "../../../../components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      visible: false,
      unitData: [],
      type: "",
      unitTotal: 0,
      unitQuery: {
        current: 1,
        size: 10,
      },
      addBrandCallback: null, // 弹窗回调

    };
  },
  props: {
    personList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    elePlaceList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    personListTotal: {
      type: Number,
      default: 0,
    },
  },

  mounted() {},

  methods: {
    // 父字典id，父字典数据，回调
    init(callback) {
      this.addBrandCallback = callback;
      this.visible = true;
      this.loading = false;
    },

    // 单位分页
    unitPage(data) {
      this.unitQuery.current = data.page;
      this.unitQuery.size = data.limit;
      this.$emit('changePage', this.unitQuery)
    },

    closeDialog() {
      this.visible = false;
      this.$emit('colseEleTable')
    },
  },
};
</script>
<style lang="scss" scoped>
.searchBox {
  /deep/.el-form-item__label {
    color: #00f6ff !important;
    font-size: 14px;
  }
  .searchBtn {
    margin-left: 15px;
  }
}
/deep/.el-table__header-wrapper .el-checkbox {
  display: none;
  position: relative;
}
</style>
