<template>
  <div>
    <div>
      <template v-if="total != 0">
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px"
          max-height="480"
        >
          <el-table-column type="index" label="序号" />
          <el-table-column align="center" prop="eleName" label="电梯名称" />
          <el-table-column
            align="center"
            prop="startTime"
            label="计划时间"
            width="100px"
          />
          <el-table-column
            align="center"
            prop="signTime"
            label="签到时间"
            width="100px"
          />
          <el-table-column
            align="center"
            prop="finishTime"
            label="完成时间"
            width="100px"
          />
          <el-table-column align="center" prop="plotName" label="所属小区" />
          <el-table-column
            align="center"
            prop="maintainTypeName"
            label="计划类型"
          />
          <el-table-column
            align="center"
            prop="eleCode"
            label="电梯注册代码"
            width="150px"
          />
          <el-table-column
            align="center"
            prop="eleAddress"
            label="电梯安装位置"
            width="180px"
          />
          <el-table-column
            align="center"
            prop="propertyUnitName"
            label="物业单位"
            width="150px"
          />
          <el-table-column
            align="center"
            prop="maintainUnitName"
            label="维保单位"
            width="150px"
          />
          <el-table-column
            align="center"
            prop="maintainUserNames"
            label="维保人员"
            width="180px"
          />
          <el-table-column align="center" label="维保视频">
            <template slot-scope="scope">
              {{ scope.row.isMaintainVideo == 0 ? "没有" : "有" }}
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            prop="address"
            label="操作"
            width="120px"
          >
            <template slot-scope="scope">
              <el-button @click="detail(scope.row)" type="warning" size="small"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :layout="'total, sizes, prev, pager, next'"
          :page.sync="searchForm.current"
          :limit.sync="searchForm.size"
          @pagination="videoRulePage"
        />
      </template>

      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </div>
    <DetailInfo
      v-if="detailInfoVisible"
      ref="detailInfo"
      :info="info"
      :id="typeId"
      :videoList="videoList"
      @detailClose="detailClose"
    />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import DetailInfo from '../../tplb/components/detailInfo';

export default {
  components: {
    Pagination,
    DetailInfo
  },
  props: ["useId"],
  data() {
    return {
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },
      tableData: [],
      // 分页参数
      total: 0,
      detailInfoVisible: false,
      info: {},
      videoList: [],
      videoPage: {
        current: 1,
        size: 999,
      },
      typeId: '',
    };
  },
  filters: {},
  methods: {
    // 分页
    personnelPage(data) {
      this.searchForm.current = data.page;
      this.searchForm.size = data.limit;
      this.onRefer();
    },

    // 搜索
    searchOnRefer() {
      this.searchForm.current = 1;
      this.onRefer();
    },
    // 重置
    reset() {
      console.log("重置");
      this.searchForm = {
        current: 1,
        size: 10,
      };
      this.onRefer();
    },
    // 图片
    videoRulePage(data) {
      this.searchForm.current = data.page;
      this.searchForm.size = data.limit;
      this.onRefer();
    },

    // 获取人员方法
    onRefer() {
      const data = JSON.parse(JSON.stringify(this.searchForm));
      data.signUserId = this.useId;
      this.$http
        .post("/api/ele/web/maintainTask/getCapMaintainList", data)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res;
            this.tableData = data.records ?? [];
            this.total = data.total ?? 0;
            this.$nextTick(() => {
								if (this.total > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },

     // 获取视频列表
    getVideo(type) {
      
      this.info = {
        taskId: this.typeId
      }
      const data = JSON.parse(JSON.stringify(this.videoPage))
      data.taskId = this.typeId
      this.loading = true
      this.$http
        .post('/api/cap/web/video/getCapVideo', data)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res
            console.log(data,'data');
            this.videoList = data.records ?? []
            console.log(this.videoList,'this.videoList');
            this.$nextTick(() => {
              this.$refs.detailInfo.init("spxq");
            });
          } else {
            this.videoList = []
            this.$message.error(res.data.msg)
          }
        })
    },

    detail(data) {
      this.detailInfoVisible = true;
      this.typeId = data.id
      this.getVideo(data)
    },
    // 详情弹框关闭
    detailClose() {
      this.detailInfoVisible = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
