<template>
  <div>
    <div class="page-top">
      <el-form
        :inline="true"
        ref="searchForm"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item>
          <span slot="label">
            <span class="lab-span">生效单位名称</span>
          </span>
          <el-input
            v-model="searchForm.orgName"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="searchBtn"
            @click="searchOnRefer"
          >查询</el-button>
          <el-button
            type="warning"
            @click="reset"
          >重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button
        v-focus
        type="success"
        size="small"
        @click="addDj"
      >新增</el-button>
      <template v-if="tableData != 0">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="$store.state.tabHeight"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50px"
          />
          <el-table-column
            align="center"
            prop="orgName"
            label="生效单位名称"
            width="250px"
          />
          <el-table-column
            align="center"
            prop="verificationModeStr"
            label="验证方式"
            width="150px"
          />
          <el-table-column
            align="center"
            prop="signInStr"
            label="签到"
          />
          <el-table-column
            align="center"
            prop="signOutStr"
            label="签退"
          />
          <el-table-column
            align="center"
            prop="signatureStr"
            label="维保签字确认"
          />
          <el-table-column
            align="center"
            prop="maintainRemindStr"
            label="维保提醒"
          />
          <el-table-column
            align="center"
            prop="maintainRemindModeStr"
            label="提醒方式"
            width="200px"
          />
          <el-table-column
            align="center"
            prop="createUserName"
            label="创建人"
            width="150px"
          />
          <el-table-column
            fixed="right"
            align="center"
            prop="address"
            label="操作"
            width="300px"
          >
            <template slot-scope="scope">
              <el-button
                v-focus
                @click="edit(scope.row)"
                type="warning"
                size="small"
              >编辑
              </el-button>
              <el-button
                v-focus
                @click="del(scope.row)"
                type="danger"
                size="small"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="maintenRuleTotal"
          :page.sync="searchForm.current"
          :limit.sync="searchForm.size"
          @pagination="maintenRulePage"
        />
      </template>

      <div
        class="nullDate"
        v-else
      >
        <img
          src="@/assets/nullDate.png"
          style="width: 260px"
        />
      </div>
    </div>

    <el-dialog
      :title="tit"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="950px"
      top="20vh"
    >
      <div
        style="height: 40vh; overflow-y: scroll; padding-right: 20px"
        class="scrollElement"
      >
        <el-form
          label-position="right"
          label-width="120px"
          ref="maintenRuleForm"
          :model="maintenRuleForm"
          :rules="formRules"
        >
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item
                label="生效单位:"
                prop="unitName"
              >
                <el-input
                  :placeholder="maintenRuleForm.unitName || '暂无内容'"
                  v-model="maintenRuleForm.unitName"
                  class="input-with-select wid90"
                  disabled
                >
                  <el-button
                    :disabled="maintenRuleFormDisabled"
                    slot="append"
                    type="primary"
                    @click="selectUnit()"
                  >选择生效单位</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="签到:"
                prop="signIn"
              >
                <el-select
                  v-model="maintenRuleForm.signIn"
                  placeholder="请输入"
                  style="width: 100%"
                >
                  <el-option
                    label="开启"
                    :value="1"
                  />
                  <el-option
                    label="关闭"
                    :value="0"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item
                label="签退:"
                prop="signOut"
              >
                <el-select
                  v-model="maintenRuleForm.signOut"
                  placeholder="请输入"
                  style="width: 100%"
                >
                  <el-option
                    label="开启"
                    :value="1"
                  />
                  <el-option
                    label="关闭"
                    :value="0"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="维保签字确认:"
                prop="signature"
              >
                <el-select
                  v-model="maintenRuleForm.signature"
                  placeholder="请输入"
                  style="width: 100%"
                >
                  <el-option
                    label="开启"
                    :value="1"
                  />
                  <el-option
                    label="关闭"
                    :value="0"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item
                label="验证方式:"
                prop="verificationMode"
              >
                <el-select
                  multiple
                  v-model="maintenRuleForm.verificationMode"
                  placeholder="请输入"
                  style="width: 100%"
                >
                  <el-option
                    label="扫码"
                    :value="2"
                  />
                  <el-option
                    label="GPS定位"
                    :value="3"
                  />
                  <el-option
                    label="安全帽签到"
                    :value="4"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="超期后可维保:"
                prop="overdueOperation"
              >
                <el-select
                  v-model="maintenRuleForm.overdueOperation"
                  placeholder="请输入"
                  style="width: 100%"
                >
                  <el-option
                    label="是"
                    :value="1"
                  />
                  <el-option
                    label="否"
                    :value="0"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item
                label="维保提醒:"
                prop="maintainRemind"
              >
                <el-select
                  multiple
                  v-model="maintenRuleForm.maintainRemind"
                  placeholder="请输入"
                  style="width: 100%"
                >
                  <el-option
                    label="APP"
                    :value="1"
                  />
                  <el-option
                    label="短信"
                    :value="2"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="维保签到半径:"
                prop="radius"
              >
                <el-input
                  :placeholder="maintenRuleForm.radius || '请输入维保签到半径'"
                  v-model="maintenRuleForm.radius"
                  class="input-with-select wid90"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col
              :span="24"
            >
              <el-form-item
                label="维保提醒时间:"
              >
                 <el-table
                  :data="unitList"
                  style="width: 100%">
                  <el-table-column
                    align="center"
                    prop="label"
                    label="提示周期"
                    width="180">
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="时间"
                    width="350">
                    <template slot-scope="scope">
                      <el-time-select
                        disabled
                        v-model="scope.row.time"
                        :picker-options="{
                          start: '07:00',
                          step: '00:15',
                          end: '07:00',
                        }"
                        placeholder="选择时间"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="是否启用">
                    <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.switch"
                      :active-value="1"
                      :inactive-value="0"
                      @change="changeSwitch(scope.row, scope.$index)">
                    </el-switch>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        
               
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          type="primary"
          :loading="loading"
          @click="dioSub(maintenRuleFormDisabled ? 'edit' : 'add')"
        >发 布</el-button>
        <el-button
          size="small"
          @click="dialogVisible = false"
        >取 消</el-button>
      </span>
    </el-dialog>
    <SelectUnit
      v-if="selectOtherDialogVisible"
      ref="SelectUnit"
      @transferUnit="selectOtherResult"
    />
  </div>
</template>
<script>
import Pagination from '../../../components/Pagination'
import SelectUnit from './components/selectUnit'
var validateTime = (rule, value, callback) => {
  let operation = /^(0|[1-9][0-9]*)$/;
  inpYz(rule, value, callback, false, operation, "只能输入0或正整数");
};
export default {
  components: {
    Pagination,
    SelectUnit,
  },
  data() {
    var validateFirstParty = (rule, value, callback) => {
      inpFirstPartyName(rule, value, callback)
    }
    return {
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },
      unitList: [],
      unitInitList: [],
      tableData: [],
      // 分页参数
      maintenRuleTotal: 0,
      // dialog参数
      tit: '',
      dialogVisible: false,
      loading: false,
      maintenRuleForm: {
        unitName: null,
      },
      rulesMode: [], // 验证方式
      rulesRemind: [], // 维保提醒
      remindTime: [], // 维保提醒时间
      value: '',
      formRules: {
        unitName: [{ required: true, message: '请选择生效单位', trigger: 'blur' }],
        radius: [{ required: false, validator: validateTime, trigger: 'blur'}]
      },
      selectOtherDialogVisible: false, // 选择单位
      maintenRuleFormDisabled: false, // 查看禁点
      selectData: [],

      itemKey: 0
    }
  },
  filters: {},
  methods: {
    // 获取字典数据
    getDictionar(code) {
      // code = code.join()
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          console.log('字典', res)
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res
            this.unitList = data.MAINTEN_REMINDER_TIME
            this.unitList.forEach((item) => {
              item.time = '07:00'
              item.switch = 0
            })
            
            this.unitInitList = JSON.parse(JSON.stringify(this.unitList))
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    // 分页
    maintenRulePage(data) {
      this.searchForm.current = data.page
      this.searchForm.size = data.limit
      this.onRefer()
    },


    changeSwitch(data, index) {
      const tableDataArr = JSON.parse(JSON.stringify(this.unitList))
      this.$set(this, 'unitList', tableDataArr)
    },

    // 选择单位
    selectUnit() {
      console.log('选择单位')
      this.selectOtherDialogVisible = true
      console.log(this.maintenRuleForm,'this.maintenRuleForm.eleIds');
      this.$nextTick(() => {
        this.$refs.SelectUnit.init(
          this.maintenRuleForm.orgIds,
          this.selectData,
          (refresh) => {
            if (refresh) {
            }
          }
        )
      })
    },
    // 选择单位返回
    selectOtherResult(data) {
      console.log('选择单位返回', data)
      const nameArr = []
      const idArr = []
      data.map((item) => {
        nameArr.push(item.name)
        idArr.push(item.id)
      })
      this.selectData = data
      this.selectOtherDialogVisible = false
      this.$set(this.maintenRuleForm, 'unitName', nameArr.join())
      // this.maintenRuleForm.unitName = nameArr.join()
      this.maintenRuleForm.orgIds = idArr
    },
    // 弹出框提交
    dioSub(type) {
      // this.loading = true
      let url = ''
      if (type === 'add') {
        url = '/api/ele/web/maintainRules/add'
      } else {
        url = '/api/ele/web/maintainRules/edit'
      }
      let remind = []
      this.unitList.map((item) => {
        this.remindTime.map((item1) => {
          if (item.value === item1) {
            remind.push({
              value: item.value,
              time: item.time,
              switch: item.switch
            })
          }
        })
      })
      
      this.$refs.maintenRuleForm.validate(async (valid) => {
        if (valid) {
          console.log(this.maintenRuleForm, 'this.maintenRule')
          const data = JSON.parse(JSON.stringify(this.maintenRuleForm))
          data.verificationMode = JSON.stringify(this.maintenRuleForm.verificationMode)
          data.maintainRemind = JSON.stringify(this.maintenRuleForm.maintainRemind)
          data.remindRules = JSON.stringify(this.unitList)
          console.log(data, 'data');
          console.log(this.maintenRuleForm, 'this.maintenRule')
          this.$http.post(url, data).then((res) => {
            console.log(res, '弹出框提交')
            if (res.data.success) {
              this.searchForm = {
                current: 1,
                size: 10,
              }
              this.onRefer()
              this.dialogVisible = false
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
      this.loading = false
    },
    // 搜索
    searchOnRefer() {
      const regExp = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g
      if (regExp.test(this.searchForm.code)) {
        this.$message.error('标题仅可输入字母，数值，特殊符号!')
        return
      }
      this.searchForm.current = 1
      this.onRefer()
    },
    // 重置
    reset() {
      console.log('重置')
      this.searchForm = {
        current: 1,
        size: 10,
      }
      this.onRefer()
    },
    // 获取维保规则方法
    onRefer() {
      this.$http
        .post('/api/ele/web/maintainRules/getList', this.searchForm)
        .then((res) => {
          console.log('列表', res.data)
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.tableData = data.records ?? []
            this.maintenRuleTotal = data.total ?? 0
            this.$nextTick(() => {
              if (this.maintenRuleTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    // 查看维保规则
    edit(item) {
      console.log(item, '编辑维保规则')
      this.tit = '编辑维保规则'
      this.dialogVisible = true
      this.maintenRuleFormDisabled = true
      const arr = JSON.parse(item.remindRules)
      if (arr && arr.length == 4) {
        this.unitList = JSON.parse(item.remindRules)
        this.$forceUpdate()
        // this.$set(this, 'unitList', JSON.parse(item.remindRules))
      } else {
        arr.map((item) => {
          this.unitList.find(item1 => item1.value == item.value).switch = 1
        })
      }
      console.log(arr, 'arr');
      console.log(this.unitList, 'this.unitList');
      // this.$set(this, 'unitList', JSON.parse(item.remindRules))
      this.maintenRuleForm = {
        unitName: item.orgName,
        orgIds: item.orgIds,
        id: item.id,
        signIn: item.signIn,
        signOut: item.signOut,
        signature: item.signature,
        veriface: item.veriface,
        overdueOperation: item.overdueOperation,
        radius: item.radius,
        
        maintainRemind: JSON.parse(item.maintainRemind), // 维保提醒
        remindRules: JSON.parse(item.remindRules), // 维保提醒时间
        verificationMode: JSON.parse(item.verificationMode) //  验证方式
      }
    },
    // 删除规则
    del(item) {
      this.$confirm('此操作将永久删除选中的规则, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const data = {
          id: item.id,
        }
        this.$http
          .post('/api/ele/web/maintainRules/delete', data)
          .then((res) => {
            console.log('列表', res.data)
            if (res.data.success) {
              if (this.tableData.length === 1) {
                this.searchForm.current -= 1
              }
              this.onRefer()
            } else {
              this.$message.error(res.data.msg)
            }
          })
      })
    },
    addDj() {
      this.tit = '添加维保规则'
      this.dialogVisible = true
      this.maintenRuleFormDisabled = false
      this.maintenRuleForm = {
        signIn: 1,
        signOut: 1,
        signature: 1,
        veriface: 0,
        radius: '', // 签到半径
        overdueOperation: 1, // 超期后可维保
        maintainRemind: [1], // 维保提醒
        remindRules: [], // 维保提醒时间
        verificationMode: [3] //  验证方式
        
      }
    },
  },
  created() {
    this.getDictionar(['MAINTEN_REMINDER_TIME'])
  },
  mounted() {
    this.$nextTick(() => {
      this.onRefer()
    })
  },
}
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchBtn {
  margin-left: 15px;
}
/deep/.el-checkbox {
  color: #fff;
}
/deep/.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #c7c7c7;
}
.checkbox {
  width: 65%;
  /deep/.el-checkbox__label {
    width: 100%;
  }
  .fg {
    width: 15%;
    display: inline-block;
    text-align: center;
    margin-right: 5%;
  }
}
</style>
