<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">单位名称</span>
					</span>
					<el-input v-model="formInline.name" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">联系人</span>
					</span>
					<el-input v-model="formInline.contact" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">所属区域</span>
					</span>
					<el-cascader :props="props" :options="$mapCode" v-model="formInline.areaCodeArr" style="width: 100%"
						collapse-tags clearable>
					</el-cascader>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onRefer(true)">查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
			<div style="display: flex">
				<el-button v-focus type="warning" @click="dowMode(4)" size="small">下载模版</el-button>
				<el-button v-focus type="danger" @click="delAll" size="small">批量删除</el-button>
				<el-button v-focus type="success" @click="addDj" size="small">新增</el-button>
				<form v-focus name="form1" style="width: 56px; margin: 0 10px">
					<input type="file" id="docxFile" @change="readFile($event)"
						style="position: absolute; clip: rect(0 0 0 0)" />
					<el-button type="primary" @click="toLead" size="small">导入</el-button>
				</form>
			</div>
		</div>
		<div v-if="dictionarTotal != 0">
			<el-table ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange"
				style="width: 100%; margin-bottom: 20px" :height="$store.state.tabHeight">
				<el-table-column type="selection" width="55px"> </el-table-column>
				<el-table-column type="index" label="序号" width="50px" />
				<el-table-column align="center" prop="name" label="单位名称" />
				<el-table-column align="center" prop="address" label="单位地址" />
				<el-table-column align="center" prop="certificateCode" label="公司代码" />
				<el-table-column align="center" prop="contact" label="联系人" />
				<el-table-column align="center" prop="phone" label="联系方式" />
				<el-table-column align="center" prop="areaPidName" label="所属区域" />
				<el-table-column align="center" prop="createUserName" label="创建人">
				</el-table-column>
				<el-table-column fixed="right" align="center" label="操作" width="350px">
					<template slot-scope="scope">
						<el-button v-focus @click="addPerson(scope.row)" type="primary" size="small">添加人员</el-button>
						<el-button v-focus @click="selRela(scope.row)" type="primary" size="small">相关人员</el-button>
						<el-button v-focus @click="upd(scope.row)" type="primary" size="small">编辑</el-button>
						<el-button v-focus @click="del([scope.row.id])" type="danger" size="small">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
				@pagination="dictionarPage" />
		</div>
		<div class="nullDate" v-else>
			<img src="@/assets/nullDate.png" style="width: 260px" />
		</div>
		<el-dialog v-if="dialogVisible" :close-on-click-modal="false" :title="tit" :visible.sync="dialogVisible"
			width="950px" top="20vh">
			<div style="height: 30vh;overflow-y: scroll;" class="scrollElement">
				<el-form :rules="rules" ref="ruleForm" label-position="right" label-width="100px" :model="fign">
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="单位名称:" prop="name">
								<el-input v-model="fign.name" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="公司代码:" prop="certificateCode">
								<el-input v-model="fign.certificateCode" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="单位地址:" prop="address">
								<el-input v-model="fign.address" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="所属区域:" prop="areaCodeArr">
								<el-cascader :props="props" :options="$mapCode" v-model="fign.areaCodeArr" class="einp"
									collapse-tags clearable>
								</el-cascader>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin: 10px 0">
						<el-col :span="12">
							<el-form-item label="单位负责人:" prop="contact">
								<el-input v-model="fign.contact" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="联系方式:" prop="phone">
								<el-input v-model="fign.phone" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" type="primary" @click="dioSub">确 定</el-button>
				<el-button size="small" @click="dialogVisible = false">取 消</el-button>
			</span>
		</el-dialog>

		<!-- 添加人员 -->
		<el-dialog
			v-if="PersonDialog"
			title="添加人员"
			:visible.sync="PersonDialog"
			:close-on-click-modal="false"
			width="950px"
			top="20vh"
			>
			<PersonAction
				tit="添加人员"
				:useType="useType"
				:personnelForm="personnelForm"
				@changeUseType="changeUseType"
				@subForm="subForm"
				@colseForm="colseForm"
			/>
			<span slot="footer" class="dialog-footer"></span>
		</el-dialog>
		<!-- 相关人员 -->
		<related v-if="xgryId != null" @close="xgClose" :dialogxgry="xgryId != null" :id="xgryId"></related>
		<!-- 错误信息列表 -->
		<errorDio v-if="errorList != null" :errorLists="errorList" @dioClose="dioCloseB"></errorDio>
	</div>
</template>
<script>
	var validateName = (rule, value, callback) => {
		let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9()（）]{3,30}$/
		// true：必填  false：非必填
		inpYz(
			rule,
			value,
			callback,
			true,
			operation,
			'可输入中文，字母，数值，长度为3-30'
		)
	}
	var validatecertificateCode = (rule, value, callback) => {
		let operation = /^[a-zA-Z0-9]{3,14}$/
		inpYz(rule, value, callback, false, operation, '可输入字母，数值，长度为3-14')
	}
	var validateaddress = (rule, value, callback) => {
		let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,25}$/
		inpYz(
			rule,
			value,
			callback,
			true,
			operation,
			'可输入中文，字母，数值，长度为2-25'
		)
	}
	var validatecontact = (rule, value, callback) => {
		let operation = /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,6}$/
		inpYz(
			rule,
			value,
			callback,
			true,
			operation,
			'可输入中文，字母，数值，长度为2-6'
		)
	}
	var validatephone = (rule, value, callback) => {
		// let operation = /^1\d{7,10}$/
		// inpYz(rule, value, callback, true, operation, '手机号码长度为：8-11')
		let operation = /^.{8,20}$/
		inpYz(rule, value, callback, true, operation, '手机号码长度为：8-20')
	}

	// 页面对应的type
	const pageType = 'INSTALL_UNIT'
	import related from '@/components/related'
	import errorDio from '@/components/errorDio.vue'
	import PersonAction from './../rygl/components/personAction'
	import Pagination from '@/components/Pagination'
	import FileUpload from '@/components/FileUpload'
	export default {
		components: {
			related,
			errorDio,
			FileUpload,
			Pagination,
			PersonAction
		},
		data() {
			let _this = this
			return {
				// 错误信息列表
				errorList: null,
				// 相关人员参数
				xgryId: null,
				// 地区选择器
				props: {
					label: 'name',
					value: 'areaCode',
					checkStrictly: true,
				},
				// 表格参数
				delData: [], //多选的数据
				formInline: {
					current: 1,
					size: 10,
					type: pageType,
				},
				tableData: [],
				// 分页参数
				dictionarTotal: 0,
				// dialog参数
				tit: '',
				dialogVisible: false,
				fign: {},
				// 角色下拉框数据
				roleData: [],
				// 表单限制
				rules: {
					name: [{
						required: true,
						validator: validateName,
						trigger: 'blur',
					}],
					certificateCode: [{
						validator: validatecertificateCode,
						trigger: 'blur',
					}, ],
					address: [{
						required: true,
						validator: validateaddress,
						trigger: 'blur',
					}, ],
					contact: [{
						required: true,
						validator: validatecontact,
						trigger: 'blur',
					}, ],
					phone: [{
						required: true,
						validator: validatephone,
						trigger: 'blur',
					}, ],
					areaCodeArr: [{
						required: true,
						message: '请选择所属区域',
						trigger: 'change',
					}, ],
				},

				// 添加人员
				PersonDialog: false,
				useType: [],
				personnelForm: {
					unitName: "",
				}
			}
		},
		methods: {
			// 错误列表回调
			dioCloseB() {
				this.errorList = null
			},
			// 导入
			toLead() {
				document.form1.docxFile.click()
			},
			readFile(file) {
				let _this = this
				let docxFile = file.target.files[0]
				if (docxFile == undefined) {
					return
				}
				let hasFile = false
				let fileName = docxFile.name
				let fileArr = file.target.files[0].name.split('.')
				let files = docxFile
				if (!file) {
					this.$message('请先选择正确的文件类型上传')
				} else {
					let fileData = new FormData()
					fileData.append('file', files)
					fileData.append('type', files.type)
					let config = {
						onUploadProgress: (e) => {
							//属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
							//如果lengthComputable为false，就获取不到e.total和e.loaded
							if (e.lengthComputable) {
								let rate = e.loaded / e.total //已上传的比例
								if (rate < 1) {
									//这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
									//因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
									//等响应回来时，再将进度设为100%
									let uploadRate = (rate * 100).toFixed(2) + '%'
								}
							}
						},
					}
					this.$http
						.post(`/api/system/web/batch/saveData/${pageType}`, fileData, config)
						.then((res) => {
							if (res.data.success) {
								this.onRefer()
								this.$message.success(res.data.msg)
							} else {
								this.errorList = res.data.data
								this.$message.error(res.data.msg)
							}
							file.target.value = ''
						})
				}
			},
			// 下载模版
			dowMode(type) {
				downloadMode(type)
			},
			// 相关人员的方法
			selRela(item) {
				this.xgryId = item.id
			},
			xgClose() {
				this.xgryId = null
			},
			// 重置按钮事件
			reset() {
				console.log('重置')
				this.formInline = {
					current: 1,
					size: 10,
					type: pageType,
				}
				this.onRefer()
			},
			// 分页
			dictionarPage(data) {
				this.formInline.current = data.page
				this.formInline.size = data.limit
				this.onRefer()
			},
			// 弹出框提交
			dioSub() {
				console.log('this.fign', this.fign)

				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						let data = JSON.parse(JSON.stringify(this.fign))
						let url = '/api/system/web/org/submit'

						if (data.zTime) {
							data.certificateStartTime = data.zTime[0]
							data.certificateEndTime = data.zTime[1]
						}
						if (data.areaCodeArr) {
							if (data.areaCodeArr.length > 0) {
								data.areaCodes = JSON.stringify(data.areaCodeArr)
								data.areaCode = data.areaCodeArr[data.areaCodeArr.length - 1]
							}
						}
						data.type = pageType

						console.log('data', data)
						this.$http.post(url, data).then((res) => {
							if (res.data.success) {
								this.onRefer()
								this.dialogVisible = false
								this.$message.success(res.data.msg)
							} else {
								this.$message.error(res.data.msg)
							}
						})
					} else {
						this.$message.warning('请验证填写内容!')
						return false
					}
				})
			},

			// 表格方法
			onRefer(ishm) {
				if (ishm) {
					this.formInline.current = 1
				}
				if (this.formInline.areaCodeArr) {
					this.formInline.areaCodes = null
					if (this.formInline.areaCodeArr.length > 0) {
						let obj = JSON.parse(JSON.stringify(this.formInline.areaCodeArr))
						this.formInline.areaCodes = '*' + obj[obj.length - 1] + '*'
					}
				}
				console.log('this.formInline', this.formInline)
				this.$http
					.post('/api/system/web/org/list', this.formInline)
					.then((res) => {
						console.log('列表', res.data)
						if (res.data.success) {
							this.tableData = res.data.data.records
							this.dictionarTotal = res.data.data.total
							this.$nextTick(() => {
								if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
			handleSelectionChange(val) {
				this.delData = val
			},
			// 修改
			upd(item) {
				this.tit = '修改'
				this.dialogVisible = true
				this.fign = JSON.parse(JSON.stringify(item))

				let codes = JSON.parse(this.fign.areaCodes)
				this.$set(this.fign, 'areaCodeArr', codes)

				if (this.fign.certificateStartTime != null) {
					let zt = [this.fign.certificateStartTime, this.fign.certificateEndTime]
					this.$set(this.fign, 'zTime', zt)
				}
				console.log('item', this.fign)
			},
			delAll() {
				if (this.delData.length > 0) {
					console.log('this.delData', this.delData)
					let arr = []
					this.delData.forEach((qs) => {
						arr.push(qs.id)
					})
					this.del(arr)
				} else {
					this.$message.warning('请选择要删除的单位')
				}
			},
			del(item, isAll) {
				this.$confirm('此操作将永久删除选中的单位, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http
						.delete(`/api/system/web/org/remove?ids=${item.join(',')}`)
						.then((res) => {
							if (res.data.success) {
								this.delData = []
								this.$refs.multipleTable.clearSelection();
								this.onRefer()
								this.$message.success(res.data.msg)
							} else {
								this.$message.error(res.data.msg)
							}
						})
					console.log(item)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			addDj() {
				this.tit = '新增'
				this.fign = {
					jdType: 0,
					isActive: 1,
					// 后续需要删除 数据读取权限字段
					onlyReadType: 0,
				}
				this.dialogVisible = true
			},
			
			// 添加人员
			addPerson(row){
				this.personnelForm = {}
				this.PersonDialog = true
				this.useType = [pageType]
				this.personnelForm.type = pageType
				this.personnelForm.isLogin = 0,
				this.personnelForm.unitName = row.name
				this.personnelForm.orgId = row.id
			},
			changeUseType(data) {
				this.useType = data;	
			},
			// 弹出框提交
			subForm(data) {
				this.personnelForm = data;
				this.$http
					.post("/api/system/web/appUser/submit", this.personnelForm)
					.then((res) => {
					if (res.data.success) {
						this.onRefer();
						this.PersonDialog = false;
						this.$message.success(res.data.msg);
					} else {
						this.$message.error(res.data.msg);
					}
					});
			},
			colseForm() {
				this.PersonDialog = false;
			},
		},
		created() {
			this.onRefer()
		},
	}
</script>
<style scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-top {
		width: 100%;
		margin-bottom: 20px;
	}

	.page-bom {}
</style>
