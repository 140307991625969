<template>
    <div>
        <div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯名称</span>
					</span>
					<el-input v-model="formInline.name" placeholder="请输入" clearable></el-input>
				</el-form-item>
                <el-form-item>
					<span slot="label">
						<span class="lab-span">注册代码</span>
					</span>
					<el-input v-model="formInline.code" placeholder="请输入" clearable></el-input>
				</el-form-item>
                <el-form-item>
					<el-radio-group v-model="formInline.timeType" @change="onRefer(true)">
                        <el-radio-button :label="1">日</el-radio-button>
                        <el-radio-button :label="2">月</el-radio-button>
                    </el-radio-group>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onRefer(true)">查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
					<el-button type="success" :disabled="exportType" @click="exportData()">{{exportTitle}}</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div v-if="dictionarTotal!=0">
			<el-table :data="tableData" v-loading="loading" ref="multipleTable" style="width: 100%;margin-bottom: 20px;" :height="$store.state.tabHeight">
				<el-table-column fixed="left" type="index" label="序号" width="50px" />
				<el-table-column align='center' min-width="100" prop="name" label="电梯名称" />
				<el-table-column align='center' min-width="100" prop="code" label="注册代码" />
				<el-table-column align='center' min-width="100" prop="eleType" label="电梯类型" >
                    <template slot-scope="scope">
                        {{$getStatusName(scope.row.eleType,elevatorTypeList)}}
                    </template>
                </el-table-column>
				<el-table-column align='center' min-width="200" prop="elePlaceType" label="使用场所" >
                    <template slot-scope="scope">
                        {{$getStatusName(scope.row.elePlaceType,usePlaceList)}}
                    </template>
                </el-table-column>
                <el-table-column align='center' min-width="100" prop="plotName" label="所属小区" />
                <el-table-column align='center' min-width="100" prop="brandName" label="电梯品牌" />
                <el-table-column align='center' min-width="120" prop="eleAddress" label="电梯安装位置" />
                <el-table-column align='center' min-width="120" prop="inspectionUnitName" label="电梯检验单位" />
                <el-table-column align='center' min-width="100" prop="bendNum" label="累计/次" />
                <template v-for="(item, index) in th">
                    <el-table-column align='center' min-width="100" :label="item" :key="index">
                        <template slot-scope="scope">
                            {{ Math.ceil(scope.row[item]).toFixed(0)}}
                        </template>
                    </el-table-column>
                </template>
			</el-table>
			<Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
				@pagination="dictionarPage" />
		</div>
		<div class="nullDate" v-else>
			<img src="@/assets/nullDate.png" style="width: 260px;" />
		</div>
    </div>

</template>

<script>
import Pagination from '@/components/Pagination'
import { getTimeSE } from '@/util'
export default {
    components: {
        Pagination,
    },
    data() {
        return {
            // 表格参数
            formInline: {},
            tableData: [],
            th:[],
            elevatorTypeList:[],
            usePlaceList:[],
            // 分页参数
            dictionarTotal: 0,
            loading: false,
            exportType: false,
            exportTitle: '导出'
        }
    },
    methods:{
        initFormInline(){
            this.formInline = {
                current: 1,
                size: 10,
                timeType:this.formInline.timeType?this.formInline.timeType:1,
            }
        },
        // 重置按钮事件
        reset() {
            this.initFormInline()
            this.onRefer()
        },
        // 分页
        dictionarPage(data) {
            this.formInline.current = data.page
            this.formInline.size = data.limit
            this.onRefer()
        },

         // 导出
        async exportData (ishm) {
            console.log('adsadasdasdasdasd');
            this.exportType = true
            this.exportTitle = '正在导出'
            const {start,end}=getTimeSE(this.formInline.timeType)
            this.formInline.startTime = start
            this.formInline.endTime = end
            this.$http.post("/api/ele/web/export/elevatorBending", this.formInline, { responseType: 'blob' }).then((res) => {
                if (res.data.size > 0) {
                    let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                    let url = URL.createObjectURL(blob);
                    const link = document.createElement('a'); //创建a标签
                    link.href = url;
                    link.download = '折弯次数统计.xls'; //重命名文件
                    link.click();
                    URL.revokeObjectURL(url);
                    console.log("下载文件" + res);
                } else {
                    this.$message.error('暂无数据')
                }
                this.exportType = false
                this.exportTitle = '导出'
            })
        },
        // 表格方法
        onRefer(ishm) {
            this.loading = true
            if (ishm) {
                this.formInline.current = 1
            }
            const {start,end}=getTimeSE(this.formInline.timeType)
            this.formInline.startTime = start
            this.formInline.endTime = end
            // let nowDate=new Date().getTime()
            // if(this.formInline.endTime>nowDate){
            //     this.formInline.endTime=nowDate
            // }
            console.log(this.$formatDate(this.formInline.startTime),this.$formatDate(this.formInline.endTime))
            this.$http.post("/api/ele/web/elevatorInfo/elevatorBending", this.formInline)
            .then((res) => {
                if (res.data.success) {
                    this.tableData = res.data.data.records
                    this.th = res.data.data.th.reverse()
                    this.dictionarTotal = parseInt(res.data.data.total)
                    this.$nextTick(() => {
                        if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                            this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                            this.$refs.multipleTable.doLayout()
                        }
                    })
                } else {
                    this.$message.error(res.data.msg)
                }
                this.loading = false
            })
        },
        //获取字典
        getDictionary(codes=['ELEVATOR_TYPE','USE_PLACE']){
            this.$http.post(`/api/system/web/dict/dictionary?codes=${codes}`)
            .then((res) => {
                if (res.data.success) {
                    this.elevatorTypeList = res.data.data.ELEVATOR_TYPE
                    this.usePlaceList = res.data.data.USE_PLACE
                    this.onRefer()
                }
            })
        },


    },
    created(){
        this.initFormInline()
        this.getDictionary()
    }
}
</script>

<style scoped>
.pBox {
		font-size: 18px;
		display: flex;
	}

	.pSpan {
		width: 100px;
	}

	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-top {
		width: 100%;
		margin-bottom: 20px;
	}
</style>