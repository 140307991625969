<template>
	<div class="dioBg">
		<div class="dioBg-om" v-if="dioData.eleSmsRescordDTOS.length > 0">
			<p><span class="hpan">短信通知</span></p>
			<el-card class="infoClass" style="height:280px">
				<p>短信发送时间: {{dioData.eleSmsRescordDTOS.length > 0 ? dioData.eleSmsRescordDTOS[0].createTime : '-'}}</p>
        <div v-if="dioData.eleSmsRescordDTOS.length > 0">
          <p class="dioDataTime" v-for="(item, index) in dioData.emergencyNames" :key="index">
            {{item}}
          </p>
          <p class="dioDataTime" v-for="(item, index) in dioData.maintainNames" :key="index">
            {{item}}
          </p>
          <p class="dioDataTime" v-for="(item, index) in dioData.propertyNames" :key="index">
            {{item}}
          </p>
        </div>
        <!-- <p style="color:#ccc">已限流: 被叫号码在一定的时间内，达到次数上限，已被运营商限制被叫</p> -->
			</el-card>
		</div>

		<div class="dioBg-om" v-if="dioData.voiceRescordDTOS.length > 0">
			<p><span class="hpan">电话通知</span></p>
			<el-card class="infoClass" style="height:280px">
				<p>电话拨打时间: {{dioData.voiceRescordDTOS.length > 0 ? dioData.voiceRescordDTOS[0].createTime : '-'}}</p>
        <div v-if="dioData.voiceRescordDTOS.length > 0">
          <template v-for="item in dioData.voiceRescordDTOS">
            <p :key="item.id">{{item.userName}}({{item.phoneNumber}}) ----
              <span style="font-weight: 600; font-size: 17px;" :style="item.code == 'OK'? 'color:#0f0' : 'color:#ff5b5b'">{{item.code == 'OK' ? '调用成功' : '已限流'}}</span>
            </p>
          </template>
        </div>
        <p v-if="dioData.voiceRescordDTOS.find(item => item.code != 'OK')" style="color:#ccc">已限流: 被叫号码在一定的时间内，达到次数上限，已被运营商限制被叫</p>
			</el-card>
		</div>

	</div>
</template>

<script>
	export default {
		props: ['dioData'],
		data() {
			return {
				showUrl: null,
			}
		},
    computed:{
        codeStatus(){
          if (this.dioData.voiceRescordDTOS.find(item => item.code != 'OK')) {
            return false
          }
        }
    },
		mounted() {
			this.showUrl = this.dioData.sosVideo.length > 0 ? this.dioData.sosVideo[0].videoUrl : ''
		},
		methods: {
			showVoid(url) {
				this.showUrl = url
			}
		}
	}
</script>

<style lang="scss" scoped>

/deep/.infoClass{
  margin-top: 20px;
  .el-card__body{
    padding: 15px !important;
    font-size: 15px;
    min-height: 100px;
  }
}

.dioBg {
  width: 98%;
  height: 100%;
  display: flex;
  .hpan {
    margin-right: 5px;
    font-weight: bold;
    font-size: 18px;
  }

  .dioBg-om {
    width: 48%;
    height: 10%;
    margin-right: 2%;
  }
}

</style>
