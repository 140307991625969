<template>
  <div>
    <div>
      <el-button
        v-focus
        type="success"
        size="small"
        @click="addDj"
      >新增</el-button>
      <template v-if="personnelTotal != 0">
        <el-table
          ref="multipleTable"
          :data="tableData"
           max-height="650"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50px"
          />
          <el-table-column
            align="center"
            prop="name"
            label="维保项名称"
            min-width="150px"
          />
          <el-table-column
            align="center"
            prop="orgName"
            label="维保类型"
            min-width="150px"
          >
            <template slot-scope="scope">
              {{
                maintainType.find((item1) => Number(item1.value) === scope.row.type)
                  ? maintainType.find((item1) => Number(item1.value) === scope.row.type)
                      .label
                  : ''
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="createTime"
            label="创建时间"
            min-width="150px"
          />
          <el-table-column
            align="center"
            prop="updateTime"
            label="更新时间"
            min-width="150px"
          />
          <el-table-column
            align="center"
            prop="isDeleted"
            label="维保项目数量"
            min-width="150px"
          >
            <template slot-scope="scope">
              {{ JSON.parse(scope.row.itemCodes).length }}
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            prop="isActive"
            label="状态"
            width="120px"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isActive"
                :active-value="1"
                :inactive-value="0"
                active-color="#409eff"
                inactive-color="#909399"
                @change="isSealedChange(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="createUserName"
            label="创建人"
            min-width="150px"
          />
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="300px"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.id != 1 && scope.row.id != 2 && scope.row.id != 3 && scope.row.id != 4">
                <el-button
                  v-focus
                  @click="check(scope.row)"
                  type="warning"
                  size="small"
                >查看
                </el-button>
                <el-button
                  v-focus
                  @click="edit(scope.row)"
                  type="primary"
                  size="small"
                >编辑
                </el-button>
                <el-button
                  v-focus
                  @click="del(scope.row.id)"
                  type="danger"
                  size="small"
                >删除
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="personnelTotal"
          :page.sync="searchForm.current"
          :limit.sync="searchForm.size"
          @pagination="personnelPage"
        />
      </template>

      <div
        class="nullDate"
        v-else
      >
        <img
          src="@/assets/nullDate.png"
          style="width: 260px"
        />
      </div>
    </div>

    <el-dialog
      v-if="dialogVisible"
      :title="tit"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="950px"
      top="15vh"
    >
      <div
        style="height: 40vh; overflow-y: scroll; padding-right: 20px"
        class="scrollElement"
      >
        <el-form
          label-position="right"
          label-width="120px"
          ref="mainterItemForm"
          :model="mainterItemForm"
          :rules="formRules"
        >
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item
                label="维保项名称:"
                prop="name"
              >
                <el-input
                  v-model="mainterItemForm.name"
                  placeholder="请输入"
                  class="wid90"
                  :disabled="dialogVisibleDis"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="维保类型:"
                prop="type"
              >
                <el-select
                  :disabled="dialogVisibleDis"
                  class="wid90"
                  v-model="mainterItemForm.type"
                  placeholder="请输入"
                  @change="changeItemType"
                >
                  <el-option
                    v-for="(item, index) in maintainType"
                    :key="index"
                    :label="item.label"
                    :value="Number(item.value)"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <template v-for="(item, index) in mainterItemForm.mainItemsArr">
                <el-form-item
                  :label="`标题${index + 1}:`"
                  :key="index"
                  :prop="'mainItemsArr.' + index + '.value'"
                  :rules="{
                    required: true,
                    message: '标题至少需要中文，字母，数值，特殊符号，长度为5-200',
                    trigger: 'change',
                    min: 5,
                    max: 200,
                  }"
                >
                  <el-input
                    :disabled="dialogVisibleDis"
                    v-model="item.value"
                    placeholder="请输入"
                    @change="changeLabel(index)"
                    class="wid70 mr20"
                  />
                  <el-button
                    type="text"
                    size="medium"
                    icon="el-icon-delete"
                    class="mr20"
                    style="color: red !important; font-size: 20px"
                    @click="deleteItem(index)"
                    :disabled="dialogVisibleDis"
                  />
                  <el-checkbox
                    v-model="item.checked"
                    :disabled="dialogVisibleDis"
                  >是否拍照</el-checkbox>
                </el-form-item>
              </template>
            </el-col>
            <el-button
              :disabled="dialogVisibleDis"
              type="primary"
              icon="el-icon-plus"
              style="padding: 10px 20px; margin-left: 120px"
              @click="chooseTableItem"
            >
              从模板中选择
            </el-button>
            <el-button
              :disabled="dialogVisibleDis"
              type="primary"
              icon="el-icon-plus"
              style="padding: 10px 40px; margin-left: 10px"
              @click="chooseItem"
            />
          </el-row>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          v-if="!dialogVisibleDis"
          size="small"
          type="primary"
          :loading="loading"
          @click="dioSub(ItemType)"
        >确 定</el-button>
        <el-button
          size="small"
          @click="dialogVisible = false"
        >取 消</el-button>
      </span>
    </el-dialog>
    <WbItem
      v-if="selectDialogVisible"
      ref="wbItem"
      :selectUnitList="maintainItem"
      @transferUnit="selectOtherResult"
    />
  </div>
</template>
<script>
import Pagination from '../../../components/Pagination'
import WbItem from './components/wbItem'
export default {
  components: {
    Pagination,
    WbItem,
  },
  data() {
    return {
      pageType: 'EMPLOYEE_MANAGE',
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
      },
      // 维保项类型
      maintainType: [],
      // 维保项内容
      maintainItem: [],
      // MainItemsArr: [{ value: '', checked: true }], // 已选维保项
      // 单位类型
      unitList: [],
      tableData: [],
      // 分页参数
      personnelTotal: 0,
      // dialog参数
      tit: '',
      dialogVisible: false,
      dialogVisibleDis: false, // 查看禁点
      loading: false,
      ItemType: null, // 新增修改状态
      selectRow: [], // 选中维保项
      personnelData: {}, // 维保项数据
      selectDialogVisible: false, // 维保项列表
      mainterItemForm: {
        name: '',
        mainItemsArr: [{ value: '', checked: false }],
      },
      formRules: {
        name: [
          {
            required: true,
            message: '维保项名称至少需要中文，字母，数值，特殊符号，长度为2-30',
            trigger: 'blur',
            min: 2,
            max: 30,
          },
        ],
        type: [
          {
            required: true,
            message: '维保类型至少选择一项',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {},
  methods: {
    // 获取字典数据
    getDictionar(code) {
      // code = code.join()
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          console.log('字典', res)
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res

            this.maintainType = data.MAINTAIN_TYPE
            this.maintainItem = data.MAINTAIN_ITEM
            this.maintainItem.forEach((item) => {
              item.checked = false
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    // 分页
    personnelPage(data) {
      this.searchForm.current = data.page
      this.searchForm.size = data.limit
      this.onRefer()
    },

    // 弹出框提交
    dioSub(type) {
      // this.loading = true
      let url = ''
      if (type === 'add') {
        url = '/api/ele/web/maintainItem/add'
      } else {
        url = '/api/ele/web/maintainItem/edit'
      }
      this.mainterItemForm.itemCodes = JSON.stringify(
        this.mainterItemForm.mainItemsArr
      )
      console.log(this.mainterItemForm, 'this.maintenRule')
      this.$refs.mainterItemForm.validate(async (valid) => {
        if (valid) {
          console.log(this.maintenRuleForm, 'this.maintenRule')
          this.$http.post(url, this.mainterItemForm).then((res) => {
            console.log(res, '弹出框提交')
            if (res.data.success) {
              this.onRefer()
              this.dialogVisible = false
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
      this.loading = false
    },
    // 是否开启
    isSealedChange(val) {
      const data = {
        id: val.id,
        type: val.type,
        name: val.name,
        itemCodes: val.itemCodes,
        isActive: val.isActive,
      }
      console.log(data, 'data')
      this.$http.post('/api/ele/web/maintainItem/edit', data).then((res) => {
        if (res.data.success) {
          this.onRefer()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取维保项方法
    onRefer() {
      const data = JSON.parse(JSON.stringify(this.searchForm))
      this.$http.post('/api/ele/web/maintainItem/getList', data).then((res) => {
        console.log('列表', res.data)
        if (res.data.success) {
          const {
            data: { data },
          } = res
          this.tableData = data.records ?? []
          this.personnelTotal = data.total ?? 0
          this.$nextTick(() => {
            if (this.personnelTotal > 0 && this.$refs.multipleTable) {
              this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    changeLabel(value){
      this.mainterItemForm.mainItemsArr[value].label = ''
    },
    // 查看
    check(item) {
      console.log(item, '查看')
      this.tit = '查看维保项'
      this.dialogVisible = true
      this.dialogVisibleDis = true
      this.mainterItemForm = JSON.parse(JSON.stringify(item))
      this.$set(
        this.mainterItemForm,
        'mainItemsArr',
        JSON.parse(item.itemCodes)
      )
    },

    // 修改
    edit(item) {
      console.log(item, '修改')
      this.ItemType = 'edit'
      this.tit = '编辑维保项'
      this.dialogVisible = true
      this.dialogVisibleDis = false
      this.mainterItemForm = JSON.parse(JSON.stringify(item))
      this.$set(
        this.mainterItemForm,
        'mainItemsArr',
        JSON.parse(item.itemCodes)
      )
    },
    // 删除
    del(id) {
      this.$confirm('此操作将永久删除选中的维保项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const data = {
          id,
        }
        this.$http
          .post('/api/ele/web/maintainItem/delete', data)
          .then((res) => {
            console.log('列表', res.data)
            if (res.data.success) {
              const num = id.indexOf(',') > -1 ? id.split(',').length : 1
              if (this.tableData.length === num) {
                this.searchForm.current -= 1
              }
              this.onRefer()
            } else {
              this.$message.error(res.data.msg)
            }
          })
      })
    },

    // 新增
    addDj() {
      this.tit = '新增维保项'
      this.ItemType = 'add'
      this.dialogVisible = true
      this.dialogVisibleDis = false
      this.mainterItemForm = {
        name: '',
        type: '',
        mainItemsArr: [{ value: '', checked: false }],
      }
    },
    changeItemType (val) {
      let id = 1
      switch (val) {
        case 1:
          id = 1
          break
        case 3:
          id = 2
          break
        case 4:
          id = 4
          break
        case 5:
          id = 3
          break
      
        default:
          break
      }
      this.$http
        .get(`/api/ele/web/maintainItem/getMaintainItemDetail?id=${id}`)
        .then((res) => {
          if (res.data.success) {
            this.$set(
              this.mainterItemForm,
              'mainItemsArr',
              JSON.parse(res.data.data.itemCodes)
            )
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },

    // 添加维保项
    chooseItem() {
      console.log(12313)
      if (this.mainterItemForm.mainItemsArr.find((item) => item.value === '')) {
        this.$message.error('已经有空白的维保项')
        return
      }
      this.mainterItemForm.mainItemsArr.push({ value: '', checked: false })
    },
    // 删除维保项
    deleteItem(index) {
      console.log(index, 'deleteItem')
      if (this.mainterItemForm.mainItemsArr.length === 1) {
        this.$message.error('已经是最后一条维保项了')
        return
      }
      this.mainterItemForm.mainItemsArr.splice(index, 1)
    },
    // 列表添加维保项
    chooseTableItem() {
      console.log('选择维保项')
      this.selectDialogVisible = true
      this.$nextTick(() => {
        this.$refs.wbItem.init(this.mainterItemForm.mainItemsArr, (refresh) => {
          if (refresh) {
          }
        })
      })
    },
    // 选择单位返回
    selectOtherResult(data) {
      console.log('选择单位返回', data)
      if (data.length > 0) {
        this.selectRow = JSON.parse(JSON.stringify(data))
        this.selectDialogVisible = false
        this.selectRow.forEach((item) => {
          // if (item.checked) {
          item.checked = item.checked ?? false
          // }
        })
        // if (!this.selectRow[0].value) {
        //   this.selectRow.splice(0, 1)
        // }
        this.$set(this.mainterItemForm, 'mainItemsArr', [...this.mainterItemForm.mainItemsArr, ...this.selectRow])
      } else {
        this.$set(this.mainterItemForm, 'mainItemsArr', [
          { value: '', checked: false },
        ])
      }
    },
  },
  created() {
    this.getDictionar(['MAINTAIN_TYPE', 'MAINTAIN_ITEM'])
  },
  mounted() {
    this.$nextTick(() => {
      this.onRefer()
    })
  },
}
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchBtn {
  margin-left: 15px;
}
.wid90 {
  width: 95%;
}
.wid70 {
  width: 70%;
}
.mr20 {
  margin-right: 20px;
}
/deep/.el-checkbox {
  color: #fff;
}
/deep/.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #c7c7c7;
}
</style>
