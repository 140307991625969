<template>
  <div>
    <div class="page-top">
      <!-- 加提交时间和审核时间，默认搜索前三个月 -->
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">AI摄像头ID</span>
          </span>
          <el-input v-model="formInline.deviceId" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">电梯名称</span>
          </span>
          <el-input v-model="formInline.eleName" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">审核状态</span>
          </span>
          <el-select v-model="formInline.status" placeholder="请输入" class="einp" clearable>
            <el-option label="未审核" :value="0" />
            <el-option label="已通过" :value="1" />
            <el-option label="不通过" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">提交时间</span>
          </span>
          <el-date-picker v-model="formInline.gitTime" type="daterange" range-separator="到" start-placeholder="开始时间"
            end-placeholder="结束时间"  />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">审核时间</span>
          </span>
          <el-date-picker v-model="formInline.checkTime" type="daterange" range-separator="到" start-placeholder="开始时间"
            end-placeholder="结束时间"  />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onRefer(true)">查询</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <template v-if="dictionarTotal != 0">
        <el-table ref="multipleTable" :data="tableData" style="width: 100%;margin: 20px 0;"
          :height="$store.state.tabHeight-100">
          <!-- <el-table-column type="selection" width="55px"/> -->
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align='center' prop="deviceId" label="AI摄像头ID" width="150px" />
          <el-table-column align='center' prop="eleName" label="电梯名称" width="200px"/>
          <el-table-column align='center' prop="address" label="安装位置" width="200px" />
          <el-table-column align='center' prop="createUserName" label="提交人" width="120px"/>
          <el-table-column align='center' prop="contactWay" label="联系方式" width="120px"/>
          <el-table-column align='center' prop="planStartTime" label="审核状态">
            <template slot-scope="scope">
              {{ getStatus(scope.row.status) }}
            </template>
          </el-table-column>
          <el-table-column align='center' prop="createTime" label="提交时间" width="180px" />
          <el-table-column align='center' prop="examineTime" label="审核时间" width="180px" />
          <el-table-column fixed="right" align='center' prop="address" label="操作" width="150px">
            <template slot-scope="scope">
              <el-button @click="check(scope.row)" type="primary" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
          @pagination="contractPage" />
      </template>
      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </div>
    <InstallDetail v-if="installType" ref="install" :imgList="imgList" :eleInfo="eleInfo" @change="onRefer"/>
  </div>
</template>
<script>
  import Pagination from '../../../components/Pagination'
  import InstallDetail from './components/InstallDetail'
  import { formatDate } from "@/util";
  let current_time = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00")
  let starTime = new Date(current_time.getTime() - (90 * 24 * 3600 * 1000));
  let endTime = new Date(current_time.getTime());
  export default {
    components: {
      Pagination,
      InstallDetail
    },
    data() {
      return {
        formInline: {
          current: 1,
          size: 10,
          gitTime:[starTime, endTime],
        },
        dictionarTotal: 0,
        tableData: [],
        imgList:[],
        installType: false,
        eleInfo: null,
      }
    },
    methods: {
      // 分页
      contractPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      // 重置
      reset() {
        console.log('重置')
        this.formInline = {
          current: 1,
          size: 10,
        }
        this.onRefer()
      },
      // 获取合同方法
      onRefer(ishm) {
        this.installType = false
        if (ishm) {
          this.formInline.current = 1
        }
        // if(this.formInline.gitTime){
          this.formInline.createStartTime = this.formInline.gitTime ? this.formInline.gitTime[0].getTime() : ''
          this.formInline.createEndTime = this.formInline.gitTime ? this.formInline.gitTime[1].getTime() + (24 * 3600 * 1000 -1000) : ''
        // }
        // if(this.formInline.checkTime){
          this.formInline.examineStartTime = this.formInline.checkTime ? this.formInline.checkTime[0].getTime() : ''
          this.formInline.examineEndTime = this.formInline.checkTime ? this.formInline.checkTime[1].getTime() + (24 * 3600 * 1000 -1000) : ''
        // }
        this.$http
          .post("/api/ele/web/installRecord/getList", this.formInline)
          .then((res) => {
            if (res.data.success) {
              this.tableData = res.data.data.records
              this.dictionarTotal = res.data.data.total
              this.$nextTick(() => {
                if (this.unitTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      check(item) {
        this.eleInfo = item
        var ant = this.eleInfo.antennaImg.split(',')
        var end = this.eleInfo.endingImg.split(',')
        var pow = this.eleInfo.powerImg.split(',')
        var scr = this.eleInfo.screenImg.split(',')
        this.imgList = [...ant, ...end, ...pow, ...scr].filter(item => item !== '');
        this.installType = true
        this.$nextTick(() => {
          this.$refs.install.init((refresh) => {
            if (refresh) {}
          })
        })
      },
      getStatus(status) {
        let title = "-"
        switch (status) {
          case 0:
            title = '未审核'
            break;
          case 1:
            title = '已通过'
            break;
          case 2:
            title = '不通过'
            break;
          default:
            break;
        }
        return title
      },
    },
    created() {
      this.onRefer()
    }
  }
</script>
<style lang="scss" scoped>
  .searchBtn {
    margin-left: 15px;
  }

  .nullDate {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
