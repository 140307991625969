<template>
	<div>
		<el-row>
			<el-col :span="16" class="left">
				<div class="mapBox" ref="mapBox">
					<!-- <el-button
            size="small"
            type="primary"
            @click="backBtn"
            >返回</el-button
          > -->
					<Map v-if="isShow||jkMsg.length==0"  @openInfo="openInfo"/>
					<div class="jkShowBox" v-else>
						<template v-for="item in jkMsg">
							<wbjkZlv :key="item.code" @closeJks="closeJk" :jkData="item"
								:style="{width:ow+'px',height:oh}" style="background-color: black;" />
						</template>
					</div>
				</div>
				<div class="jkBox">
					<el-row v-if="jkTotal != 0">
						<el-col :span="8" v-for="(item, index) in jkList" class="jkItem" :key="index">

							<!-- <button @click="addJk">add</button> -->

							<div @click="openJk(item, 'jkList')">
								<el-image style="width: 95%; height: 160px; margin: 0 auto;cursor: pointer;"
									:src="item.imgUrl" fit="cover" />
								<!-- <h4>{{ item.userName }}( {{ item.userPhone }} )</h4> -->
								<h4>{{ item.userName }}( {{ item.code }} )</h4>
							</div>
						</el-col>
					</el-row>
					<div v-else>
						<img src="@/assets/nullDate.png"
							style="width: 100%;height: 200px; margin: 0 auto;object-fit: contain;" />
					</div>
					<Pagination :total="jkTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
						:page-sizes="[3]" @pagination="dictionarPage" />
				</div>
			</el-col>
			<el-col :span="8" class="right">
				<h4>进行中维保任务</h4>
				<div style="height: 75vh; overflow-y: scroll" class="scrollElement">
					<template v-if="taskTotal != 0">
						<div v-for="(item, index) in taskList" class="taskBox" :key="index"
							@click="openJk(item, 'taskList')">
							<div class="boxTitle">
								<p class="titleName">
									{{ item.eleName }}
									({{ maintainType.find(item1 => item1.value == item.maintainType) ? maintainType.find(item1 => item1.value == item.maintainType).label : '-'}})
								</p>
								<p>{{ item.startTime.split(' ')[0] }}</p>
							</div>
							<div class="boxBody">
								<p>{{ item.maintainUnitName }}（{{item.maintainUserNames}}）</p>
								<p>{{ item.propertyUnitName }}（{{item.maintainUserNames}}）</p>
								<p>{{ item.eleAddress }}</p>
								<p>监控人：{{ item.maintainUserNames }} ({{item.capCode}})</p>
							</div>
						</div>
					</template>
					<div v-else style="text-align: center;">
						<img src="@/assets/nullDate.png"
							style="width: 150px;height: 75vh; margin: 0 auto;object-fit: contain;" />
					</div>
				</div>
				<div class="rightBottom">
					<el-button size="small" :disabled="this.taskInline.current == 1" type="primary"
						@click="back">上一页</el-button>
					<el-button size="small" :disabled="
              this.taskInline.current * this.taskInline.size > taskTotal
            " type="primary" @click="next">下一页</el-button>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import Pagination from "@/components/Pagination";
	import Map from "./components/map";
	import wbjkZlv from "@/components/cap/wbjkZlv";
	export default {
		components: {
			Pagination,
			wbjkZlv,
			Map
		},
		data() {
			return {
				ow: 0,
				oh: 0,
				jkMsg: [],
				nullImg: '@/assets/nullDate.png',
				isShow: true,
				// 维保项类型
				maintainType: [],
				taskInline: {
					current: 1,
					size: 10,
				},
				taskList: [], //维保列表
				taskTotal: 0,
				//底部列表
				formInline: {
					current: 1,
					size: 3,
				},
				jkList: [], //底部列表
				jkTotal: 0, //底部列表数量

				
				eleInfo: null,
				useId: null, // id
				MonitorInfoVisible: false, // 详情弹框
			};
		},
		methods: {
			closeJk(data, type) {
				console.log('data, type', data, type)
				if (type == 'all') {
					this.jkMsg.forEach((res, ind) => {
						if (res.code != data.code) {
							this.jkMsg.splice(ind, 1)
						}
					})
				} else {
					this.jkMsg.forEach((res, ind) => {
						if (res.code == data.code) {
							console.log('res.code == data.code', res.code, data.code)
							this.jkMsg.splice(ind, 1)
							console.log('this.jkMsg', this.jkMsg)
						}
					})
				}
				let len = this.jkMsg.length
				this.setWh(len)
			},
			// 获取字典数据
			getDictionar(code) {
				// code = code.join()
				this.$http
					.post(`/api/system/web/dict/dictionary?codes=${code}`)
					.then((res) => {
						console.log('字典', res)
						if (res.data.code === 200) {
							const {
								data: {
									data
								},
							} = res

							this.maintainType = data.MAINTAIN_TYPE
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
			// 底部列表
			getjkData() {
				this.$http
					.get(
						`/api/cap/web/capUser/getCapUserPage?current=${this.formInline.current}&size=${this.formInline.size}`
					)
					.then((res) => {
						if (res.data.success) {
							const {
								data: {
									data
								},
							} = res;
							this.jkList = data.records ?? [];
							this.jkTotal = data.total ?? 0;
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 维保列表
			getTaskData() {
				this.$http
					.get(
						`/api/ele/web/maintainTask/getCapMaintainTaskPage?current=${this.taskInline.current}&size=${this.taskInline.size}`
					)
					.then((res) => {
						if (res.data.success) {
							const {
								data: {
									data
								},
							} = res;
							this.taskList = data.records ?? [];
							this.taskTotal = data.total ?? 0;
						} else {
							this.$message.error(res.data.msg);
						}
					});
			},
			// 维保任务列表
			// 维保任务列表上一页
			back() {
				this.taskInline.current--;
				this.getData();
			},
			// 维保任务列表下一页
			next() {
				this.taskInline.current++;
				this.getData();
			},

			// 底部翻页
			dictionarPage() {
				this.getjkData();
			},
			// addJk() {
			// 	let len = this.jkMsg.length + 1
			// 	let data = {
			// 		name: '测试' + len,
			// 		w: len * 200,
			// 		h: len * 100
			// 	}
			// 	this.openJk(data)
			// },
			setWh(len) {
				let num = 0.95
				this.oh = '90%'
				if (len == 2) {
					num = 0.475
				} else if (len == 3) {
					num = 0.316
					this.oh = '75%'
				}
				this.ow = this.$refs.mapBox.offsetWidth * num
				console.log('this.owh', this.ow, this.oh, len)
			},
			// 打开监控
			openJk(data, type) {
				let dataList = {}
				if (type == 'jkList') {
					dataList = {
						code: data.code,
						name: data.userName,
						userPhone: data.userPhone
					}
				} else {
					dataList = {
						code: data.capCode,
						name: data.maintainUserNames,
						userPhone: data.userPhone ? data.userPhone : ''
					}
				}
				let len = this.jkMsg.length
				if (len < 3 && !this.jkMsg.find(item => item.code == dataList.code)) {
					let agentData = {
						act: 'ma_start_rtsp',
						code: dataList.code
					}
					this.socketCapsApi.sendCapsSock(agentData, (e) => {
						if (e.status) {
							if (e.cmd == 'ma_start_rtsp' && e.data) {
								let edata = JSON.parse(e.data)
								let jkObj = {
									code: dataList.code,
									name: dataList.userName,
									play_url: edata.play_url,
									userPhone: data.userPhone
								}
								this.jkMsg.push(jkObj)
								len = this.jkMsg.length
								this.setWh(len)
								this.isShow = false;
							}
						} else {
							this.$message.error(e.msg)
						}
					})

				} else {
					if (len > 2) {
						this.$message.error('最多同时打开三个监控!')
					}
				}
			},
			// 
			backBtn() {
				this.isShow = true;
			},
			openInfo(data){
				console.log(data, 'data');
				this.eleInfo = data;
				this.useId = data.id;
				this.openJk(data, 'jkList')
			},
		},
		created() {
			this.getTaskData();
			this.getjkData();
			this.getDictionar(['MAINTAIN_TYPE'])
		},
	};
</script>
<style lang="scss" scoped>
	.jkShowBox {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.left {
		padding-right: 2%;

		.mapBox {
			height: 50vh;
			width: 100%;
			background: #7b7b7b;
		}

		.jkBox {
			margin-top: 25px;
			height: 28vh;
			padding: 15px 10px;
			background: #4682a499;
			border-radius: 5px;

			.jkItem {
				text-align: center;
				margin-bottom: 15px;

				h4 {
					color: #fff;
					font-size: 14px;
					margin: 5px 0;
				}
			}
		}
	}

	.right {
		color: #fff;
		font-size: 14px;

		.taskBox {
			padding: 5px 10px;
			background: #457091b3;
			border-radius: 5px;
			margin: 10px 5px;

			.boxTitle {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.titleName {
					font-size: 15px;
					font-weight: 600;
				}
			}
		}
		.taskBox:hover{
			background: #088fb9de;
			cursor:pointer;
		}

		.rightBottom {
			display: flex;
			justify-content: space-evenly;
			margin-top: 20px;
		}

		h4 {
			margin: 5px 0;
		}

		p {
			margin: 9px 0;
		}
	}

	/deep/.el-pagination__total,
	/deep/.el-pagination__jump {
		color: #fff;
	}
</style>