<template>
  <div>
    <div class="dtBox1">
      <div class="supervisoryBox">
        <template v-if="dioData.DeviceData && !dioData.DeviceData.isOnline">
          <div style="width: 100%;height:100%;margin:auto;background:#000;display:flex;align-items: center;justify-content: center;">
            <el-image
              fit="contain"
              :src="require('@/assets/notOnline.png')"
              style="width: 25%;"
            />
            <div style="color:#fff;width: 55%;margin-left: 5%;font-size:16px">
              <h4>离线时间：{{dioData.lastLogoutTime ? new Date(parseInt(dioData.lastLogoutTime)).toLocaleString() : ''}}</h4>
              <span>1、 登录App或后台网页，查看球机离线前的最后状态：</br>
                A、若显示低电量，建议安排现场检查电源情况；</br>
                B、若信号很弱或重启后又离线的，建议现场实测信号情况以及球机天线两头有没有拧紧，并进行重启观察；</br>
                2、登录网页后台“电梯运行监测”列表，检查球机是否有具体“激活时间”，若未曾激活上线过，建议现场重启后观察；</br>
                3、登录app或后台网页，查看电梯资料AI摄像头ID和设备ID号码是否一致；</br>
                4、如以上均无法解决，可在App内发起"帮助与反馈"，或者寻求管理员帮助。</span>
            </div>
          </div>
        </template>
        <acContainer
          v-else
          :eleInfo="eleInfo"
          :dioData="dioData"
        />

      </div>
      <div class="lift-sequence">
        <div class="lift-sequenceTop">
          <h3 style="margin: 0;">电梯参数</h3>
          <div>
            <el-image
              v-if="dioData.DeviceData && dioData.DeviceData.isOnline !== ''"
              :fit="'cover'"
              :src="dioData.DeviceData.isOnline ? require('@/assets/monitor/qiuji1.png') : require('@/assets/monitor/qiuji0.png')"
              style="width:25px;height:25px"
            />
            <el-image
              v-if="dioData.terminalData && dioData.terminalData.isOnline"
              :fit="'cover'"
              :src="dioData.terminalData.isOnline ?require('@/assets/monitor/hezi1.png') : require('@/assets/monitor/hezi0.png')"
              style="width:25px;height:25px"
            />
          </div>
        </div>
        <!-- <el-progress type="circle" stroke-linecap="butt" :percentage="100" stroke-width="8"></el-progress>
        <el-progress type="circle" :percentage="50" stroke-width="8" status="exception" define-back-color="#20a0ff"></el-progress> -->
        <el-descriptions
          class="margin-top"
          :column="1"
          border
        >
          <el-descriptions-item label="电梯名称"> {{dioData | showTitle(dioData.name)}} </el-descriptions-item>
          <el-descriptions-item label="安装位置">
            <el-tooltip
              effect="dark"
              :content="dioData.address ? dioData.address :'-'"
              placement="top"
            >
              <div class="titlTips">
                {{dioData | showTitle(dioData.address)}}
              </div>
            </el-tooltip>
          </el-descriptions-item>
          <el-descriptions-item label="维保单位"> {{dioData | showTitle(dioData.maintainUnitName)}}</el-descriptions-item>
          <el-descriptions-item label="使用单位">{{dioData | showTitle(dioData.propertyUnitName)}}</el-descriptions-item>
          <el-descriptions-item label="电梯品牌">{{dioData | showTitle(dioData.brandName)}}</el-descriptions-item>
          <el-descriptions-item label="制造单位">{{dioData | showTitle(dioData.manufacturingUnitName)}}</el-descriptions-item>
          <el-descriptions-item label="数字终端ID">{{dioData | showTitle(dioData.terminalId)}}</el-descriptions-item>
          <el-descriptions-item label="AI摄像头ID">{{ dioData.caVer ? dioData.deviceCode :'-'  }}</el-descriptions-item>
          <el-descriptions-item label="电梯注册代码">{{dioData | showTitle(dioData.code)}}</el-descriptions-item>
          <el-descriptions-item label="物联网设备系统版本">
            <el-tooltip
              v-if="dioData.caVer"
              effect="dark"
              :content="`${dioData.caVer ? dioData.caVer :'-'} / ${dioData.appVer ? dioData.appVer :'-'}` "
              placement="top"
            >
              <div class="titlTips">{{dioData | showTitle(dioData.caVer)}} / {{dioData | showTitle(dioData.appVer)}}</div>
            </el-tooltip>
            <div v-else>{{dioData | showTitle(dioData.caVer)}} / {{dioData | showTitle(dioData.appVer)}}</div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="dtBox2">
      <h3 style="color:#fff">电梯数据</h3>
      <el-descriptions
        class="margin-top"
        :column="6"
        border
      >
        <el-descriptions-item label="当前楼层">
          {{ dioData.DeviceData ? (
            dioData.DeviceData.textFloorAlias ? (
              JSON.parse(dioData.DeviceData.textFloorAlias).data.length > 0 ? (
                JSON.parse(dioData.DeviceData.textFloorAlias).data.find(item => item.floor == dioData.DeviceData.floor) ? 
                  JSON.parse(dioData.DeviceData.textFloorAlias).data.find(item => item.floor == dioData.DeviceData.floor).name : '无')
               : '无' ) : (
              dioData.DeviceData.floor ? dioData.DeviceData.floor : '-')
            ): '' }}
        </el-descriptions-item>
        <el-descriptions-item label="电梯状态">
          {{
            dioData.DeviceData && dioData.DeviceData.isOnline ? (
              dioData.status === 1 ? '救援' : (
                dioData.status === 2 ? '故障' : (
                  dioData.status === 3 ? '检修' : (
                    dioData.status === 4 ? '维保' : (
                      dioData.status === 5 ? '运行' : '-'
                    )
                  )
                )
              )
            ) : '-'
          }}
        </el-descriptions-item>
        <el-descriptions-item label="是否有人">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.heads) | showType('heads')}}
        </el-descriptions-item>
        <el-descriptions-item label="运行速度">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.liftSpeed) | showType('m/s')}}
        </el-descriptions-item>
        <el-descriptions-item label="运行距离">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.liftDistance) | showType('m')}}
        </el-descriptions-item>
        <el-descriptions-item label="运行次数">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.runningNum) | showType('number')}}
        </el-descriptions-item>
        <el-descriptions-item label="运行方向">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.liftSpeed) | showType('liftSpeed')}}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="dtBox3">
      <h3 style="color:#fff">电梯数字终端</h3>
      <el-descriptions
        class="margin-top"
        :column="6"
        border
      >
        <el-descriptions-item label="安全回路">{{ dioData.terminalData ? (dioData.terminalData.aq | getStatus("aq"))  : '' }}</el-descriptions-item>
        <el-descriptions-item label="厅门回路">{{ dioData.terminalData ? (dioData.terminalData.tm | getStatus("tm"))  : '-' }}</el-descriptions-item>
        <el-descriptions-item label="轿门回路">{{ dioData.terminalData ? (dioData.terminalData.jm | getStatus("jm"))  : '-' }}</el-descriptions-item>
        <el-descriptions-item label="抱闸线圈">{{ dioData.terminalData ? (dioData.terminalData.bz | getStatus("bz"))  : '-' }}</el-descriptions-item>
        <el-descriptions-item label="上限位">{{ dioData.terminalData ? (dioData.terminalData.sx | getStatus("sx"))  : '-' }}</el-descriptions-item>
        <el-descriptions-item label="下限位">{{ dioData.terminalData ? (dioData.terminalData.xx | getStatus("xx"))  : '-' }}</el-descriptions-item>

        <el-descriptions-item label="上平层">{{ dioData.terminalData ? (dioData.terminalData.sp | getStatus("sp"))  : '-' }}</el-descriptions-item>
        <el-descriptions-item label="下平层">{{ dioData.terminalData ? (dioData.terminalData.xp | getStatus("xp"))  : '-' }}</el-descriptions-item>
        <el-descriptions-item label="开门限位">{{ dioData.terminalData ? (dioData.terminalData.km | getStatus("km"))  : '-' }}</el-descriptions-item>
        <el-descriptions-item label="关门限位">{{ dioData.terminalData ? (dioData.terminalData.gm | getStatus("gm"))  : '-' }}</el-descriptions-item>
        <el-descriptions-item label="上行减速">{{ dioData.terminalData ? (dioData.terminalData.sxjs | getStatus("sxjs"))  : '-' }}</el-descriptions-item>
        <el-descriptions-item label="下行减速">{{ dioData.terminalData ? (dioData.terminalData.xxjs | getStatus("xxjs"))  : '-' }}</el-descriptions-item>

        <el-descriptions-item label="信号强度">
          {{ dioData.terminalData ? dioData.terminalData.signal : '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="心跳时间">
          {{ dioData.terminalData ? new Date(parseInt(dioData.terminalData.terminal_time)).toLocaleString() : '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="版本">{{ dioData.terminalData ? dioData.terminalData.terminalVersion : '-' }}</el-descriptions-item>
        <el-descriptions-item label="ICCID">{{ dioData.terminalData ? dioData.terminalData.terminalIcd : '-' }}</el-descriptions-item>
        <el-descriptions-item label="机房温度">{{ dioData.terminalData ? dioData.terminalData.temp : '-' }}</el-descriptions-item>
        <el-descriptions-item label="意外移动">
          {{ dioData.terminalData
            ? ( dioData.terminalData.aq ||dioData.terminalData.sp || dioData.terminalData.xp
                  ? dioData.terminalData.aq == 1 &&
                    (dioData.terminalData.sp == 1 && dioData.terminalData.xp == 1)
                    ? "异常"
                    : "正常"
                  : "未知"
                )
            : '-'
          }}
        </el-descriptions-item>

        <el-descriptions-item label="服务模式">
          {{ dioData.terminalData ? getmodel(dioData.terminalData) : '-' }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="dtBox4">
      <div style="display:flex;justify-content: space-between;align-items: center;">
        <h3 style="color:#fff">AI智能终端</h3>
        <el-button
          type="text"
          @click="query"
        >查询流量</el-button>
      </div>
      <el-descriptions
        class="margin-top"
        :column="6"
        border
      >
        <el-descriptions-item label="供电模式">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.charging) | showType('charging')}}
        </el-descriptions-item>
        <el-descriptions-item label="SD存储">
          <span v-if="dioData.activeTime">
            {{dioData.DeviceData | showTitle(dioData.DeviceData.sdTotalSize) | showType('SDMB')}}
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="SD卡可用">
          <span v-if="dioData.activeTime">
            {{dioData.DeviceData | showTitle(dioData.DeviceData.sdAvailableSize) | showType('SDMB')}}
          </span>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="今日流量">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.daySimNum) | showType('MB')}}
        </el-descriptions-item>
        <el-descriptions-item label="当月流量">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.monthSimNum) | showType('MB')}}
        </el-descriptions-item>
        <el-descriptions-item label="信号强度">
          <el-image
            v-if="dioData.DeviceData && dioData.DeviceData.netStrenth && dioData.DeviceData.netStrenth.indexOf('L') > -1"
            fit="contain"
            :src="require(`@/assets/monitor/${dioData.DeviceData.netStrenth}.png`)"
            style="width:25px;height:25px"
          />
          <span v-else>-</span>
        </el-descriptions-item>

        <el-descriptions-item label="IP地址">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.ethIp)}}
        </el-descriptions-item>
        <el-descriptions-item label="DNS">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.DNS)}}
        </el-descriptions-item>
        <el-descriptions-item label="网关">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.gateway)}}
        </el-descriptions-item>
        <el-descriptions-item label="SIM卡号">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.SIM)}}
        </el-descriptions-item>
        <el-descriptions-item label="心跳时间">
          {{ dioData.DeviceData ? (dioData.DeviceData.reportTime ? dioData.DeviceData.reportTime : '-') : '' }}
        </el-descriptions-item>
        <el-descriptions-item label="4G模块ID">
          {{dioData.DeviceData | showTitle(dioData.DeviceData.imei4g) }}
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <!-- 图表 -->
    <el-dialog
      title="流量统计"
      v-if="visible"
      :visible.sync="visible"
      append-to-body
      :close-on-click-modal="false"
      width="960px"
      top="8vh"
      @close="dialogClose"
    >
      <div style="height: 60vh;">
        <el-form
          :inline="true"
          ref="searchForm"
          :model="searchForm"
          class="demo-form-inline videoSearch"
        >
          <el-form-item>
            <span slot="label">
              <span class="lab-span">统计日期</span>
            </span>
            <el-date-picker
              v-model="searchForm.value"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              style="width:400px;margin-right:15px"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              class="searchBtn"
              @click="searchList"
            >查看</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="multipleTable"
          :data="dataList"
          height="50vh"
          border
          stripe
          fit
        >
          <el-table-column
            label="序号"
            type="index"
            width="50px"
            align="center"
          />

          <el-table-column
            prop="simDataNum"
            label="当日使用流量(M)"
            align="center"
          />
          <el-table-column
            prop="simDataTotal"
            label="当月使用流量(M)"
            align="center"
          />
          <el-table-column
            prop="createTime"
            label="统计时间"
            align="center"
          >
            <template slot-scope="scope">
              {{ getTime(scope.row.createTime) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="text-align: center"
      >
        <Pagination
          :total="total"
          :page.sync="formInline.current"
          :limit.sync="formInline.size"
          @pagination="turnPage"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import acContainer from './acContainer/index.vue' // 电梯监控 eleMonitoring
import Pagination from '@/components/Pagination'
let getDeTime
export default {
  props: ['deviceCode', 'elevatorId', 'eleInfo'],
  components: {
    acContainer,
    Pagination,
  },
  data() {
    return {
      dioData: {
        DeviceData: {
          isOnline: true,
        },
      },
      visible: false,
      // 表格参数
      searchForm: {
        value: [],
      },
      dataList: [],
      formInline: {
        current: 1,
        size: 10,
      },
      initTime: [],
      total: 0,

      ctx: null,
    }
  },
  filters: {
    getStatus(data, target) {
      let str = '-'
      if (data || data === 0) {
        switch (target) {
          case 'aq':
          case 'tm':
          case 'jm':
          case 'bz':
            str = data === 0 ? '闭合' : '断开'
            break
          case 'xx':
          case 'sx':
            str = data === 0 ? '正常' : '动作'
            break
          case 'km':
          case 'gm':
          case 'sxjs':
          case 'xxjs':
          case 'sp':
          case 'xp':
            str = data === 0 ? '待机' : '动作'
            break

          default:
            str = '无'
            break
        }
      }
      return str
    },

    showTitle(data, target) {
      let url = ''
      if (data) {
        url = '-'
        if (target || target == 0) {
          url = target
        }
      }
      return url
    },

    showType(data, target) {
      let url = '-'
      if (data !== '-') {
        switch (target) {
          case 'SDMB':
            url = data ? `${data}(M)` : 'SD卡已损坏'
            break
          case 'MB':
            url = `${data}(M)`
            break
          case 'm/s':
            url = `${data} m/s`
            break
          case 'm':
            url = `${data} m`
            break
          case 'number':
            url = `${data} 次`
            break
          case 'heads':
            url = data > 0 ? '有人' : '无人'
            break
          case 'liftSpeed':
            url =
              Number(data) > 0 ? '上行' : Number(data) === 0 ? '静止' : '下行'
            break
          case 'charging':
            url = data > 0 ? '电池供电' : data == 0 ? 'DC供电' : '-'
            break
          default:
            url = '无'
            break
        }
      }
      return url
    },
  },
  created() {
    console.log('deviceCode', this.elevatorId)
    setTimeout(() => {
      this.getFacility()
      getDeTime = setInterval(() => {
        this.getFacility()
      }, 2000)
    }, 1000)
  },
  mounted() {
    this.initTime = [
      new Date(
        new Date(new Date().toLocaleDateString()).getTime() -
          7 * 24 * 3600 * 1000
      ),
      new Date(
        new Date(new Date().toLocaleDateString()).getTime() +
          (24 * 3600 - 1) * 1000
      ),
    ]
    this.searchForm.value[0] = new Date(
      new Date(new Date().toLocaleDateString()).getTime() - 7 * 24 * 3600 * 1000
    )
    this.searchForm.value = this.initTime
    this.drawCvs()
  },
  beforeDestroy() {
    clearInterval(getDeTime)
  },
  destroyed() {
    console.log('电梯监控详情销毁')
    clearInterval(getDeTime)
  },
  methods: {
    drawCvs() {
      var mycanvas = document.getElementById('mycanvas')
      this.ctx = mycanvas.getContext('2d')
      // 找到画布的中心点
      var canvasX = mycanvas.width / 2
      var canvasY = mycanvas.width / 4
      this.paint(1.2, '#f00', '#3586ff', canvasX, canvasY)
    },

    paint(percent, startColor, endColor, canvasX, canvasY) {
      // 圆环起始位置，正下方
      let startAngle = Math.PI / 2
      // 圆环结束位置，一个整圆（Math.PI*2）乘以比例 + 起始位置
      let endAngle = Math.PI * 2 * percent + startAngle
      // 每次绘制的弧度单位，越小颜色分布越均匀，但图形较小时边缘越糙
      const unit = 0.2
      // 根据最小弧度单位计算整个圆弧可以切成多少小份
      let division = parseInt((endAngle - startAngle) / unit, 10)
      // 生成渐变色数组
      let gradient = this.gradientColor(startColor, endColor, division)

      let start = startAngle
      let end = start
      for (let i = 0; i < division; i++) {
        this.ctx.beginPath()
        this.ctx.lineCap = 'round'
        end = start + unit
        this.ctx.lineWidth = 10
        this.ctx.strokeStyle = gradient[i]
        this.ctx.arc(canvasX, canvasY, 60, start, end)
        this.ctx.stroke()
        start += unit
      }
    },

    gradientColor(startColor, endColor, step) {
      let startRGB = this.colorRgb(startColor) //转换为rgb数组模式
      let startR = startRGB[0]
      let startG = startRGB[1]
      let startB = startRGB[2]

      let endRGB = this.colorRgb(endColor)
      let endR = endRGB[0]
      let endG = endRGB[1]
      let endB = endRGB[2]

      let sR = (endR - startR) / step //总差值
      let sG = (endG - startG) / step
      let sB = (endB - startB) / step

      var colorArr = []
      for (var i = 0; i < step; i++) {
        //计算每一步的hex值
        var hex = this.colorHex(
          'rgb(' +
            parseInt(sR * i + startR) +
            ',' +
            parseInt(sG * i + startG) +
            ',' +
            parseInt(sB * i + startB) +
            ')'
        )
        colorArr.push(hex)
      }
      return colorArr
    },
    // 将hex表示方式转换为rgb表示方式(这里返回rgb数组模式)
    colorRgb(sColor) {
      var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
      var sColor = sColor.toLowerCase()
      if (sColor && reg.test(sColor)) {
        if (sColor.length === 4) {
          var sColorNew = '#'
          for (var i = 1; i < 4; i += 1) {
            sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
          }
          sColor = sColorNew
        }
        //处理六位的颜色值
        var sColorChange = []
        for (var i = 1; i < 7; i += 2) {
          sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
        }
        return sColorChange
      } else {
        return sColor
      }
    },

    // 将rgb表示方式转换为hex表示方式
    colorHex(rgb) {
      var _this = rgb
      var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
      if (/^(rgb|RGB)/.test(_this)) {
        var aColor = _this.replace(/(?:(|)|rgb|RGB)*/g, '').split(',')
        var strHex = '#'
        for (var i = 0; i < aColor.length; i++) {
          var hex = Number(aColor[i]).toString(16)
          hex = hex < 10 ? 0 + '' + hex : hex // 保证每个rgb的值为2位
          if (hex === '0') {
            hex += hex
          }
          strHex += hex
        }
        if (strHex.length !== 7) {
          strHex = _this
        }
        return strHex
      } else if (reg.test(_this)) {
        var aNum = _this.replace(/#/, '').split('')
        if (aNum.length === 6) {
          return _this
        } else if (aNum.length === 3) {
          var numHex = '#'
          for (var i = 0; i < aNum.length; i += 1) {
            numHex += aNum[i] + aNum[i]
          }
          return numHex
        }
      } else {
        return _this
      }
    },

    // 获取数据
    getFacility() {
      if (this.socketApi.isheart) {
        let agentData = {
          act: 'ma_get_ele_detail',
          id: this.elevatorId,
        }
        this.socketApi.sendSock(agentData, (e) => {
          if (e.cmd === 'ma_get_ele_detail' && e.status) {
            if (e.data) {
              this.dioData = JSON.parse(e.data)
            }
          }
        })
      }
    },
    // 转换时间
    getTime(data) {
      if (data) {
        var date = new Date(data)
        var YY = date.getFullYear() + '-'
        var MM =
          (date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1) + '-'
        var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        var hh =
          (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
        var mm =
          (date.getMinutes() < 10
            ? '0' + date.getMinutes()
            : date.getMinutes()) + ':'
        var ss =
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        return YY + MM + DD + ' ' + hh + mm + ss
      }
      return '-'
    },
    // 服务模式
    getmodel(data) {
      let str = '-'
      if (data.st && data.jx) {
        if (data.st === 1) {
          str = '锁梯'
        } else {
          if (data.jx === 1) {
            str = '检修中'
          } else {
            str = '运行中'
          }
        }
      }
      return str
    },
    // 打开流量弹框
    query() {
      console.log('查询流量')
      this.visible = true
      this.searchList()
    },
    // 翻页
    turnPage(data) {
      this.formInline.current = data.page
      this.formInline.size = data.limit
      this.searchList()
    },
    // 搜索流量
    searchList() {
      console.log(this.searchForm.value, 'this.searchForm.value')
      if (!this.searchForm.value) {
        this.$message.error('请选择起止日期')
        return
      }
      const day =
        (this.searchForm.value[1].getTime() -
          this.searchForm.value[0].getTime()) /
        86400 /
        1000
      console.log(day)
      if (Math.ceil(day) > 30) {
        this.$message.error('查询日历最短1天，最长为30天')
        return
      }
      const data = JSON.parse(JSON.stringify(this.formInline))
      data.eleId = this.elevatorId
      data.startTime = this.getTime(this.searchForm.value[0].getTime())
      data.endTime = this.getTime(this.searchForm.value[1].getTime())
      console.log(data)
      this.$http
        .post(
          `/api/ele/web/simData/getSimDataLogPage?current=${this.formInline.current}&size=${this.formInline.size}`,
          data
        )
        .then((res) => {
          console.log('获取视频列表', res.data)
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.dataList = data.records
            this.total = data.total
						this.$nextTick(() => {
              if (this.total > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
						})
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    // 关闭流量弹框
    dialogClose() {
      console.log('关闭流量弹框')
      this.searchForm.value = this.initTime
      this.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.supervisoryBox {
  width: 60%;
  height: 100%;
  margin-right: 2%;
  // background-color: #9acd32;
}

.lift-sequence {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .lift-sequenceTop {
    display: flex;
    align-items: center;
    .el-image {
      margin-left: 15px;
    }
  }
  // background-color: yellowgreen;
}

.dtBox1 {
  width: 100%;
  height: 505px;
  display: flex;
  .titlTips {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

// .dtBox2 {
//   /* width: 100%;
// 		height: 130px;
// 		background-color: yellow; */
// }

// .dtBox3 {
//   /* width: 100%;
// 		height: 260px;
// 		background-color: red; */
// }

// .dtBox4 {
//   /* width: 100%;
// 		height: 130px;
// 		background-color: yellow; */
// }
/deep/.el-descriptions-item__label.is-bordered-label {
  background: #1282a5 !important;
  text-align: center;
  width: 115px;
  font-size: 14px !important;
}
/deep/.el-descriptions-item__content {
  width: 210px;
}
/deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 12px 6px;
}
</style>
