<template>
  <el-dialog
			v-if="eleAboutDialog"
			title="关联电梯"
			:visible.sync="eleAboutDialog"
			:close-on-click-modal="false"
			width="1400px"
			top="10vh"
      @close="closeDialog" 
			>
    <div
      style="height: 67vh; overflow-y: scroll; padding-right: 20px"
      class="scrollElement"
    > 
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item>
            <span slot="label">
              <span class="lab-span">电梯名称</span>
            </span>
            <el-input v-model="formInline.name" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">救援识别码</span>
            </span>
            <el-input v-model="formInline.rescueCode" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">电梯注册代码</span>
            </span>
            <el-input v-model="formInline.code" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onRefer(true)">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
            <el-button type="primary" @click="addEle">添加电梯</el-button>
          </el-form-item>
        </el-form>
        <div v-if="total!=0">
          <el-table ref="multipleTable" :data="tableData"
            style="width: 100%;margin-bottom: 20px;" max-height="500px"
            :height="$store.state.tabHeight" >
              <el-table-column align='center' type="index" label="序号" width="50px" />
              <el-table-column align='center' prop="name" label="电梯名称" width="150px"/>
              <el-table-column align='center' prop="code" label="电梯注册代码" width="150px"/>
              <el-table-column align='center' prop="rescueCode" label="救援识别码" width="150px"/>
              <el-table-column align='center' prop="deviceCode" label="AI摄像头ID" width="150px"/>
              <el-table-column align='center' prop="terminalId" label="电梯数字终端ID" width="150px"/>
              <el-table-column align='center' prop="plotName" label="所属小区" width="150px"/>
              <el-table-column align='center' label="使用场所" width="150px">
                <template slot-scope="scope">
                  {{
                    scope.row.elePlaceType ? 
                      (elePlaceList.find(item => item.value == scope.row.elePlaceType) ?
                        elePlaceList.find(item => item.value == scope.row.elePlaceType).label : '-')
                    : '-'
                  }}
                </template>
              </el-table-column>
              <el-table-column align='center' prop="propertyUnitName" label="物业单位" width="150px"/>
              <el-table-column align='center' prop="propertyUnitUserNames" label="物业安全员" width="150px"/>
              <el-table-column align='center' prop="maintainUnitName" label="维保单位" width="150px"/>
              <el-table-column align='center' prop="maintainUserNames" label="维保人员" width="150px"/>
              <el-table-column align='center' prop="insuranceUnitName" label="保险单位" width="150px"/>
              <el-table-column align='center' prop="insuranceUnitUserNames" label="保险人员" width="150px"/>
              <el-table-column align='center' label="楼层学习">
                <template slot-scope="scope">
                  {{
                    scope.row.isInit ? '已完成' : '未完成'
                  }}
                </template>
              </el-table-column>
              <el-table-column align='center' prop="eleAddress" label="电梯安装位置" width="200px"/>
              <el-table-column align='center' prop="createTime" label="添加时间"/>
              <el-table-column align='center' prop="createUserName" label="创建人" />
              <el-table-column align="center" label="识别状态" width="120px" >
                <template slot-scope="scope">
                  {{
                    scope.row.recognitionState ? 
                      (recongtion.find(item => item.value == scope.row.recognitionState) ?
                        recongtion.find(item => item.value == scope.row.recognitionState).label : '-')
                    : '-'
                  }}
                </template>
              </el-table-column>
          </el-table>
          <Pagination ref="pageCom" :total="total" :page.sync="formInline.current"
            :limit.sync="formInline.size" @pagination="dictionarPage" />
        </div>
        <div class="nullDate" v-else>
          <img src="@/assets/nullDate.png" style="width: 260px;" />
        </div>
    </div>
    <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="dioSub">确 定</el-button>
        <el-button size="small" @click="closeDialog">取 消</el-button>
    </div>
    <AddEle
      v-if="addEleDialog"
      :eleType="eleType"
      :unitId="unitId"
      :userId="userId"
      :unitType="unitType"
      :relaredList="relaredList"
      :elePlaceList="elePlaceList"
      :recongtion="recongtion"
      :tableDataArr="eleAbout"
      @colseAddEle="colseAddEle"
    />
  </el-dialog>
</template>
<script>
import Pagination from '@/components/Pagination'
import AddEle from './addEleFrom';
export default {
  components: {
    Pagination,
    AddEle
  },
  props: ['eleType', 'unitType', 'unitId', 'userId'],
  data() {
    return {
      loading: false,
      formInline: {
        current: 1,
        size: 10
      },
      tableData: [],// 关联电梯列表
      total: 0,
      eleAboutDialog: false,
      eleAbout: [], // 勾选关联电梯
      addEleDialog: false,
      recongtion: [], // 识别状态
      elePlaceList: [], // 使用场所
      relaredList: [] // 角色
    };
  },
  filters: {},
  methods: {
    
    // 获取字典
    getNotice(code) {
    this.$http
      .post(`/api/system/web/dict/dictionary?codes=${code}`)
      .then((res) => {
        if (res.data.success) {
          const {
          data: { data },
          } = res
          this.recongtion = data.RECOGNITION_STATE
          this.elePlaceList = data.USE_PLACE
          this.relaredList = data.RELATED_CODES
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 重置按钮事件
    reset () {
      console.log('重置')
        this.formInline = {
          current: 1,
          size: 10
        }
        this.onRefer()
    },
    // 分页
    dictionarPage (data) {
      console.log('分页触发了')
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
    },
    
    // 添加绑定电梯列表
    addEle() {
      this.addEleDialog = true
    },
    // 关闭绑定电梯列表
    colseAddEle() {
      this.addEleDialog = false
      this.onRefer()
      this.onReferInit()
    },
    // 表格方法
    onRefer (ishm) {
      if (ishm) {
        this.formInline.current = 1
      }
      const data = JSON.parse(JSON.stringify(this.formInline))
      // 人员关联
      if (this.eleType == 'personType') {
        data.userId = this.userId
      } else if (this.eleType == 'otherType') {
        data.registrationAuthorityId = this.userId
      } else {
        // 单位关联
        switch (this.unitType) {
					case 'MAINTAIN_UNIT':
            data.maintainUnitId = this.unitId
						break;
					case 'TENEMENT_UNIT':
            data.propertyUnitId = this.unitId
						break;
					case 'INSURANCE_UNIT':
            data.insuranceUnitId = this.unitId
						break;
					case 'RESCUE_UNIT':
            data.emergencyRescueUnitId = this.unitId
						break;
        }
      }
      this.$http
        .post("api/ele/web/elevatorInfo/list", data)
        .then((res) => {
          console.log('列表', res.data)
          this.tableData = res.data.data.records
          this.total = res.data.data.total
          this.$nextTick(() => {
            if (this.total > 0 && this.$refs.multipleTable) {
              this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
            }
          })
        })
    },
    onReferInit() {
      const eleAboutAll = JSON.parse(JSON.stringify(this.formInline))

      console.log(this.unitId, this.eleType);
      // 人员关联
      if (this.eleType == 'personType') {
        eleAboutAll.userId = this.userId
      } else if (this.eleType == 'otherType') {
        eleAboutAll.registrationAuthorityId = this.userId
      } else {
        // 单位关联
        switch (this.unitType) {
					case 'MAINTAIN_UNIT':
            eleAboutAll.maintainUnitId = this.unitId
						break;
					case 'TENEMENT_UNIT':
            eleAboutAll.propertyUnitId = this.unitId
						break;
					case 'INSURANCE_UNIT':
            eleAboutAll.insuranceUnitId = this.unitId
						break;
					case 'RESCUE_UNIT':
            eleAboutAll.emergencyRescueUnitId = this.unitId
						break;
        }
      }
      eleAboutAll.size = 999
      this.$http
        .post("api/ele/web/elevatorInfo/list", eleAboutAll)
        .then((res) => {
          this.eleAbout = res.data.data.records ?? []
        })
    },
    // 弹出框提交
    dioSub() {
      console.log(this.eleAbout, 'eleAbout');
      this.closeDialog();
    },
    closeDialog() {
      this.eleAboutDialog = false
      this.$emit("colseEleFrom");
    }
  },
    
  created() {
    this.eleAboutDialog = true
    this.getNotice(['RECOGNITION_STATE', 'USE_PLACE', 'RELATED_CODES'])
    this.onRefer()
    this.onReferInit()
  },
  mounted() {
    this.$nextTick(() => {
    });
  },
  destroyed(){
    console.log('销毁');
  }
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBtn {
  margin-left: 15px;
}

.wid90 {
  width: 95%;
}

/deep/.el-checkbox {
  color: #fff;
}

/deep/.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #c7c7c7;
}
</style>
