<template>
  <div>
    <div style="margin-top:10px padding-left:10px">
      <span>电梯名称:{{dataInfo.name}}</span>
      <span>({{dataInfo.deviceCode}})</span>
      <el-date-picker
        style="margin-left:600px "
        value-format="timestamp"
        @change="swchange"
        v-model="value1"
        type="date"
        placeholder="选择日期"
      >
      </el-date-picker>
    </div>
    <div>
      <span>离线时长:{{logout_time}}(分钟)</span>
      <span style="margin-left:50px ">在线时长:{{login_time}}(分钟)</span>
    </div>
    <div id="myChart">
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: ['stime', 'eleId', 'dataInfo', 'onlineTime'],

  data() {
    return {
      value1: '',
      logout_time: 0,
      login_time: 0,
      switchData: [],
      loading: false,
    }
  },

  created() {
    this.value1 = this.stime * 1000
    console.log('this.stime', this.stime)
    this.switchGet()
  },
  mounted() {
    this.switchLine()
  },

  methods: {
    swchange() {
      this.stime = this.value1 / 1000
      this.switchGet()
    },

    async switchGet() {
      console.log('stime222', this.stime)
      // console.log('ele_id222',this.ele_id)
      const params = {
        startTime: this.stime * 1000,
        endTime: (this.stime + 24 * 3600 - 1) * 1000,
        eleId: this.eleId,
      }
      this.loading = true
      this.$http
        .post('api/ele/web/loginLog/getEleLoginCountDay', params)
        .then((res) => {
          console.log('列表', res.data)
          if (res.data.success) {
            this.loading = false
            this.switchData = res.data.data.records
            console.log('this.switchData', this.switchData)
            for (let i = 0; i < this.switchData.length; i++) {
              this.switchData[i][0] = Number(this.switchData[i][0]) * 1000
              this.switchData[i][1] = Number(this.switchData[i][1]) == 0 ? 1 : 0
              // if ((i + 1) % 4 === 0) {
              //   this.switchData[i].splice(1, 1, 0)
              // }
            }
            console.log('this.switchData', this.switchData)
            this.switchLine()
            const time =
              (new Date().getTime() -
                new Date(new Date().toLocaleDateString()).getTime()) /
              60000
            console.log(Math.floor(time), time, 'time')
            this.logout_time = this.onlineTime.logout_time
            this.login_time = this.onlineTime.login_time
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    switchLine() {
      // console.log('shsh222',this.shsh)
      var myCharts = echarts.init(document.getElementById('myChart'))
      // 显示标题，图例和空的坐标轴
      var mytime24 = new Array()
      var i = 0
      console.log(this.stime, 'this.stime')
      var ttt = Number(this.stime) * 1000
      for (; i < 23; i++) {
        mytime24.push([ttt]) //传入value的值为‘’则该值点不会显示在图表中
        ttt = ttt + 3927250
      }
      myCharts.setOption({
        //颜色
        color: ['#91CC75', '#fff'],
        //提示框
        tooltip: {
          trigger: 'axis',
          //修改提示框名称
          formatter: function (datas) {
            let axisValueLabel
            for (let i = 0; i < datas.length; i++) {
              if (datas[i].axisValueLabel.length > 8) {
                axisValueLabel = datas[i].axisValueLabel.slice(0, 5)
                return (datas[i].seriesName = axisValueLabel)
              }
              return (datas[i].seriesName = datas[i].axisValueLabel)
            }
          },
        },
        legend: {
          data: ['离线时间'],
          textStyle: {
            fontSize: 14, //字体大小
            color: '#ffffff', //字体颜色
          },
        },
        xAxis: {
          name: '时间',
          type: 'time',
          data: [],
          axisPointer: {
            show: true,
          },
        },

        yAxis: {
          splitLine: 'false',
          type: 'value',
          axisLine: {
            //x轴坐标轴
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            //x轴刻度线
            show: false,
          },
        },
        //只对X轴进行缩放
        dataZoom: [
          {
            id: 'dataZoomX',
            type: 'inside',
            xAxisIndex: [0],
            filterMode: 'none',
            start: 0,
            end: 100,
          },
        ],

        series: [
          {
            name: '离线时间',
            type: 'line',
            barWidth: 10,
            data: this.switchData,
            areaStyle: {
              // color:'#1386f850',
            },
          },
          {
            type: 'line',
            barWidth: 10,
            data: [[this.stime * 1000, 1.8]],
          },
          {
            name: '',
            type: 'line',
            data: mytime24,
          },
        ],
      })
    },
  },
}
</script>
<style scoped>
span {
  color: white;
}

#myChart {
  margin-top: 20px;
  width: 100%;
  height: 45vh;
}
</style>
