<template>
  <div :class="className" :style="{width:width, height:height}" />
</template>

<script>
import echarts from 'echarts'

export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '42vh'
      // default: '400px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    sosLineData: {
      type: Object,
      default() { return { createTime: [], onlineTime: [] } }
    }
  },

  data() {
    return {
      chart: null
    }
  },

  // 监听data里面对应的
  watch: {
    sosLineData: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },

  beforeDestroy() {
    if (!this.chart) return
    // 释放图表实例
    this.chart.dispose()
    this.chart = null
  },

  methods: {
    // 初始化
    initChart() {
      this.chart = echarts.init(this.$el)
      this.setOptions(this.sosLineData)
    },
    setOptions({ createTime, onlineTime } = {}) {
      // 存储this
      const that = this
      const options = {
        tooltip: {
          trigger: 'axis',
          // 在这里设置
          formatter: (params) => {
            let value = `${params[0].name}<br>`
            params.map((item, i) => {
              // 原始的数据的名称
              const targetData = JSON.parse(item.seriesName)
              // 判断是否在原始数据结构里
              const isTarget = targetData.some(target => {
                return target.name === targetData[params[0].dataIndex].name
              })
              // 时间长度大于0
              if (isTarget && params[i].value) value += `在线时长：${params[i].value}分钟<br>`
            })
            return value
          }
        },
        grid: {
          top: '18%',
          left: '40px',
          right: '30px',
          bottom: '1%',
          containLabel: true
          // borderColor: '#fff'
        },
        legend: {
          right: '30px',
          top: '1%',
          data: []
        },
        xAxis: {
          type: 'category',
          data: createTime, // 横坐标
          axisLine: {},
          axisLine: {
              lineStyle:{
                type:'solid',  
              color: '#fff'
            },
          },
          axisLabel: {
            show: true,
            interval: 'auto',
            color: '#fff'
          }
        },
        yAxis: {
          type: 'value',
          name: '在线时长(分钟)',
          show: true,
          axisTick: {
            show: false
          },
          axisLine: {
              lineStyle:{
                type:'solid',  
              color: '#fff'
            },
          },
           axisLabel: {
            show: true,
            interval: 'auto',
            color: '#fff'
          },
          triggerEvent: true // 可点击
        },
        dataZoom: [
          // 下层拖拽缩放
          {
            type: 'slider',
            xAxisIndex: 0,
            startValue: 0,
            endValue: 30, // 只显示7个数据
            bottom: -10,
            height: 8
          },
          // 图表内也可缩放
          {
            type: 'inside'
          }
        ],
        series: []
      }
      // y轴数据
      onlineTime.map((item, i) => {
        if (item.length > 0) {
          // 展示的时间
          const showTime = []
          item.map(show => {
            showTime.push(show.minutes)
          })

          const yData = {
            name: JSON.stringify(item),
            type: 'bar',
            data: showTime,
            barWidth:'20px',
            // 配置样式
            itemStyle: {
            // 通常情况下：
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0,
                  [
                    {
                      offset: 0,
                      color: '#7DA7FF' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#1470FB' // 100% 处的颜色
                    }
                  ],
                  false
                )
              }
            }
          }
          options.series.push(yData)
        }
      })
      // missionDataChart 图表容器
      that.chart.on('click', function(param) {
        that.$emit('initMap', { param: param, target: 'playBack' })
      })
      that.chart.setOption(options)
    }
  }
}
</script>
