<template>
  <div>
    <div class="searchBox">
      <el-form :inline="true" :model="dictionaryForm" class="dictionaryFormClass">
        <el-form-item label="字典名称" class="formTitle">
          <el-input
            v-model="dictionaryForm.dictValue"
            clearable
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchDictionary" class="searchBtn">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="dataList">
      <el-button type="success" size="small" @click="addDictionary">新增</el-button>
      <el-table :data="dictionarData" ref="multipleTable" border style="width: 100%; margin-top: 20px">
        <el-table-column prop="code" label="字典编号" min-width="150px"/>
        <el-table-column prop="dictValue" label="字典名称" min-width="200px" align='center'/>
        <el-table-column prop="isSealed" label="是否启用" width="120px" align='center'>
          <template slot-scope="scope" >
            <el-switch
                v-model="scope.row.isSealed"
                :active-value="0"
                :inactive-value="1"
                active-color="#409eff"
                inactive-color="#909399"
                @change="isSealedChange(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="120px" align='center'/>
        <el-table-column fixed="right" label="操作" min-width="200px" align='center'>
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="editDictionary(scope.row)">编辑</el-button>
            <!-- <el-button type="danger" size="small" @click="delDictionary(scope.row)">删除</el-button> -->
            <el-button type="warning" size="small" @click="deploykDictionary(scope.row)">字典配置</el-button >
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="dictionarTotal"
        :page.sync="dictionarQuery.current"
        :limit.sync="dictionarQuery.size"
        @pagination="dictionarPage"
      />
    </div>
    <Action ref="acitonDictionary" />
    <ChildIndex ref="ChildDictionary" :childDictionary="childDictionary"/>
  </div>
</template>

<script>
import Pagination from '../../../components/Pagination'
import Action from './components/action'
import ChildIndex from './components/childIndex'
export default {
  components: {
    Pagination,
    Action,
    ChildIndex
  },
  data () {
    return {
      dictionaryForm: {
        dictValue: ''
      },
      search: false,
      dictionarData: [],
      dictionarTotal: 0,
      dictionarQuery: {
        current: 1,
        size: 10
      },
      childDictionary: {}
    }
  },
  created () {
    this.getDictionary()
  },
  methods: {
    // 搜索词典
    searchDictionary () {
      this.dictionarQuery.current = 1
      this.search = true
      this.getDictionary()
      console.log('搜索词典')
    },
    // 新增词典
    addDictionary () {
      console.log('新增词典')
      this.$nextTick(() => {
        this.$refs.acitonDictionary.init('add', '', '', '', () => {
          console.log('新增词典')
          this.getDictionary()
        })
      })
    },
    // 修改词典
    editDictionary (row) {
      console.log('修改词典', row)
      this.$nextTick(() => {
        this.$refs.acitonDictionary.init('edit', '', '', row, () => {
          console.log('修改词典')
          this.getDictionary()
        })
      })
    },
    // 删除词典
    delDictionary (row) {
      console.log(row,'删除词典')
      // const data = {
      //   ids: String(row.id)
      // }
      this.$confirm('此操作将永久删除选中的字典, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http
        .post(`/api/system/web/dict/remove?ids=${row.id}`)
        .then((res) => {
          if (res.data.success) {
            console.log(res.data)
            if (this.dictionarData.length === 1) {
              this.dictionarQuery.current -= 1
            }
            this.getDictionary()
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg
            })
          }
        })
      }).catch(() => {});
    },
    // 词典分页
    dictionarPage (data) {
      this.dictionarQuery.current = data.page
      this.dictionarQuery.size = data.limit
      this.getDictionary()
    },
    // 是否开启
    isSealedChange (val) {
      const data = {
        id: val.id,
        parentId: 0,
        code: val.code,
        isSealed: val.isSealed
      }
      console.log(data, 'data')
      this.$http
        .post('/api/system/web/dict/submit', data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res.data)
            this.getDictionary()
          }
        })
    },
    // 词典配置
    deploykDictionary (row) {
      this.childDictionary = {
        parentId: row.id,
        parentDic: row.dictValue,
        parentCode: row.code
      }
      this.$nextTick(() => {
        this.$refs.ChildDictionary.init(() => {
          console.log('词典配置')
          this.getDictionary()
        })
      })
    },
    // 获取词典列表
    getDictionary () {
      const data = this.dictionarQuery
      if (this.search) {
        data.dictValue = this.dictionaryForm.dictValue
      }
      this.$http
        .get('/api/system/web/dict/parent-list', { params: data })
        .then((res) => {
          const { data: { data } } = res
          if (res.data.code === 200) {
            this.dictionarData = JSON.parse(JSON.stringify(data.records))
            this.dictionarTotal = data.total
            this.search = false
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          }
          console.log('res123', data)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.searchBox{
  /deep/.el-form-item__label{
    color: #00F6FF  !important;
    font-size: 14px;
  }
  .searchBtn{
    margin-left: 15px;
  }
}

</style>
