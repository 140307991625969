<template>
  <div>
    <div class="page-top">
      <el-form
        :inline="true"
        ref="searchForm"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item>
          <span slot="label">
            <span class="lab-span">规则名称</span>
          </span>
          <el-input
            v-model="searchForm.name"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer"
            >查询</el-button
          >
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button v-focus type="success" size="small" @click="addDj"
        >新增</el-button
      >
      <template v-if="tableData != 0">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="$store.state.tabHeight"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px"
        >
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column
            align="center"
            prop="name"
            label="规则名称"
            width="200px"
          />
          <el-table-column
            align="center"
            label="规则类型"
            width="150px"
          >
            <template slot-scope="scope">
              {{scope.row.takeEffectType ? (
                effectType.find(item => item.value == scope.row.takeEffectType) ?
                 effectType.find(item => item.value == scope.row.takeEffectType).label : '-'
              ) : '-'}}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="maintainItemName"
            label="维保项名称"
            width="180px"
          />
          <el-table-column
            align="center"
            prop="eleNum"
            width="120px"
            label="关联电梯数"
          />
          <el-table-column align="center" prop="demandType" label="条件方式" >
            <template slot-scope="scope">
              {{scope.row.demandType ? (
                demandType.find(item => item.value == scope.row.demandType) ?
                 demandType.find(item => item.value == scope.row.demandType).label : '-'
              ) : '-'}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="状态">
            <template slot-scope="scope">
              <el-switch
                :active-value="0"
                :inactive-value="1"
                v-model="scope.row.isActive"
                @change="changeActive(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="createTime"
            label="创建时间"
            width="150px"
          />
          <el-table-column
            fixed="right"
            align="center"
            prop="address"
            label="操作"
            width="350px"
          >
            <template slot-scope="scope">
              <el-button @click="checkItem(scope.row)" type="warning" size="small"
                >查看维保项
              </el-button>
              <el-button
                v-focus
                @click="eleInfo(scope.row)"
                type="warning"
                size="small"
                >电梯明细
              </el-button>
              <el-button
                v-focus
                @click="edit(scope.row)"
                type="primary"
                size="small"
                >编辑
              </el-button>
              <el-button
                v-focus
                @click="del(scope.row)"
                type="danger"
                size="small"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="maintenRuleTotal"
          :page.sync="searchForm.current"
          :limit.sync="searchForm.size"
          @pagination="maintenRulePage"
        />
      </template>

      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </div>

    <el-dialog
      :title="tit"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="950px"
      top="20vh"
      @close="addDialogClose"
    >
      <div
        style="height: 40vh; overflow-y: scroll; padding-right: 20px"
        class="scrollElement"
      >
        <el-form
          label-position="right"
          label-width="120px"
          ref="maintenRuleForm"
          :model="maintenRuleForm"
          :rules="formRules"
        >
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="规则名称:" prop="name">
                <el-input
                  v-model="maintenRuleForm.name"
                  placeholder="请输入"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="生效类型:" prop="takeEffectType">
                <el-select
                  v-model="maintenRuleForm.takeEffectType"
                  placeholder="请输入"
                  style="width: 100%"
                  clearable
                  @change="changeEffectType"
                >
                  <el-option
                    v-for="item in effectType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="维保类型:" prop="maintainItemName">
                <el-input
                  :placeholder="maintenRuleForm.maintainItemName || '暂无内容'"
                  v-model="maintenRuleForm.maintainItemName"
                  class="wid90"
                  disabled
                >
                  <el-button
                    slot="append"
                    type="primary"
                    @click="selectEle('item')"
                    >选择</el-button
                  >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="生效电梯:" prop="eleName">
                <el-input
                  :placeholder="maintenRuleForm.eleName || '暂无内容'"
                  v-model="maintenRuleForm.eleName"
                  class="wid90"
                  disabled
                >
                  <el-button
                    slot="append"
                    type="primary"
                    @click="selectEle('ele')"
                    >选择电梯</el-button
                  >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="条件方式:" prop="demandType">
                <el-select
                  v-model="maintenRuleForm.demandType"
                  placeholder="请输入"
                  style="width: 100%"
                  @change="changeDemandType"
                >
                  <el-option
                    v-for="item in demandType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col
              :span="24"
              style="display: flex; justify-content: space-between"
            >
              <h3 style="color: #fff">生效条件</h3>
              <el-button type="text" @click="addCondition">添加条件</el-button>
            </el-col>
          </el-row>
          <el-row
            style="margin: 10px 0"
            v-if="maintenRuleForm.takeEffectType != 1"
          >
            <template v-for="(item, index) in condition">
              <el-col :span="12" :key="index">
                <el-form-item :label="item.label" :prop="item.label">
                  <el-input
                    placeholder="请输入内容"
                    v-model="maintenRuleForm[item.label]"
                    class="input-with-select"
                    @blur="blurInput(item, maintenRuleForm[item.label])"
                  >
                    <el-button
                      slot="append"
                      type="text"
                      class="conditionFoot"
                      v-if="conditionType.includes(item.label)"
                      >次</el-button
                    >
                    <el-button
                      slot="append"
                      type="text"
                      class="conditionFoot"
                      v-if="item.label === '运行里程'"
                      >km</el-button
                    >
                    <el-button
                      slot="append"
                      type="text"
                      class="conditionFoot"
                      v-if="item.label === '运行时间'"
                      >小时</el-button
                    >
                  </el-input>
                  <el-button
                    type="text"
                    icon="el-icon-delete"
                    class="delCondition"
                    @click="delItem(item)"
                  />
                </el-form-item>
              </el-col>
            </template>
          </el-row>

          <el-row style="margin: 10px 0" v-else>
            <template v-for="(item, index) in conditionTree">
              <el-col :span="12" :key="index">
                <el-form-item label="部件名称" prop="signIn">
                  <el-input
                    disabled
                    placeholder="请输入内容"
                    v-model="item.title"
                    class="input-with-select"
                  >
                  </el-input>
                  <el-button
                    type="text"
                    icon="el-icon-delete"
                    class="delCondition"
                    @click="delTreeItem(item)"
                  />
                </el-form-item>
              </el-col>
            </template>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          :loading="loading"
          @click="dioSub"
          >确 定</el-button
        >
        <el-button size="small" @click="addDialogClose">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="tit"
      v-if="addConVisible"
      :visible.sync="addConVisible"
      :close-on-click-modal="false"
      width="950px"
      top="20vh"
    >
      <div
        style="height: 40vh; overflow-y: scroll; padding-right: 20px"
        class="scrollElement"
      >
        <el-table
          v-if="maintenRuleForm.takeEffectType != 1"
          :data="roleList"
          ref="selectroleList"
          max-height="600"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px"
          @select="handleSelectionChange"
          @select-all="selectAll"
        >
          <el-table-column
            type="selection"
            reserve-selection
            width="55px"
            align="center"
          />
          <el-table-column type="index" label="序号" width="50px" />
          <el-table-column align="center" prop="label" label="条件名称" />
          <el-table-column align="center" prop="remark" label="条件" />
        </el-table>

        <el-tree
          v-else
          class="el-tree"
          :data="treeData"
          :render-content="renderContent"
          show-checkbox
          node-key="id"
          ref="tree"
          check-strictly
          highlight-current
          @node-expand="handleExpand"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="maintenRuleForm.takeEffectType != 1"
          size="small"
          type="primary"
          :loading="loading"
          @click="addConVisible = false"
          >确 定</el-button
        >
        <el-button
          v-else
          size="small"
          type="primary"
          :loading="loading"
          @click="getTreeChecked"
          >确 定</el-button
        >
        <el-button size="small" @click="addConVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择电梯 -->
    <SelectEle
      v-if="selectEleVisible"
      ref="SelectEle"
      @transferUnit="selectEleResult"
    />
    <!-- 选择维保项 -->
    <SelectItem
      v-if="selectItemVisible"
      ref="SelectItem"
      @transferUnit="selectItemResult"
    />
    <!-- 选择维保项 -->
    <DetailWbx
      v-if="detailWbxVisible"
      ref="DetailWbx"
      @closeDetail="closeDetail"
    />
  </div>
</template>
<script>
import Pagination from "../../../components/Pagination";
import SelectEle from "./components/selectEle";
import SelectItem from "./components/selectItem";
import DetailWbx from "./components/detailWbx";
export default {
  components: {
    Pagination,
    SelectEle,
    SelectItem,
    DetailWbx
  },
  data() {
    return {
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
        name: ''
      },
      // 条件列表
      roleList: [],
      // 条件树列表
      treeData: [],
      // 条件方式
      effectType: [],
      // 生效条件
      demandType: [],
      tableData: [],
      // 分页参数
      maintenRuleTotal: 0,
      // dialog参数
      tit: "",
      dialogVisible: false,
      loading: false,

      maintenRuleForm: {
        demandType: '',
        takeEffectType: ''
      }, // 按需维保规则表单
      formRules: {
        name: [{ required: true, message: "请输入规则名称", trigger: "blur" }],
        takeEffectType: [
          { required: true, message: "请选择生效类型", trigger: "change" },
        ],
        maintainItemName: [
          { required: true, message: "请选择维保类型", trigger: "blur" },
        ],
        eleName: [
          { required: true, message: "请输入生效电梯", trigger: "blur" },
        ],
        demandType: [
          { required: true, message: "请选择条件方式", trigger: "blur" },
        ],
        运行次数: [
          { required: true, message: "请输入值", trigger: "blur" },
        ],
        运行里程: [
          { required: true, message: "请输入值", trigger: "blur" },
        ],
        运行时间: [
          { required: true, message: "请输入值", trigger: "blur" },
        ],
        震动次数: [
          { required: true, message: "请输入值", trigger: "blur" },
        ],
        开关门次数: [
          { required: true, message: "请输入值", trigger: "blur" },
        ],
        折弯次数: [
          { required: true, message: "请输入值", trigger: "blur" },
        ],
      },
      selectEleVisible: false, // 选择单位
      selectItemVisible: false, // 查看禁点
      selectData: [], // 电梯选中值
      selectItemData: [], // 维保项选中值
      eleDisabled: true, // 选择电梯按钮
      addConVisible: false, // 生效条件弹框
      condition: [], // 生效条件
      conditionType: ["运行次数", "震动次数", "开关门次数", "折弯次数"],

      treeId: [],
      conditionTree: [],
      detailWbxVisible: false, // 维保详情
    };
  },
  filters: {},
  methods: {
    // 获取字典数据
    getDictionar(code) {
      // code = code.join()
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          console.log("字典", res);
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res;
            this.effectType = data.TAKE_EFFECT_TYPE; //生效类型
            this.demandType = data.DEMAND_TYPE; //条件方式
            this.roleList = data.DEMAND_ROLE_LIST; //生效条件
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 获取树结构
    getTree() {
      this.$http.get("/api/ele/web/partsCategory/tree?status=").then((res) => {
        if (res.data.success) {
          this.treeData = this.getNewData(res.data.data);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 去除hasChildren属性
    getNewData(data) {
      data.forEach((item, index) => {
        item.index = index + 1;
        item.label = item.title;
        if (item.children && item.children.length > 0) {
          this.getNewData(item.children);
        }
      });
      return data;
    },
    // 分页
    maintenRulePage(data) {
      this.searchForm.current = data.page;
      this.searchForm.size = data.limit;
      this.onRefer();
    },

    blurInput(item, value) {
      console.log(item, 'item');
      console.log(value, 'value');
    },

    // 选择电梯
    selectEle(type) {
      if (type === "ele") {
        if (!this.maintenRuleForm.takeEffectType) {
          this.$message.error("请先选择生效类型");
          return;
        }
        this.selectEleVisible = true;
        this.$nextTick(() => {
          this.$refs.SelectEle.init(
            this.maintenRuleForm.eleIds,
            this.maintenRuleForm.takeEffectType,
            this.selectData,
            "",
            (refresh) => {
              if (refresh) {
              }
            }
          );
        });
      }
      if (type === "item") {
        this.selectItemVisible = true;
        this.$nextTick(() => {
          this.$refs.SelectItem.init(
            this.maintenRuleForm.maintainItemId,
            '',
            this.selectItemData,
            (refresh) => {
              if (refresh) {
              }
            }
          );
        });
      }
    },
    // 选择电梯返回
    selectEleResult(data) {
      console.log("选择电梯返回", data);
      const nameArr = [];
      const idArr = [];
      data.map((item) => {
        nameArr.push(item.name);
        idArr.push(item.id);
      });
      this.selectData = data;
      this.selectEleVisible = false;
      this.$set(this.maintenRuleForm, "eleName", nameArr.join());
      this.maintenRuleForm.eleIds = idArr;
    },

    // 选择维保项返回
    selectItemResult(data) {
      console.log("选择维保项返回", data);
      const nameArr = [];
      const idArr = [];
      data.map((item) => {
        nameArr.push(item.name);
        idArr.push(item.id);
      });
      this.selectItemData = data;
      this.selectItemVisible = false;
      this.$set(this.maintenRuleForm, "maintainItemName", nameArr.join());
      this.maintenRuleForm.maintainItemId = idArr.join(",");
    },

    //维保详情
    closeDetail(){
      this.detailWbxVisible = false
    },

    // 故障部件树
    handleExpand() {
      //节点被展开时触发的事件
      //因为该函数执行在renderContent函数之前，所以得加定时
      setTimeout(() => {
        this.changeCss();
      }, 10);
    },
    renderContent(h, { node, data, store }) {
      //树节点的内容区的渲染 Function
      let classname = "";
      // 由于项目中有三级菜单也有四级级菜单，就要在此做出判断
      if (node.level === 4) {
        classname = "foo";
      }
      if (node.level === 3 && node.childNodes.length === 0) {
        classname = "foo";
      }
      return h(
        "p",
        {
          class: classname,
        },
        node.label
      );
    },
    changeCss() {
      var levelName = document.getElementsByClassName("foo"); // levelname是上面的最底层节点的名字
      for (var i = 0; i < levelName.length; i++) {
        // cssFloat 兼容 ie6-8  styleFloat 兼容ie9及标准浏览器
        levelName[i].parentNode.style.cssFloat = "left"; // 最底层的节点，包括多选框和名字都让他左浮动
        levelName[i].parentNode.style.styleFloat = "left";
      }
    },
    // 状态改变
    changeActive(row) {
      this.$http.get(`/api/ele/web/maintainDemandRole/edit?id=${row.id}&isActive=${row.isActive}`).then((res) => {
        if (res.data.success) {
          this.onRefer();
        }
      });
    },
    // 关闭新增修改弹框
    addDialogClose(){
      this.dialogVisible = false
    },

    // 弹出框提交
    dioSub(type) {
      console.log(this.maintenRuleForm.takeEffectType,'this.maintenRuleForm.takeEffectType');
      console.log(this.treeId,'this.treeId');  
      if (this.maintenRuleForm.takeEffectType == 1 && this.treeId.length == 0) {
       console.log(this.treeId,'this.treeId');
        this.$message.error("请先选择生效条件");
        return;
      }
      if ( this.maintenRuleForm.takeEffectType == 2 && this.condition.length == 0) {
        console.log(this.condition,'this.condition');
        this.$message.error("请先选择生效条件");
        return;
      }
      this.loading = true;
      this.$refs.maintenRuleForm.validate(async (valid) => {
        if (valid) {
          // 故障
          if (this.maintenRuleForm.takeEffectType == 1) {
            this.maintenRuleForm.roleData = this.treeId.join(",");
          } else {
            // 运行状态
            const obj = {}
            this.condition.map((item) => {
              item.inputValue = this.maintenRuleForm[item.label];
              delete this.maintenRuleForm[item.label];
              obj[item.value] = item.inputValue
            });
            this.maintenRuleForm.roleData = JSON.stringify(obj);
          }
          this.$http
            .post(
              "/api/ele/web/maintainDemandRole/submit",
              this.maintenRuleForm
            )
            .then((res) => {
              if (res.data.success) {
                this.addDialogClose()
                this.searchForm = {
                  current: 1,
                  size: 10,
                  name: ''
                };
                this.onRefer();
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
            });
        }
      });
      this.loading = false;
    },
    // 搜索
    searchOnRefer() {
      this.searchForm.current = 1;
      this.onRefer();
    },
    // 重置
    reset() {
      console.log("重置");
      this.searchForm = {
        current: 1,
        size: 10,
        name: ''
      };
      this.onRefer();
    },

    // 获取维保规则方法
    onRefer() {
      const data = JSON.parse(JSON.stringify(this.searchForm))
      data.name = this.searchForm.name != '' ? `*${this.searchForm.name}*` : ''
      this.$http
        .post("/api/ele/web/maintainDemandRole/list", data)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res;
            this.tableData = data.records ?? [];
            this.maintenRuleTotal = data.total ?? 0;
            this.$nextTick(() => {
              if (this.maintenRuleTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //查看维保项
    checkItem(row) {
      this.detailWbxVisible = true;
      this.$nextTick(() => {
          this.$refs.DetailWbx.init(
            row.maintainItemName,
            (refresh) => {
              if (refresh) {
              }
            }
          );
        });
    },
    // 电梯明细
    eleInfo(row) {
      this.selectEleVisible = true;
      this.$nextTick(() => {
        this.$refs.SelectEle.init(
          row.eleIds.join(","),
          row.takeEffectType,
          [],
          row.id,
          (refresh) => {
            if (refresh) {
            }
          }
        );
      });
    },
    // 编辑维保规则
    edit(item) {
      console.log(item.roleData,'item');
      const {id, name, takeEffectType, demandType, roleData, maintainItemId, maintainItemName} = item 
      this.selectUnitListGet(item);
      this.tit = "编辑按需维保规则";
      this.dialogVisible = true;
      this.maintenRuleForm = {
        id, name, roleData, maintainItemId, maintainItemName
      };
      // this.$set(this.maintenRuleForm.takeEffectType, 'takeEffectType', takeEffectType)
      // this.$set(this.maintenRuleForm.takeEffectType, 'demandType', demandType)
      this.maintenRuleForm.takeEffectType = String(takeEffectType);
      this.maintenRuleForm.demandType = String(demandType);
      this.conditionTree = []
      if (item.takeEffectType == 1) {
        this.treeId = this.maintenRuleForm.roleData.split(",");
        item.partsCategoryNameList.map((item1, index) => {
          this.conditionTree.push({
            title: item1,
            id: this.treeId[index],
          });
        });
      } else {
        const arr = JSON.parse(this.maintenRuleForm.roleData)
        console.log(arr,'arr');
        this.condition = []
        for (const key in arr) {
          this.condition.push({
            value: key,
            inputValue: arr[key],
            label: this.roleList.find(item => item.value == key).label,
            remark: this.roleList.find(item => item.value == key).remark,
          })
        }
        this.condition.map((item) => {
          this.$set(this.maintenRuleForm, item.label, item.inputValue);
        });
      }
    },
    // 获取电梯名称
    selectUnitListGet(item) {
      const params = {
        id: item.id,
      };
      this.$http
        .get(
          `/api/ele/web/maintainDemandRole/getEleList?type=${item.takeEffectType}&current=1&size=999`,
          { params }
        )
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res;
            if (data.records.length > 0) {
              const nameArr = [];
              const idArr = [];
              data.records.map((item) => {
                nameArr.push(item.name);
                idArr.push(item.id);
              });
              this.$set(this.maintenRuleForm, "eleName", nameArr.join(","));
              this.$set(this.maintenRuleForm, "eleIds", idArr);
            }
          }
        });
    },
    // 删除规则
    del(item) {
      this.$confirm("此操作将永久删除选中的规则, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .delete(`/api/ele/web/maintainDemandRole/remove?ids=${item.id}`)
          .then((res) => {
            if (res.data.success) {
              this.onRefer();
            } else {
              this.$message.error(res.data.msg);
            }
          });
      });
    },
    // 生效类型改变
    changeEffectType(value) {
      this.eleDisabled = value == "";
      this.$forceUpdate();
      if (value == 1) {
        this.condition = this.roleList;
      } else {
        this.getTree();
        this.treeId = [];
        this.conditionTree = [];
      }
    },
    // 生效类型改变
    changeDemandType(value) {
      this.$forceUpdate();
    },
    // 新增按需维保
    addDj() {
      this.tit = "添加按需维保规则";
      this.dialogVisible = true;
      this.maintenRuleFormDisabled = false;
      this.maintenRuleForm.id = "";
      this.maintenRuleForm = {}
      this.condition = this.roleList
    },
    // 添加条件
    addCondition() {
      this.tit = "按需维保条件";
      if (!this.maintenRuleForm.takeEffectType) {
        this.$message.error("请先选择生效类型");
        return;
      }
      if (this.maintenRuleForm.takeEffectType != 1) {
        this.addConVisible = true;
        // 勾选选中值
        this.$nextTick(() => {
          if (this.condition.length > 0) {
            this.roleList.forEach((ele) => {
              this.condition.forEach((item) => {
                if (item.value === ele.value) {
                  this.$refs.selectroleList.toggleRowSelection(ele, true);
                }
              });
            });
          }
        });
      } else {
        this.addConVisible = true;
        // 勾选选中值
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.treeId);
        });
      }
    },
    // 全选
    selectAll(selection) {
      this.condition = selection;
    },
    // 选择
    handleSelectionChange(val, row) {
      this.condition = val;
    },
    // 获取树的值
    getTreeChecked() {
      this.treeId = this.$refs.tree.getCheckedKeys();
      let initArr = [];
      this.$refs.tree.getCheckedNodes().map((item) => {
        initArr.push({
          title: item.title,
          id: item.id,
        });
      });
      this.conditionTree = initArr;
      this.addConVisible = false;
    },
    // 删除生效条件
    delItem(row) {
      if (this.condition.length == 1) {
        this.$message.error("最少保留一个生效条件");
        return;
      }
      this.condition = this.condition.filter(
        (item) => item.label !== row.label
      );
    },
    // 删除部件
    delTreeItem(row) {
      if (this.conditionTree.length == 1) {
        this.$message.error("最少保留一个生效条件");
        return;
      }
      this.conditionTree = this.conditionTree.filter(
        (item) => item.title !== row.title
      );
      this.treeId = this.treeId.filter((item) => item !== row.id);
    },
  },
  created() {
    this.getDictionar(["DEMAND_ROLE_LIST", "TAKE_EFFECT_TYPE", "DEMAND_TYPE"]);
    this.getTree();
  },
  mounted() {
    this.$nextTick(() => {
      this.onRefer();
    });
  },
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchBtn {
  margin-left: 15px;
}
/deep/.el-checkbox {
  color: #fff;
}
/deep/.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #c7c7c7;
}
.checkbox {
  width: 65%;
  /deep/.el-checkbox__label {
    width: 100%;
  }
  .fg {
    width: 15%;
    display: inline-block;
    text-align: center;
    margin-right: 5%;
  }
}
.conditionFoot {
  padding: 12px 15px;
}
.delCondition {
  margin: 0 auto;
  width: 15%;
  color: #f00 !important;
  font-size: 18px;
}
/deep/.input-with-select {
  width: 85%;
  .el-input-group__append {
    color: white !important;
    border: 1px solid #04e3ef !important;
    background-color: #1282a5 !important;
    border-left: none !important;
    button {
      color: white !important;
    }
  }
  .el-input__inner {
    border-right: none !important;
  }
}
/deep/.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox__input > .el-checkbox__inner {
    display: none;
  }
}
</style>
