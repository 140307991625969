<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯名称</span>
					</span>
					<el-input class="einp" v-model="formInline.eleName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">维保状态</span>
					</span>
					<el-select v-model="formInline.isNext" placeholder="请输入" class="einp" clearable>
						<el-option v-for="item in NextArr" :key="item.val" :label="item.lab" :value="item.val">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯注册代码</span>
					</span>
					<el-input class="einp" v-model="formInline.eleCode" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">AI摄像头ID</span>
					</span>
					<el-input class="einp" v-model="formInline.deviceCode" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">任务状态</span>
					</span>
					<el-select v-model="formInline.isExceed" placeholder="请输入" class="einp" clearable>
						<el-option v-for="item in ExceedArr" :key="item.val" :label="item.lab" :value="item.val">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">维保单位</span>
					</span>
					<el-input class="einp" v-model="formInline.maintainUnitName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">物业单位</span>
					</span>
					<el-input class="einp" v-model="formInline.propertyUnitName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
				<span slot="label">
					<span class="lab-span">小区名称</span>
				</span>
				<el-input v-model="formInline.plotName" placeholder="请输入" clearable />
				</el-form-item>
				
				<el-form-item>
					<el-button type="primary" @click="onRefer(true)">查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div v-if="dictionarTotal!=0">
			<el-table :data="tableData" ref="multipleTable" style="width: 100%;margin-bottom: 20px;" max-height="550">
				<el-table-column fixed="left" type="index" label="序号" width="px" />
				<el-table-column align='center' prop="eleName" label="电梯名称" width="180px"/>
				<el-table-column align='center' prop="isNext" label="维保状态" />
				<el-table-column align='center' prop="isExceed" label="任务状态" />
				<el-table-column align='center' prop="lastTaskTime" label="最后一次维保日期" width="180px"/>
				<el-table-column align='center' prop="nextTaskTime" label="下一次维保日期" width="180px"/> 
				<el-table-column align='center' label="所属小区" width="180px" >
					<template slot-scope="scope">
						{{scope.row.plotName ? scope.row.plotName : '-'}}
					</template>
				</el-table-column>
				<el-table-column align='center' prop="eleCode" label="电梯注册代码" width="150px"/>
				<el-table-column align='center' prop="deviceCode" label="AI摄像头ID" width="150px"/>
				<el-table-column align='center' prop="propertyUnitName" label="物业单位" width="180px"/>
				<el-table-column align='center' prop="maintainUnitName" label="维保单位" width="180px"/>
				<el-table-column align='center' label="维保人员" width="200px">
					<template slot-scope="scope">
						<span :title="scope.row.maintainUserNames">
										{{ scope.row.maintainUserNames ? scope.row.maintainUserNames.split(';').slice(0,2).join() : '-' }}</span>
									</template>
								</el-table-column>
					</el-table-column>
			</el-table>
			<Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
				@pagination="dictionarPage" />
		</div>
		<div class="nullDate" v-else>
			<img src="@/assets/nullDate.png" style="width: 260px;" />
		</div>
	</div>
</template>
<script>
	// 页面对应的type
	import Pagination from '@/components/Pagination'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				// 地区选择器
				props: {
					label: 'name',
					value: 'areaCode',
					checkStrictly: true
				},
				// 表格参数
				formInline: {
					current: 1,
					size: 10
				},
				tableData: [],
				// 分页参数
				dictionarTotal: 0,
				fign: {},
				// 状态
				NextArr: [{
					val: 1,
					lab: '已添加'
				}, {
					val: 2,
					lab: '未添加'
				}],
				ExceedArr: [{
					val: 1,
					lab: '正常'
				}, {
					val: 2,
					lab: '超期'
				}]
			}
		},
		methods: {
			// 重置按钮事件
			reset() {
				this.formInline = {
					current: 1,
					size: 10
				}
				this.onRefer()
			},
			// 分页
			dictionarPage(data) {
				this.formInline.current = data.page
				this.formInline.size = data.limit
				this.onRefer()
			},
			// 表格方法
			onRefer(ishm) {
				if (ishm) {
					this.formInline.current = 1
				}
				this.$http
					.post("/api/ele/web/eleMaintain/getList", this.formInline)
					.then((res) => {
						if (res.data.success) {
							// res.data.data.list.forEach((qs)=>{
							// 	qs.isNextLab = this.getType(qs.isNext, 1)
							// 	qs.isExceedLab = this.getType(qs.isExceed, 2)
							// })
							
							this.tableData = res.data.data.list
							this.dictionarTotal = res.data.data.total
							this.$nextTick(() => {
								if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
		},
		created() {
			this.onRefer()
		}
	}
</script>
<style scoped>
	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-top {
		width: 100%;
		margin-bottom: 20px;
	}

	.page-bom {}
</style>
