<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">用户名称</span>
					</span>
					<el-input v-model="formInline.name" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">登录名称</span>
					</span>
					<el-input v-model="formInline.userName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">状态</span>
					</span>
					<el-select v-model="formInline.isActive" placeholder="请输入">
						<el-option label="全部" value="null"></el-option>
						<el-option label="启用" :value="1"></el-option>
						<el-option label="未启用" :value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">角色</span>
					</span>
					<el-select v-model="formInline.roleId" placeholder="请输入" clearable>
						<el-option v-for="item in roleData" :key="item.id" :label="item.roleName" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onRefer(true)">查询</el-button>
				</el-form-item>
			</el-form>
			<el-button v-focus type="success" @click="addDj" size="small">新增</el-button>
		</div>
		<div>
			<el-table :data="tableData" ref="multipleTable" style="width: 100%;margin-bottom: 20px;" :height="$store.state.tabHeight">
				<el-table-column type="index" label="序号" width="50px">
				</el-table-column>
				<el-table-column align='center' prop="name" label="用户名称">
				</el-table-column>
				<el-table-column align='center' prop="userName" label="登录名">
				</el-table-column>
				<!-- <el-table-column align='center' prop="password" label="登陆密码">
				</el-table-column> -->
				<el-table-column align='center' prop="memo" label="备注">
				</el-table-column>
				<el-table-column align='center' prop="roleNames" label="角色">
				</el-table-column>
				<el-table-column align='center' prop="address" label="是否启用">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.isActive" active-color="#409eff" inactive-color="#909399"
							:active-value="1" :inactive-value="0" @change='swChange(scope.row)'>
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column align='center' prop="manageAreaCode" label="管理区域">
					<template slot-scope="scope">
						<!-- {{getAreaCodeName(scope.row.manageAreaCode)}} -->
						{{scope.row.manageAreaCodeNames}}
					</template>
				</el-table-column>
				<el-table-column align='center' prop="permissionName" label="数据查询权限">
				</el-table-column>
				<el-table-column align='center' prop="areaNames" label="数据查询区域">
				</el-table-column>
				<el-table-column align='center' prop="createUserName" label="创建人">
				</el-table-column>
				<el-table-column align='center' prop="platformName" label="平台名称">
				</el-table-column>
				<el-table-column align='center' prop="updateTime" label="更新时间">
				</el-table-column>
				<el-table-column fixed="right" align='center' prop="address" label="操作">
					<template slot-scope="scope">
						<el-button v-focus @click="upd(scope.row)" type="primary" size="small">编辑
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
				@pagination="dictionarPage" />
		</div>
		<el-dialog v-if='dialogVisible' :title="tit" :visible.sync="dialogVisible" :close-on-click-modal='false'
			width="1150px" top='20vh'>
			<div style="height: 30vh;overflow-y: scroll;" class="scrollElement">
				<el-form :rules="rules" ref="ruleForm" label-position="right" label-width="120px" :model="fign">
					<el-row style="margin:10px 0">
						<el-col :span="12">
							<el-form-item label="用户名称:" prop="name">
								<el-input v-model="fign.name" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="登录名:" prop="userName">
								<el-input v-model="fign.userName" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin:10px 0">
						<el-col :span="12">
							<el-form-item label="登录密码:" prop="password" :rules="tit == '新增' ? rules.password : editRules.password">
								<el-input maxlength="24" v-model="fign.password" placeholder="请输入"
									class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机号码:" prop="phone">
								<el-input v-model="fign.phone" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin:10px 0">
						<el-col :span="12">
							<!-- <el-form-item label="管理区域:" prop="manageAreaCode">
								<el-cascader
								class="einp"
								:disabled="tit=='修改'&&adminInfo&&adminInfo.roleId!='1'"
								v-model="manageAreaCode"
								placeholder="请选择"
								:props="{
									label: 'name',
									value: 'areaCode',
									checkStrictly: true,
								}"
								:options="manageAreaCodeOptions"
								filterable></el-cascader>
							</el-form-item> -->
							<el-form-item label="管理区域:" prop="manageAreaCode">
								<el-cascader
								class="einp"
								:disabled="tit=='修改'&&adminInfo&&adminInfo.roleId!='1'"
								v-model="manageAreaCode"
								placeholder="请选择"
								:props="{
									label: 'name',
									value: 'areaCode',
									checkStrictly: true,
								}"
								:options="manageAreaCodeOptions"
								filterable></el-cascader>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="下级管理区域:" prop="transregional">
								<el-select v-model="fign.transregional" :disabled="adminInfo&&adminInfo.roleId!='1'" placeholder="请选择" class="einp">
									<el-option label="只能同级下级" :disabled="transregionalDisabled" :value="0"></el-option>
									<el-option label="可以跨级" :value="1"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin:10px 0">
						<el-col :span="12">
							<el-form-item label="备注:">
								<el-input v-model="fign.memo" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="角色类型:" prop="roleId">
								<el-select v-model="fign.roleId" placeholder="请选择" class="einp">
									<el-option v-for="item in roleData" :key="item.id" :label="item.roleName"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12" style="margin:5px 0">
							<el-form-item label="关联单位:">
								<el-select
									v-model="fign.orgId"
									multiple
									filterable
									clearable
									placeholder="请选择"
									class="einp"
									:multiple-limit="10"
									@change="orgIdChange">
									<el-option v-for="item in orgIdData" :key="item.id" :label="item.name"
										:value="`${item.type}-${item.id}`">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12" v-if="fign.createUserName!='超级管理员'" style="margin:5px 0">
							<el-form-item label="数据权限:">
								<el-select v-model="fign.jdType" placeholder="请选择" class="einp">
									<el-option label="默认" :value="0"></el-option>
									<el-option label="本账号所创建" :value="1"></el-option>
									<el-option label="本账号与下属" :value="2"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<!-- <el-row style="margin:5px 0">
						<el-col :span="12">
							<el-form-item label="额外数据权限:">
								<el-radio v-model="fign.radio" label="1" style="color: white;">只读</el-radio>
								<el-radio v-model="fign.radio" label="2" style="color: white;">可操作</el-radio>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="下级创建数据:">
								<el-radio v-model="fign.radio" label="1" style="color: white;">备选项</el-radio>
								<el-radio v-model="fign.radio" label="2" style="color: white;">备选项</el-radio>
							</el-form-item>
						</el-col>
					</el-row> -->
					<el-row style="margin:5px 0">
						<el-col :span="12" style="margin:5px 0">
							<el-form-item label="是否启用:">
								<el-select v-model="fign.isActive" placeholder="请选择" class="einp">
									<el-option label="启用" :value="1"></el-option>
									<el-option label="关闭" :value="0"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12" style="margin:5px 0">
							<el-form-item label="平台名称:" prop="platformName">
								<el-input v-model="fign.platformName" placeholder="请输入" class="einp">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin:10px 0" v-if="fign.createUserName!='超级管理员'">
						<el-col :span="12" v-show="fign.jdType!=0">
							<el-form-item label="数据查询区域:">
								<el-cascader :props="props" :options="$mapCode" v-model="fign.dataArea" class="einp"
									collapse-tags clearable>
								</el-cascader>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12" style="margin:5px 0" v-if="fign.roleId && roleData.find(item => item.id == fign.roleId).roleName == '集团物业'">
							<el-form-item label="关联品牌:">
								<el-select v-model="fign.brand" multiple filterable clearable placeholder="请选择" class="einp">
									<!-- <el-option v-for="item in brandList" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option> -->
									<el-option v-for="item in brandList" :key="item.uniqueCode" :label="item.brand"
										:value="item.uniqueCode">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row style="margin:10px 0">
						<el-col :span="12">
							<el-form-item label="平台logo:">
								<FileUpload :md5s.sync="fign.logoUrl" :size="400"
									:multiple="false" :limit="1" :url="url" />
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" type="primary" @click="dioSub">确 定</el-button>
				<el-button size="small" @click="dialogVisible = false;setManageAreaCode(fign.manageAreaCode)">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	var validatePass = (rule, value, callback) => {
		inpPwd(rule, value, callback)
	}
	var validateEditPass = (rule, value, callback) => {
		inputApp(
			rule,
			value,
			false,
			/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%&^*()_——\-+=<>;？?:""{}|「」,.\\/'\[\]·！￥（）《》？：“”【】、；‘’，。]).{8,16}$/,
			"密码长度为：8-16位，且必须包含大小写字母，数字与特殊符号",
			callback
		);
	}
	var validatephone = (rule, value, callback) => {
		inpPhone(rule, value, callback)
	}
	var validateName = (rule, value, callback) => {
		inpName(rule, value, callback)
	}
	var validateUname = (rule, value, callback) => {
		inpUname(rule, value, callback)
	}

	import cloneDeep from 'lodash/cloneDeep'
	import Pagination from '@/components/Pagination'
	import FileUpload from '@/components/FileUpload'
	export default {
		components: {
			Pagination,
			FileUpload
		},
		data() {
			let _this = this
			return {
				orgIdData: [],
				// 地区选择器
				props: {
					multiple: true,
					label: 'name',
					value: 'areaCode',
					checkStrictly: true,
				},
				// 表格参数
				formInline: {
					current: 1,
					size: 10,
				},
				tableData: [],
				// 分页参数
				dictionarTotal: 0,
				// dialog参数
				tit: '',
				dialogVisible: false,
				fign: {},
				// 角色下拉框数据
				roleData: [],

				brandList: [],
				// 表单限制
				rules: {
					name: [{
						required: true,
						validator: validateName,
						trigger: 'blur',
					}, ],
					userName: [{
						required: true,
						validator: validateUname,
						trigger: 'blur',
					}, ],
					roleId: [{
						required: true,
						message: '请选择角色类型',
						trigger: 'change',
					}, ],
					password: [{
						required: true,
						validator: validatePass,
						trigger: 'change',
					}, ],
					phone: [{
						required: true,
						validator: validatephone,
						trigger: 'blur',
					}, ],
					manageAreaCode: [{
						required: true,
						// message: '请选择管理区域',
						validator: this.validateManageAreaCode,
						trigger: 'blur',
					}],
					transregional: [{
						required: true,
						// message: '请选择下级管理区域',
						validator: this.validateTransregional,
						trigger: 'blur',
					}],
					platformName: [{
						required: false,
						min: 2,
						max: 20,
						message: '请输入长度2-20，支持输入中文，大小写字母，数值，特殊符号的平台名称',
						trigger: 'blur',
					}],
				},
				editRules: {
					password: [{
						required: false,
						validator: validateEditPass,
						trigger: 'change'
					}]
				},
				jdType: null, // 角色类型
				dataArea: null,
				againBtn: true,

				adminInfo:null,
				manageAreaCode:[],
				manageAreaCodeOptionsCopy:[
					{
						name:'全国',
						areaCode:'',
						children:cloneDeep(this.$mapCode),
					}
				],
				url: '/api/file/web/upload', // 上传地址
			}
		},
		watch:{
			'fign.manageAreaCode':{
				handler: function (arr){
					this.setManageAreaCode(arr)
				}
			}
		},
		computed:{
			manageAreaCodeOptions(){
				let arr=[
					{
						name:'全国',
						areaCode:'',
						children:cloneDeep(this.$mapCode),
					}
				]
				let manageAreaCode= this.toManageAreaCode(this.fign.manageAreaCode)
				let code=manageAreaCode[manageAreaCode.length-1]
				// this.getManageAreaCode(code,arr,0,this.fign.transregional,this.adminInfo&&this.adminInfo.roleId)
				this.getManageAreaCode(manageAreaCode,arr,0,this.fign.transregional,this.adminInfo&&this.adminInfo.roleId)
				return arr
			},
			transregionalDisabled(){
				let manageAreaCode=this.toManageAreaCode(this.fign.manageAreaCode) 
				manageAreaCode=manageAreaCode[manageAreaCode.length-1]
				let manageAreaCodeIndex=this.manageAreaCode.indexOf(manageAreaCode)
				return this.manageAreaCode.length-manageAreaCodeIndex>2
			}
		},
		methods: {
			validateManageAreaCode(rule, value, callback){
				callback()
			},
			toManageAreaCode(arr){
				arr=arr?JSON.parse(arr):arr
				let manageAreaCode=(!arr||!arr[0])?[]:arr[0]
				return ['',...manageAreaCode]
			},
			setManageAreaCode(arr){
				this.manageAreaCode=this.toManageAreaCode(arr)
			},
			getManageAreaCode(codes,arr,arrLevel,transregional,roleId,codeLevel=codes.length){
				arr.map(item=>{
					if(roleId=='1'){
						// item.disabled=item.areaCode!=''
					}else{
						item.disabled=true
						
						if(item.areaCode==codes[arrLevel]){
							//codes最后一个的children完整保留
							if(codeLevel-1==arrLevel){
								item.children=item.children
							//codes最后一个前的code对应的children过滤
							}else if(codeLevel-1>arrLevel){
								item.children=item.children.filter(item=>item.areaCode==codes[arrLevel+1])
							}
						//下n级保留完整children并开启选用
						}else if(arrLevel>=codeLevel&&transregional==1){
							item.disabled=false
						}else{
							item.children=null
							item.disabled=true
						}
						//同级或下级开启选用
						if(arrLevel==codeLevel-1||arrLevel==codeLevel){
							item.disabled=false
						}
					}
					if(item.children&&item.children.length>0){
						this.getManageAreaCode(codes,item.children,arrLevel+1,transregional,roleId,codeLevel)
					}
				})
			},
			getAreaCodeName(codes){
				codes=this.toManageAreaCode(codes)
				let val=codes.map(code=>{
					let val=this.getCodeName(code,this.manageAreaCodeOptionsCopy)
					return val
				})
				return val.join(',')
			},
			getCodeName(code,arr){
                let children=[]
				for (let i=0;i<arr.length;i++){
					children=children.concat(arr[i].children?arr[i].children:[])
					if(arr[i].areaCode==code){
						return arr[i].name
					}
				}
				return this.getCodeName(code,children)				
			},


			// 获取关联单位
			getori(id) {
				let data = {
					current: 1,
					size: 10000,
					type: 'TENEMENT_UNIT,MAINTAIN_UNIT,RESCUE_UNIT,INSURANCE_UNIT,INSTALL_UNIT,VENDOR_UNIT,STREET_UNIT',
				}
				if (id) {
					data.orId = id
				}
				this.$http.post('/api/system/web/org/list', data).then((res) => {
					if (res.data.success) {
						this.orgIdData = res.data.data.records
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},
			// 分页
			dictionarPage(data) {
				this.formInline.current = data.page
				this.formInline.size = data.limit
				this.onRefer()
			},
			// 弹出框提交
			dioSub() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						let manageAreaCode=this.manageAreaCode.slice(1,this.manageAreaCode.length)
						manageAreaCode=manageAreaCode.length>0?[manageAreaCode]:manageAreaCode
						let data = JSON.parse(JSON.stringify({...this.fign,manageAreaCode: JSON.stringify(manageAreaCode)}))
						let url = '/api/system/web/user/edit'
						if (this.tit == '新增') {
							this.fign.id = null
							this.againBtn = false
							url = '/api/system/web/user/add'
						} else {
							this.againBtn = this.jdType !== data.jdType || this.dataArea.toString() !== data
								.dataArea.toString()
						}
						let t = data.jdType
						if (data.dataArea != undefined) {
							if (data.dataArea.length != 0) {
								let arr = deMapCode(data.dataArea)
								data.dataArea = JSON.stringify(arr)
								// 只有此页面需要 设置账号权限
								if (t == 0) {
									t = 0
								} else if (t == 1) {
									t = 3
								} else if (t == 2) {
									t = 4
								}
							} else {
								data.dataArea = '[]'
							}
						}
						
						data.brand = this.fign.brand ? this.fign.brand.join(',') : ''
						data.dataPermission = t
						if (this.againBtn) {
							this.$confirm(
									'如果修改数据权限或数据查询区域，可能会造成该用户数据错乱的情况，请谨慎修改',
									'提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning',
									}
								)
								.then(() => {
									this.$http.post(url, data).then((res) => {
										if (res.data.success) {
											this.onRefer()
											this.dialogVisible = false
											this.$message.success(res.data.msg)
										} else {
											this.$message.error(res.data.msg)
										}
									})
								})
								.catch(() => {
									this.fign.jdType = this.jdType
									this.fign.dataArea = this.dataArea
									this.$message.error('已取消权限修改')
								})
						} else {
							data.orgId = this.fign.orgId.length > 0 ? this.fign.orgId.join(',') : ''
							this.$http.post(url, data).then((res) => {
								if (res.data.success) {
									this.onRefer()
									this.dialogVisible = false
									this.$message.success(res.data.msg)
								} else {
									this.$message.error(res.data.msg)
								}
							})
						}
					} else {
						this.$message.warning('请验证填写内容!')
						return false
					}
				})
			},

			// 表格方法
			swChange(item) {
				let url = '/api/system/web/user/isActiveUser'
				let data = {
					id: item.id,
					isActive: item.isActive,
				}
				this.$http.post(url, data).then((res) => {
					if (res.data.success) {
						this.onRefer()
						this.$message.success(res.data.msg)
					} else {
						this.$set(item, 'isActive', item.isActive == 0 ? 1 : 0)
						this.$message.error(res.data.msg)
					}
				})
			},
			onRefer(ishm) {
				if (ishm) {
					this.formInline.current = 1
				}
				this.$http
					.post('/api/system/web/user/getList', this.formInline)
					.then((res) => {
						if (res.data.success) {
							this.tableData = res.data.data.records
							this.dictionarTotal = res.data.data.total
							this.$nextTick(() => {
								if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
			upd(item) {
				this.tit = '修改'
				this.dialogVisible = true

				this.getori(item.orgId)
				console.log(item,'item');
				// let manageAreaCode=item.manageAreaCode?item.manageAreaCode:['']
				// item.manageAreaCode= manageAreaCode[0]?manageAreaCode[0]:['']
				this.fign = JSON.parse(JSON.stringify(item))
				this.fign.orgId = item.orgId ? item.orgId.split(',') : []
				console.log(this.fign);
				this.fign.brand = item.brand ? item.brand.split(',') : ''
				let t = this.fign.dataPermission
				if (t == null) {
					t = 0
				} else if (t == 3) {
					t = 1
				} else if (t == 4) {
					t = 2
				}
				this.jdType = t
				this.$set(this.fign, 'jdType', t)
				let codes = JSON.parse(this.fign.dataArea)

				this.dataArea = codes
				this.$set(this.fign, 'dataArea', codes)
			},
			addDj() {
				this.tit = '新增'
				this.fign = {
					jdType: 0,
					isActive: 1,
					// 后续需要删除 数据读取权限字段
					onlyReadType: 0,
					// manageAreaCode:this.adminInfo&&this.adminInfo.manageAreaCode?this.adminInfo.manageAreaCode:[''],
					manageAreaCode:this.adminInfo.manageAreaCode,
					transregional:this.adminInfo?this.adminInfo.transregional:0
				}
				this.dialogVisible = true
				this.getori()
			},
			// 获取角色列表
			getrole() {
				let data = {
					size: 100,
					current: 1,
					isActive: 1,
				}
				this.$http.post('/api/system/web/role/getList', data).then((res) => {
					if (res.data.success) {
						this.roleData = res.data.data.records
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},

			// 关联单位
			orgIdChange (value) {
				if (value.length > 9) {
					this.$message.error('最多关联10个单位')
				}
			},
			getBrand() {
				const data = {
					current: 1,
					size: 999,
				}
				this.$http.post(`/api/system/web/brand/getBrandPage`, data)
					.then((res) => {
					if (res.data.success) {
						const { data: { data }} = res
						this.brandList = data.records
					} else {
						this.$message.error(res.data.msg)
					}
				})
			}
		},
		created() {
			this.getrole()
			this.onRefer()
    		this.getBrand()
			this.adminInfo=JSON.parse(sessionStorage.getItem('adminInfo'))
		},
	}
</script>
<style scoped>
	.page-top {
		width: 100%;
		margin-bottom: 20px;
	}

	.page-bom {}
</style>
