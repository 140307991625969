import { render, staticRenderFns } from "./locusCharts.vue?vue&type=template&id=280d4a90&scoped=true"
import script from "./locusCharts.vue?vue&type=script&lang=js"
export * from "./locusCharts.vue?vue&type=script&lang=js"
import style0 from "./locusCharts.vue?vue&type=style&index=0&id=280d4a90&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "280d4a90",
  null
  
)

export default component.exports