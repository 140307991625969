<template>
  <div>
    <div class="page-top">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <span slot="label">
            <span class="lab-span">人员名称</span>
          </span>
          <el-input v-model="searchForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">角色</span>
          </span>
          <el-select v-model="searchForm.type" placeholder="请输入" clearable>
            <el-option
              v-for="(item, index) in personnelTypeSearch"
              :key="index"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <span slot="label">
            <span class="lab-span">所属单位</span>
          </span>
          <el-input
            v-model="searchForm.orgName"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="searchBtn" @click="searchOnRefer"
            >查询</el-button
          >
          <el-button type="warning" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-button v-focus type="warning" @click="dowMode(1)" size="small"
        >下载模版</el-button
      >
      <!-- <el-button v-focus type="danger" size="small" @click="batchDel"
        >批量删除</el-button
      > -->
      <el-button v-focus type="success" size="small" @click="addDj"
        >新增</el-button
      >
      <form
        v-focus
        name="form1"
        style="width: 56px; margin: 0 10px; display: inline-block"
      >
        <input
          type="file"
          id="docxFile"
          @change="readFile($event)"
          style="position: absolute; clip: rect(0 0 0 0)"
        />
        <el-button type="primary" @click="toLead" size="small">导入</el-button>
      </form>
      <template v-if="personnelTotal != 0">
        <el-table
          ref="multipleTable"
          :data="tableData"
          style="width: 100%; margin-top: 20px; margin-bottom: 20px"
          @selection-change="handleSelectionChange"
          :height="$store.state.tabHeight"
        >
          <el-table-column type="selection" width="55px" />
          <el-table-column type="index" label="序号" />
          <el-table-column align="center" prop="name" label="人员名称" />
          <el-table-column align="center" prop="orgName" label="所属单位" />
          <el-table-column
            align="center"
            prop="fileUrl"
            label="职位"
            width="150px"
          >
            <template slot-scope="scope">
              {{getPosition(scope.row.position)}}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="type"
            label="角色"
            width="250px"
          >
            <template slot-scope="scope">
              <template
                v-if="scope.row.type && scope.row.type.indexOf(',') > -1"
              >
                <span v-for="item in scope.row.type.split(',')" :key="item">
                  {{
                    personnelType.find((item1) => item1.value === item)
                      ? personnelType.find((item1) => item1.value === item)
                          .label
                      : ""
                  }}
                </span>
              </template>
              <template v-else>
                {{
                  personnelType.find((item1) => item1.value === scope.row.type)
                    ? personnelType.find(
                        (item1) => item1.value === scope.row.type
                      ).label
                    : ""
                }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="fileUrl"
            label="是否允许登录"
            width="150px"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isLogin"
                :active-value="0"
                :inactive-value="1"
                active-color="#409eff"
                inactive-color="#909399"
                @change="isSealedChange(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="contactWay" label="联系方式" />
          <el-table-column align="center" prop="account" label="app账号" />
          <!-- <el-table-column
            align="center"
            prop="oriPassword"
            label="app账号密码"
          /> -->
          <el-table-column
            align="center"
            prop="certificateCode"
            label="证书编号"
          />
          <el-table-column
            align="center"
            prop="createTime"
            label="添加时间"
            width="200px"
          />
          <el-table-column
            align="center"
            prop="createUserName"
            label="创建人"
          />
          <el-table-column
            fixed="right"
            align="center"
            prop="address"
            label="操作"
            width="350px"
          >
            <template slot-scope="scope">
              
              <el-button
                v-focus
                @click="change(scope.row)"
                type="primary"
                size="small"
                >更换单位</el-button
              >
              <el-button
                v-focus
                type="warning"
                size="small"
                @click="aboutEle(scope.row)"
                >关联电梯
              </el-button>
              <el-button
                v-focus
                @click="edit(scope.row)"
                type="warning"
                size="small"
                >编辑</el-button
              >
              <el-button
                v-focus
                @click="del(scope.row.id)"
                type="danger"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="personnelTotal"
          :page.sync="searchForm.current"
          :limit.sync="searchForm.size"
          @pagination="personnelPage"
        />
      </template>

      <div class="nullDate" v-else>
        <img src="@/assets/nullDate.png" style="width: 260px" />
      </div>
    </div>

    <el-dialog
      v-if="dialogVisible"
      :title="tit"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="950px"
      top="20vh"
    >
      <PersonAction
        :tit="tit"
        :useType="useType"
        :personnelForm="personnelForm"
        @changeUseType="changeUseType"
        @subForm="subForm"
        @colseForm="colseForm"
      />
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
    <ChangeUnit
      ref="changeUnit"
      :personnelData="personnelData"
      :unitList="unitChangeList"
      :changeUnitType="changeUnitType"
      @getUnitInfo="getUnitInfo"
    />
    <!-- 错误信息列表 -->
    <ErrorDio
      v-if="errorList != null"
      :errorLists="errorList"
      @dioClose="dioCloseB"
    />
    <!-- 关联电梯 -->
    <EleFrom
      v-if="eleAboutDialog"
      :eleType="'personType'"
      :userId="userId"
      :unitId="unitId"
      :unitType="unitType"
      @colseEleFrom="colseEleFrom"
    />
    <AboutEleTable
      v-if="personVisible"
      ref="personTable"
      :personList="personList"
      :personListTotal="personListTotal"
      :elePlaceList="elePlaceList"
      @changePage="changePage"
      @colseEleTable="colseEleTable"
    />
  </div>
</template>
<script>
const pageType = "EMPLOYEE_MANAGE";
import Pagination from "../../../components/Pagination";
import ChangeUnit from "./components/changeUnit";
import PersonAction from "./components/personAction";
import ErrorDio from "@/components/errorDio";
import EleFrom from '../components/eleFrom';
import AboutEleTable from './components/aboutEleTable';
export default {
  components: {
    Pagination,
    ChangeUnit,
    ErrorDio,
    PersonAction,
    EleFrom,
    AboutEleTable
  },
  data() {
    var validateName = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^[\u4e00-\u9fa5_a-zA-Z0-9]{2,6}$/,
        "人员名称可输入中文，字母，数值且长度为2-6!",
        callback
      );
    };
    var validatePhone = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^[0-9]{11}$/,
        "联系方式输入数值且长度为11位!",
        callback
      );
    };
    var validateAccount = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^[a-zA-Z0-9]{4,9}$/,
        "app账号可输入数值，字母且长度为4-9!",
        callback
      );
    };
    var validateoriPwd = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        true,
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%&^*()_——\-+=<>;？?:""{}|「」,.\\/'\[\]·！￥（）《》？：“”【】、；‘’，。]).{8,16}$/,
        "密码长度为：8-16位，且必须包含大小写字母，数字与特殊符号",
        callback
      );
    };
    var validateCode = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        false,
        /^[a-zA-Z0-9]{6,24}$/,
        "证书编号输入字母，数值且长度为6至24个长度!",
        callback
      );
    };
    return {
      // 表格参数
      searchForm: {
        current: 1,
        size: 10,
        type: "",
      },
      // 人员类型
      personnelType: [],
      personnelTypeSearch: [],
      useType: [],
      orgType: "",
      // 单位类型
      unitList: [],
      unitChangeList: [],
      tableData: [],
      // 分页参数
      personnelTotal: 0,
      // 错误信息列表
      errorList: null,
      // dialog参数
      tit: "",
      dialogVisible: false,
      loading: false,
      selectRow: [], // 选中人员
      personnelData: {}, // 人员数据
      personnelForm: {
        unitName: "",
      },
      formRules: {
        name: [
          {
            required: true,
            validator: validateName,
            trigger: "blur",
          },
        ],
        contactWay: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            // validator: validateAccount,
            validator: this.changeAccount,
            trigger: "blur",
          },
        ],
        oriPassword: [
          {
            required: true,
            validator: validateoriPwd,
            trigger: "blur",
          },
        ],
        certificateCode: [
          {
            validator: validateCode,
            trigger: "blur",
          },
        ],
        isLogin: [
          {
            required: true,
            message: "这里不能为空",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "这里不能为空",
            trigger: "blur",
          },
        ],
      },

      changeUnitType: "", // 进入单位选择类型
      eleAboutDialog: false, // 关联电梯弹框

      userId: '',
      unitId: '',
      unitType: '',


      personVisible: false,
      personId: '',
      personList: [],
      personListTotal: 0,
      elePlaceList: []
    };
  },
  // computed: {
  // },
  filters: {},
  methods: {
    // 获取字典数据
    getDictionar(code) {
      // code = code.join()
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          if (res.data.code === 200) {
            const {
              data: { data },
            } = res;

            this.personnelType = [...data.RELATED_CODES];
            this.personnelTypeSearch = [
              ...[
                {
                  label: "全部",
                  value: "",
                },
              ],
              ...data.RELATED_CODES,
            ];
            data.UNIT_LIST.forEach((element) => {});
            this.unitList = data.UNIT_LIST;
            this.elePlaceList = data.USE_PLACE
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    
    getPosition: function (type) {
      let positionType = '-'
      switch(type){
        case 'SAFETY_OFFICER':
        positionType = '安全员'
        break
        
        case 'SAFETY_DIRECTOR':
        positionType = '安全总监'
        break
        
        case 'PERSON_IN_CHARGE':
        positionType = '主要负责人'
        break
      }

      return positionType
    },
    // 分页
    personnelPage(data) {
      this.searchForm.current = data.page;
      this.searchForm.size = data.limit;
      this.onRefer();
    },
    changeUseType(data) {
      this.useType = data;
    },
    // 弹出框提交
    subForm(data) {
      this.personnelForm = data;
      this.$http
        .post("/api/system/web/appUser/submit", this.personnelForm)
        .then((res) => {
          if (res.data.success) {
            this.onRefer();
            this.dialogVisible = false;
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },

    colseForm() {
      this.dialogVisible = false;
    },
    // 搜索
    searchOnRefer() {
      this.searchForm.current = 1;
      this.onRefer();
    },
    // 重置
    reset() {
      console.log("重置");
      this.searchForm = {
        current: 1,
        size: 10,
      };
      this.onRefer();
    },
    // 是否开启
    isSealedChange(val) {
      const data = {
        id: val.id,
        type: val.type,
        name: val.name,
        contactWay: val.contactWay,
        account: val.account,
        oriPassword: val.oriPassword,
        certificateCode: val.certificateCode,
        isLogin: val.isLogin,
      };
      this.$http.post("/api/system/web/appUser/submit", data).then((res) => {
        if (res.data.code === 200) {
          this.onRefer();
        }
      });
    },
    // 改变启用账号
    changeLogin(val) {
      this.$set(this.personnelForm, "isLogin", val);
    },
    // 选中行
    handleSelectionChange(val) {
      this.selectRow = val;
    },
    // 多选框选中
    useTypeChoose(value) {
      this.$set(this.personnelForm, "type", value.join());
    },
    changeType(data) {
      console.log(data);
      this.personnelForm.type = data.join();
    },
    selectUnit() {
      console.log(this.personnelForm.type);
      if (!this.personnelForm.type) {
        this.$message.error("请先选择用户类型");
        return;
      }
      this.changeUnitType = "加入单位";
      this.unitChangeList = [];
      this.$nextTick(() => {
        let arr = [];
        this.personnelData = {
          type: this.personnelForm.type,
        };
        if (this.personnelForm.type.indexOf(",") > -1) {
          arr = this.personnelForm.type.split(",");
        } else {
          arr.push(this.personnelForm.type);
        }
        this.unitList.map((item) => {
          arr.map((item1) => {
            if (item.value === item1) {
              this.unitChangeList.push(item);
            }
          });
        });
        this.$refs.changeUnit.init(() => {
          this.onRefer();
        });
      });
    },
    getUnitInfo(data) {
      // console.log(data,'data');
      this.personnelForm.orgId = data.orgId;
      this.$set(this.personnelForm, "unitName", data.orgName);
    },
    // 获取人员方法
    onRefer() {
      const data = JSON.parse(JSON.stringify(this.searchForm));
      data.name = this.searchForm.name ? `*${this.searchForm.name}*` : "";
      data.type = this.searchForm.type ? `*${this.searchForm.type}*` : "";
      this.$http.post("/api/system/web/appUser/list", data).then((res) => {
        if (res.data.success) {
          const {
            data: { data },
          } = res;
          this.tableData = data.records ?? [];
          this.personnelTotal = data.total ?? 0;
          this.$nextTick(() => {
            if (this.personnelTotal > 0 && this.$refs.multipleTable) {
              this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
            }
          })
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 修改
    edit(item) {
      this.tit = "编辑人员";
      this.useType = [];
      this.orgType = "";
      const data = {
        id: item.id,
      };
      this.$http
        .get(`/api/system/web/appUser/detail?id=${item.id}`)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res;
            console.log(data.type, "data");
            this.orgType = data.orgType;
            this.personnelForm = data;
            this.useType = data.type ? data.type.split(",") : [];
            this.personnelForm.validityTime = [
              data.certificateStartTime,
              data.certificateEndTime,
            ];
            this.dialogVisible = true;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 删除
    del(id) {
      this.$confirm("确定要删除该人员吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.personId = id
        this.glEleList(10, 1, id)
      });
    },


    // 删除人员
    glEleList (size, current, id) {
      this.$http
      .get(`/api/ele/web/eleUser/removeAppUser?size=${size}&current=${current}&id=${id}`)
      .then((res) => {
        if (res.data.success && !res.data.data.records) {
          this.onRefer();
        } else {
          this.personVisible = true
          this.personList = res.data.data.records ?? []
          this.personListTotal = res.data.data.total ?? 0
          console.log(this.personList);
          this.$nextTick(() => {
            this.$refs.personTable.init(() => {
            });
          });
        }
      });
    },
    // 删除人员关联列表翻页
    changePage(data) {
      console.log(data,'data');
      this.glEleList(data.size, data.current, this.personId)
    },
    // 删除人员关联列表关闭
    colseEleTable (){
      this.personVisible = false
    },



    // 批量删除
    batchDel() {
      const ids = [];
      if (this.selectRow.length > 0) {
        this.selectRow.map((item) => {
          ids.push(item.id);
        });
        this.del(ids.join(","));
      } else {
        this.$message.warning("请选择要删除的对象");
      }
    },
    // 新增
    addDj() {
      this.tit = "添加人员";
      this.dialogVisible = true;
      this.personnelForm = {
        isLogin: 0,
      };
      this.useType = [];
      this.orgType = "";
    },
    // 下载模版
    dowMode() {
      downloadMode(8);
    },

    // 错误列表回调
    dioCloseB() {
      this.errorList = null;
    },
    // 导入
    toLead() {
      document.form1.docxFile.click();
    },
    // 提交导入
    readFile(file) {
      let _this = this;
      let docxFile = file.target.files[0];
      if (docxFile == undefined) {
        return;
      }
      let hasFile = false;
      let fileName = docxFile.name;
      let fileArr = file.target.files[0].name.split(".");
      let files = docxFile;
      if (!file) {
        this.$message("请先选择正确的文件类型上传");
      } else {
        let fileData = new FormData();
        fileData.append("file", files);
        fileData.append("type", files.type);
        let config = {
          onUploadProgress: (e) => {
            //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
            //如果lengthComputable为false，就获取不到e.total和e.loaded
            if (e.lengthComputable) {
              let rate = e.loaded / e.total; //已上传的比例
              if (rate < 1) {
                //这里的进度只能表明文件已经上传到后台，但是后台有没有处理完还不知道
                //因此不能直接显示为100%，不然用户会误以为已经上传完毕，关掉浏览器的话就可能导致上传失败
                //等响应回来时，再将进度设为100%
                let uploadRate = (rate * 100).toFixed(2) + "%";
              }
            }
          },
        };
        this.$http
          .post(`/api/system/web/batch/saveData/${pageType}`, fileData, config)
          .then((res) => {
            if (res.data.success) {
              this.onRefer();
              this.$message.success(res.data.msg);
            } else {
              this.errorList = res.data.data;
              this.$message.error(res.data.msg);
            }
            file.target.value = "";
          });
      }
    },
    // 更换单位
    change(row) {
      this.unitChangeList = [];
      this.changeUnitType = "更换单位";
      this.$nextTick(() => {
        this.personnelData = {
          ...row,
          unitName: "",
        };
        let arr = [];
        if (row.type.indexOf(",") > -1) {
          arr = row.type.split(",");
        } else {
          arr.push(row.type);
        }
        this.unitList.map((item) => {
          arr.map((item1) => {
            if (item.value === item1) {
              this.unitChangeList.push(item);
            }
          });
        });
        this.$refs.changeUnit.init(() => {
          this.onRefer();
        });
      });
    },
    
    // 关联电梯
    aboutEle (data) {
      console.log(data, 'data');
      this.userId = data.id
      this.unitId = data.orgId
      this.unitType = data.type
      this.eleAboutDialog = true
    },
    // 关联电梯弹框
    colseEleFrom() {
      this.eleAboutDialog = false
    },
    // 时间改变
    changeDate() {
      this.$forceUpdate();
    },
  },
  created() {
    this.getDictionar(["RELATED_CODES", "UNIT_LIST", 'USE_PLACE']);
  },
  mounted() {
    this.$nextTick(() => {
      this.onRefer();
    });
  },
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBtn {
  margin-left: 15px;
}

.wid90 {
  width: 95%;
}

/deep/.el-checkbox {
  color: #fff;
}

/deep/.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #c7c7c7;
}
</style>
