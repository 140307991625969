<template>
	<div class="dioBg">
		<div class="dioBg-om">
			<el-card>
				<span style="width: 100%;display: flex;justify-content: space-between; align-items: center;">
					电梯名称: {{dioData.eleName}}
					<el-button type="danger" size="mini">{{dioData.sosTypeName}}</el-button>
				</span>
				<p style="margin-top: 10px;">电梯注册代码: {{dioData.eleCode}}</p>
				<p v-if="dioData.sosTypeName != '自主申报' && dioData.sosTime">报警时间: {{dioData.sosTime}}</p>
				<p>维保单位: {{dioData.maintainUnitName}}</p>
				<p>物业单位: {{dioData.propertyUnitName}}</p>
				<p>所属小区: {{dioData.plotName}}</p>
				<p>地点: {{dioData.eleAddress}}</p>
				<p>确认人: {{dioData.createUserName}}</p>
				<p>确认时间: {{dioData.createTime}}</p>
			</el-card>
			<p>
				<span class="hpan">电梯信息</span>
			</p>
			<el-card>
				<p class="msgDiv">
					报警楼层: {{dioData.sosId ? dioData.eleSos.sosFloor : '暂无数据'}}
				</p>
				<p class="msgDiv">
					门状态&emsp;: {{dioData.sosId ? (dioData.eleSos.doorOpen == 1 ? '开' : '关') : '暂无数据'}}
				</p>
				<p class="msgDiv">
					运行速度: {{dioData.sosId ? `${dioData.eleSos.speed}m/s` : '暂无数据'}}
				</p>
				<p class="msgDiv">
					运行次数: {{dioData.sosId ? dioData.eleSos.runningNum : '暂无数据'}}
				</p>
				<p class="msgDiv">
					运行方向:
					{{ dioData.sosId ? (dioData.speed > 0 ? '上行': (dioData.speed < 0 ? '下行' : '静止')) : '暂无数据' }}
				</p>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['dioData']
	}
</script>

<style scoped>
	.showVo {
		width: 100%;
		height: 300px;
	}

	.msgDiv {
		width: 100%;
		display: flex;
	}

	.msgDiv span {
		width: 50%;
	}

	.hpan {
		color: white;
		margin-right: 5px;
	}

	.dioBg-om {
		width: 100%;
		margin-right: 2%;
	}

	.dioBg {
		width: 98%;
		height: 100%;
		display: flex;
	}
</style>
