<template>
	<div>
		<div class="page-top">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯名称</span>
					</span>
					<el-input v-model="formInline.eleName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">电梯注册代码</span>
					</span>
					<el-input v-model="formInline.eleCode" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">应急状态</span>
					</span>
					<el-select v-model="formInline.sosStatus" placeholder="请输入" class="einp" clearable>
						<el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">应急类型</span>
					</span>
					<el-select v-model="formInline.sosTypeOption" placeholder="请输入" class="einp" multiple clearable>
						<el-option v-for="item in typeDate.SOS_TYPE "  :key="item.value" :label="item.label"
						:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">所属小区</span>
					</span>
					<el-input v-model="formInline.plotName" placeholder="请输入" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<span slot="label">
						<span class="lab-span">查询时间</span>
					</span>
					<el-date-picker v-model="formInline.allTime" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期"
						class="einp">
					</el-date-picker>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onRefer(true)">查询</el-button>
					<el-button type="warning" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div v-if="dictionarTotal!=0">
			<el-table :data="tableData" ref="multipleTable" style="width: 100%;margin-bottom: 20px;"
      @sort-change="handleSort" :height="$store.state.tabHeight">
				<el-table-column type="index" label="序号" width="50px" />
				<el-table-column align='center' prop="eleName" label="电梯名称" width="200px" />
				<el-table-column align='center' label="报警时间" width="200px" prop="sosTime" sortable>
					<template slot-scope="scope">
						{{ scope.row.sosTime ? scope.row.sosTime : '-' }}
					</template>
				</el-table-column>
				<el-table-column align='center' prop="sosTypeName" label="应急类型" width="120px" >
					<template slot-scope="scope">
						{{scope.row.sosType == -1 ? scope.row.sosText : (scope.row.sosTypeName ? scope.row.sosTypeName : '未知')}}
					</template>
				</el-table-column>
				<el-table-column align='center' label="接警时间" width="200px" prop="callTime" sortable>
					<template slot-scope="scope">
						{{ scope.row.callTime ? scope.row.callTime : '-' }}
					</template>
				</el-table-column>
				<el-table-column align='center' label="到场时间" width="200px">
					<template slot-scope="scope">
						{{ scope.row.presentTime ? scope.row.presentTime : '-' }}
					</template>
				</el-table-column>
				<el-table-column align='center' label="完成时间" width="200px">
					<template slot-scope="scope">
						{{ scope.row.finishTime ? scope.row.finishTime : '-' }}
					</template>
				</el-table-column>
				<el-table-column align='center' label="报警状态">
					<template slot-scope="scope">
						{{  getState(scope.row.alarmState) }}
					</template>
				</el-table-column>
				<el-table-column align='center' label="所属小区" width="180px" >
					<template slot-scope="scope">
						{{scope.row.plotName ? scope.row.plotName : '-'}}
					</template>
				</el-table-column>
				<el-table-column align='center' prop="sosFloor" label="报警楼层" width="100px" />
				<el-table-column align='center' label="所用时长" width="200px">
					<template slot-scope="scope">
						{{ scope.row.useTime ? scope.row.useTime : '-' }}
					</template>
				</el-table-column>
				<el-table-column align='center' prop="sosStatusName" label="应急状态" width="100px" />
				<el-table-column align='center' prop="eleCode" label="电梯注册码" width="200px" />
				<el-table-column align='center' prop="propertyUnitName" label="物业单位" width="200px" />
				<el-table-column align='center' prop="maintainUnitName" label="维保单位" width="200px" />
				<el-table-column align='center' prop="maintainUsers" label="维保人员" width="200px" >
					<template slot-scope="scope">
						{{ scope.row.maintainUsers ? scope.row.maintainUsers.split(';').slice(0,2).join() : '-' }}
					</template>
				</el-table-column>
				<el-table-column align='center' prop="eleAddress" label="电梯安装位置" width="250px" />
				<el-table-column fixed="right" align='center' prop="address" label="操作" width="120px">
					<template slot-scope="scope">
						<el-button v-focus @click="showDetails(scope.row)" type="primary" size="small">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :total="dictionarTotal" :page.sync="formInline.current" :limit.sync="formInline.size"
				@pagination="dictionarPage" />
		</div>
		<div class="nullDate" v-else>
			<img src="@/assets/nullDate.png" style="width: 260px;" />
		</div>

		<el-dialog v-if='dioDetails' :close-on-click-modal='false' :visible.sync="dioDetails" width="1200px" top='15vh' @close="closeDialog">
			<div class="tabBut">
				<!-- <template v-for="item in detailsItem.seleArr">
					<el-button @click="detailsItem.seleId=item.value"
						:type='item.value==detailsItem.seleId?"primary":""'>{{item.label}}</el-button>
				</template> -->
				<el-button @click="detailsItem.seleId=1" :type='1 == detailsItem.seleId ? "primary" : ""'>报警消息
				</el-button>
				<el-button v-show="dioDetailsItem.sosTypeName == '安全回路报警' || dioDetailsItem.sosTypeName == '电梯运行异常告警'" @click="detailsItem.seleId=2"
					:type='2 == detailsItem.seleId ? "primary" : ""'>运行参数</el-button>
				<el-button @click="detailsItem.seleId=3" :type='3==detailsItem.seleId ? "primary" : ""'>处置流程</el-button>
				<el-button v-if="dioDetailsItem.voiceRescordDTOS.length > 0 || dioDetailsItem.eleSmsRescordDTOS.length > 0" @click="detailsItem.seleId=4" :type='4==detailsItem.seleId ? "primary" : ""'>报警通知</el-button>
			</div>
			<div style="height: 55vh;margin-top: 10px;" class="scrollElement"
				:class="[detailsItem.seleId==1?'yeScroll':'noScroll']">
				<alarmMessage v-if="detailsItem.seleId==1" :dioData="dioDetailsItem" />
				<operational v-else-if="detailsItem.seleId==2" :dioData="dioDetailsItem" />
				<process v-else-if="detailsItem.seleId==3" :dioData="dioDetailsItem" />
				<AlarmNotice v-else :dioData="dioDetailsItem" />
			</div>
			<span slot="footer" class="dialog-footer"
				style="display: flex;align-items: center;justify-content: center;">
				<div v-if="detailsItem.seleId == 1 && dioDetailsItem.sosStatus === 1">
					<template  v-if="
						dioDetailsItem.sosType == 95 ||
						dioDetailsItem.sosType == 0 ||
						dioDetailsItem.sosType == 10 ||
						dioDetailsItem.sosType == 7 ||
						dioDetailsItem.sosType == 3 ||
						dioDetailsItem.sosType == 4 ||
						dioDetailsItem.sosType == 5 ||
						dioDetailsItem.sosType == 11 ||
						dioDetailsItem.sosType == 12
					">
						<el-button  size="mini" type="primary" @click="sosClick(1)" :disabled="disabled">
							<span>人为触发(无故障)</span>
							<p style="margin: 10px 0 0 0;">确认关闭</p>
						</el-button>
						<el-button size="mini" type="info" @click="sosClick(2)" :disabled="disabled">
							<span>电梯故障(无困人)</span>
							<p style="margin: 10px 0 0 0;">派发维修工单</p>
						</el-button>
						<el-button  size="mini" type="warning" @click="sosClick(3)" :disabled="disabled">
							<span>困人</span>
							<p style="margin: 10px 0 0 0;">立即派发救援工单</p>
						</el-button>
						<el-button class="errorSos" size="mini" @click="sosClick(5)" :disabled="disabled">
							<span>误报</span>
							<p style="margin: 10px 0 0 0;">设备产生错误报警</p>
						</el-button>
					</template>
				</div>
			</span>
		</el-dialog>

	</div>
</template>
<script>
	import process from './components/process'
	import operational from './components/operational'
	import alarmMessage from './components/alarmMessage'
	import AlarmNotice from './components/alarmNotice'
	import Pagination from '@/components/Pagination'
	import { formatDate } from "@/util";

	let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
	let current_time1 = new Date(current_time).getTime();
	let starTime = new Date(current_time1 - 30 * 24 * 60 * 60 * 1000);
	let endTime = new Date(current_time1);

	export default {
		components: {
			process,
			Pagination,
			operational,
			alarmMessage,
			AlarmNotice
		},
		data() {
			return {
				// 报警详情
				detailsItem: {
					seleId: 1,
					seleArr: [{
						value: 1,
						label: '报警消息'
					}, {
						value: 2,
						label: '运行参数'
					}, {
						value: 3,
						label: '处置流程'
					}, {
						value: 4,
						label: '报警通知'
					}]
				},
				dioDetails: false,
				dioDetailsItem: null,
				// 表格参数
				formInline: {
					current: 1,
					size: 10,
       				allTime: [starTime, endTime],
				},
				tableData: [],
				// 分页参数
				dictionarTotal: 0,
				// 状态
				typeDate: [], //字典表
				stateList: [{
					value: 1,
					label: '待处理'
				}, {
					value: 2,
					label: '已处理'
				}, {
					value: 3,
					label: '进行中'
				}, {
					value: 4,
					label: '已完成'
				}],
				disabled: false
			}
		},
		methods: {
			//关闭弹框
			closeDialog(){
				this.disabled = false
			},
			// 详情操作按钮
			sosClick(type) {
				this.disabled = true
				if (this.dioDetailsItem.submitType === 0) {
				this.dioDetailsItem.sosStatus = 2
				this.$http
					.post("/api/ele/app/sos/submit", {
						id: this.dioDetailsItem.id,
						submitType: type,
						sosTime: this.dioDetailsItem.sosTime,
					})
					.then((res) => {
						if (res.data.success) {
							this.$message.success(res.data.msg)
						} else {
							this.$message.error(res.data.msg)
						}
						this.onRefer()

						this.showDetails(this.tableData.find(item => item.id === this.dioDetailsItem.id))
					})
				}
			},
			// 详情
			showDetails(item) {
				this.$http
					.post("/api/ele/web/sos/getInfo", {
						id: item.id,
						sosTime: item.sosTime ? item.sosTime : ''
					})
					.then((res) => {
						if (res.data.success) {
							res.data.data.sosVideo.forEach((qs) => {
								let url = qs.videoUrl.split('?');
								qs.videoUrl = url[0]
								qs.videoName = url[1].split('&')[0].split('=')[1]
							})
							res.data.data.sosTypeName = item.sosType == -1 ? (item.sosText != '' ? item.sosText : '未知') : (item.sosTypeName != '' ? item.sosTypeName : '未知')
							res.data.data.sosStatusName = this.stateList.find(item => item.value === res.data.data
									.sosStatus) ? this.stateList.find(item => item.value === res.data.data.sosStatus)
								.label : ''
							res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
							res.data.data.speedarr = res.data.data.speedRecord.split(',');

							this.detailsItem.seleId = 1
							this.dioDetailsItem = res.data.data
							this.dioDetailsItem.emergencyNames = res.data.data.emergencyRescueUserNames.split(';')
							this.dioDetailsItem.maintainNames = res.data.data.maintainUsers.split(';')
							this.dioDetailsItem.propertyNames = res.data.data.propertyUsers.split(';')
							// console.log(res.data.data.emergencyRescueUserNames,'res.data.data.emergencyRescueUserNames');
							this.dioDetails = true
            				console.log(this.dioDetailsItem,'this.dioDetailsItem');
						} else {
							this.$message.error(res.data.msg)
						}
					})

			},
			// 重置按钮事件
			reset() {
				this.formInline = {
					current: 1,
					size: 10,
       				allTime: [starTime, endTime]
				}
				this.onRefer()
			},
			// 分页
			dictionarPage(data) {
				this.formInline.current = data.page
				this.formInline.size = data.limit
				this.onRefer()
			},
			// 获取类型
			getType(type, sta) {
				let obj
				let arr = []
				if (sta == 1) {
					arr = this.typeDate.SOS_TYPE
				} else {
					arr = this.stateList
				}
				arr.forEach((qs) => {
					if (qs.value == type) {
						obj = qs.label
					}
				})
				return obj
			},
			getState(type){
				console.log(type,'type');
				let obj = '-'
				switch (type) {
					case 0:
						obj = '待处理'
						break;
					case 1:
						obj = '误报'
						break;
					case 2:
						obj = '故障'
						break;
					case 3:
						obj = '困人'
						break;
					case 4:
						obj = '人为触发'
						break;
				
					default:
						break;
				}
				return obj
			},
      //排序
      handleSort(column, prop, order){
        console.log('column',column)
        console.log('prop',prop)
        console.log('order',order)
        if(column.prop == 'sosTime'){
          this.formInline.sosTimeSortSign = column.order === 'descending' ? 'DESC' : 'ASC'
        }else if(column.prop == 'callTime'){
          this.formInline.callTimeSortSign = column.order === 'descending' ? 'DESC' : 'ASC'
        }
        this.onRefer()
      },
			// 表格方法
			onRefer(ishm) {
				if (ishm) {
					this.formInline.current = 1
				}
				if (this.formInline.allTime) {
					let atime = JSON.parse(JSON.stringify(this.formInline.allTime))

					if (new Date(atime[0]).getTime() < new Date('2022-12-01').getTime()) {
						this.$message.error('查询时间不得早于2022年12月')
						return
					}
					if (new Date(atime[1]).getTime() > new Date(new Date().toLocaleDateString()).getTime()) {
						this.$message.error('查询时间不得晚于当天')
						return
					}
					this.formInline.start = new Date(atime[0]).getTime()
					this.formInline.end = new Date(atime[1]).getTime() + 24 * 60 * 60 * 1000 - 1000
				} else {
					this.$message.error('请选择查询时间')
					return
				}
				this.formInline.sosTypeStr = this.formInline.sosTypeOption && this.formInline.sosTypeOption.length > 0 ? this.formInline.sosTypeOption.join(',') : ''
				console.log(this.formInline,'this');
				this.$http
					.post("/api/ele/web/sos/getList", this.formInline)
					.then((res) => {
						if (res.data.success) {
							res.data.data.records.forEach((qs) => {
								qs.sosTypeName = this.getType(qs.sosType, 1)
								qs.sosStatusName = this.getType(qs.sosStatus, 2)
							})
							this.tableData = res.data.data.records
							this.dictionarTotal = res.data.data.total
							this.$nextTick(() => {
								if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
								this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})
			},
		},
		created() {
			this.$http
				.post(`/api/system/web/dict/dictionary?codes=SOS_TYPE`)
				.then((res) => {
					if (res.data.success) {
						this.typeDate = res.data.data
						this.onRefer()
					}
				})
		}
	}
</script>
<style scoped>
	.errorSos{
		background: #00dcff;
		border-color: #00dcff;
		color: #fff
	}
	.yeScroll {
		overflow-y: scroll;
	}

	.noScroll {
		overflow-y: none;
	}

	.tabBut {
		width: 80%;
		margin-left: 10%;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.page-top {
		width: 100%;
		margin-bottom: 20px;
	}
	.page-bom {}
</style>
