<template>
  <el-dialog
			v-if="eleAboutDialog"
			:title="title"
      append-to-body
			:visible.sync="eleAboutDialog"
			:close-on-click-modal="false"
			width="1400px"
			top="10vh"
      @close="closeDialog" 
			>
    <div
        v-if="eleType == 'unitType' || eleType == 'otherType'"
        style="height: 67vh; overflow-y: scroll; padding-right: 20px"
        class="scrollElement"
      >
      <template v-if="status == 2">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item>
            <span slot="label">
              <span class="lab-span">电梯名称</span>
            </span>
            <el-input v-model="formInline.name" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">救援识别码</span>
            </span>
            <el-input v-model="formInline.rescueCode" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">电梯注册代码</span>
            </span>
            <el-input v-model="formInline.code" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onRefer(true)">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <div v-if="total!=0">
          <el-table ref="multipleTable" :data="tableData" @select="handleSelectionChange"
          @select-all="selectAll" style="width: 100%;margin-bottom: 20px;" max-height="500px"
            :height="$store.state.tabHeight" >
              <el-table-column type="selection" width="55px"/>
              <el-table-column align='center' type="index" label="序号" width="50px" />
              <el-table-column align='center' prop="name" label="电梯名称" width="150px"/>
              <el-table-column align='center' prop="code" label="电梯注册代码" width="150px"/>
              <el-table-column align='center' prop="rescueCode" label="救援识别码" width="150px"/>
              <el-table-column align='center' prop="deviceCode" label="AI摄像头ID" width="150px"/>
              <el-table-column align='center' prop="terminalId" label="电梯数字终端ID" width="150px"/>
              <el-table-column align='center' prop="plotName" label="所属小区" width="150px"/>
              <el-table-column align='center' label="使用场所" width="150px">
                <template slot-scope="scope">
                  {{
                    scope.row.elePlaceType ? 
                      (elePlaceList.find(item => item.value == scope.row.elePlaceType) ?
                        elePlaceList.find(item => item.value == scope.row.elePlaceType).label : '-')
                    : '-'
                  }}
                </template>
              </el-table-column>
              <el-table-column align='center' prop="propertyUnitName" label="物业单位" width="150px"/>
              <el-table-column align='center' prop="propertyUnitUserNames" label="物业安全员" width="150px"/>
              <el-table-column align='center' prop="maintainUnitName" label="维保单位" width="150px"/>
              <el-table-column align='center' prop="maintainUserNames" label="维保人员" width="150px"/>
              <el-table-column align='center' prop="insuranceUnitName" label="保险单位" width="150px"/>
              <el-table-column align='center' prop="insuranceUnitUserNames" label="保险人员" width="150px"/>
              <el-table-column align='center' prop="createUserName" label="楼层学习">
                <template slot-scope="scope">
                  {{
                    scope.row.isInit ? '已完成' : '未完成'
                  }}
                </template>
              </el-table-column>
              <el-table-column align='center' prop="eleAddress" label="电梯安装位置" width="200px"/>
              <el-table-column align='center' prop="createTime" label="添加时间"/>
              <el-table-column align='center' prop="createUserName" label="创建人" />
              <el-table-column align="center" label="识别状态" width="120px" >
                <template slot-scope="scope">
                  {{
                    scope.row.recognitionState ? 
                      (recongtion.find(item => item.value == scope.row.recognitionState) ?
                        recongtion.find(item => item.value == scope.row.recognitionState).label : '-')
                    : '-'
                  }}
                </template>
              </el-table-column>
          </el-table>
          <Pagination ref="pageCom" :total="total" :page.sync="formInline.current"
            :limit.sync="formInline.size" @pagination="dictionarPage" />
        </div>
        <div class="nullDate" v-else>
          <img src="@/assets/nullDate.png" style="width: 260px;" />
        </div>
      </template>
      <template v-else>
        <el-form :inline="true" :model="formInPersonline" class="demo-form-inline">
          <el-form-item>
            <span slot="label">
              <span class="lab-span">人员名称</span>
            </span>
            <el-input v-model="formInPersonline.name" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getPerson(true)">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <div v-if="total!=0">
        
        <el-table ref="personTable" :data="personData" @select="handleSelectionChange" @select-all="selectAll"
          style="width: 100%;margin-bottom: 20px;" max-height="500px"
            :height="$store.state.tabHeight" >
              <el-table-column type="selection" width="55px"/>
              <el-table-column type="index" align="center" label="序号" width="50px" />
              <el-table-column align='center' prop="name" label="人员名称" />
              <el-table-column align='center' prop="orgName" label="所属单位" />
              <el-table-column
                align="center"
                prop="fileUrl"
                label="是否允许登录"
                width="150px"
              >
                <template slot-scope="scope">
                  <el-switch
                    disabled
                    v-model="scope.row.isLogin"
                    :active-value="0"
                    :inactive-value="1"
                    active-color="#409eff"
                    inactive-color="#909399"
                  >
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column align='center' label="角色" >
                <template slot-scope="scope">
                  {{
                    relaredList.find((item) => item.value === scope.row.type)
                      ? relaredList.find(
                          (item) => item.value === scope.row.type
                        ).label
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column align='center' prop="contactWay" label="手机号码" />
              <el-table-column align='center' prop="account" label="app账号" />
              <el-table-column align='center' prop="oriPassword" label="app密码" />
              <el-table-column align='center' prop="certificateCode" label="证书编号" />
              <el-table-column align='center' prop="createTime" label="添加时间" width='200px' />
              <el-table-column align='center' prop="createUserName" label="创建人" />
          </el-table>
          <Pagination ref="pageCom" :total="personTotal" :page.sync="formInPersonline.current"
            :limit.sync="formInPersonline.size" @pagination="dictionarPage" />
        </div>
        <div class="nullDate" v-else>
          <img src="@/assets/nullDate.png" style="width: 260px;" />
        </div>
      </template>
    </div>
    <div
        v-else
        style="height: 67vh; overflow-y: scroll; padding-right: 20px"
        class="scrollElement"
      >
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item>
            <span slot="label">
              <span class="lab-span">电梯名称</span>
            </span>
            <el-input v-model="formInline.name" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">救援识别码</span>
            </span>
            <el-input v-model="formInline.rescueCode" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <span slot="label">
              <span class="lab-span">电梯注册代码</span>
            </span>
            <el-input v-model="formInline.code" placeholder="请输入" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onRefer(true)">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <div v-if="total!=0">
          <el-table ref="multipleTable" :data="tableData" @select="handlePensonChange" @select-all="selectPensonAll" 
            style="width: 100%;margin-bottom: 20px;" max-height="500px"
            :height="$store.state.tabHeight" >
              <el-table-column type="selection" width="55px"/>
              <el-table-column align='center' type="index" label="序号" width="50px" />
              <el-table-column align='center' prop="name" label="电梯名称" width="150px"/>
              <el-table-column align='center' prop="code" label="电梯注册代码" width="150px"/>
              <el-table-column align='center' prop="rescueCode" label="救援识别码" width="150px"/>
              <el-table-column align='center' prop="deviceCode" label="AI摄像头ID" width="150px"/>
              <el-table-column align='center' prop="terminalId" label="电梯数字终端ID" width="150px"/>
              <el-table-column align='center' prop="plotName" label="所属小区" width="150px"/>
              <el-table-column align='center' label="使用场所" width="150px">
                <template slot-scope="scope">
                  {{
                    scope.row.elePlaceType ? 
                      (elePlaceList.find(item => item.value == scope.row.elePlaceType) ?
                        elePlaceList.find(item => item.value == scope.row.elePlaceType).label : '-')
                    : '-'
                  }}
                </template>
              </el-table-column>
              <el-table-column align='center' prop="propertyUnitName" label="物业单位" width="150px"/>
              <el-table-column align='center' prop="propertyUnitUserNames" label="物业安全员" width="150px"/>
              <el-table-column align='center' prop="maintainUnitName" label="维保单位" width="150px"/>
              <el-table-column align='center' prop="maintainUserNames" label="维保人员" width="150px"/>
              <el-table-column align='center' prop="insuranceUnitName" label="保险单位" width="150px"/>
              <el-table-column align='center' prop="insuranceUnitUserNames" label="保险人员" width="150px"/>
              <el-table-column align='center' prop="createUserName" label="楼层学习">
                <template slot-scope="scope">
                  {{
                    scope.row.isInit ? '已完成' : '未完成'
                  }}
                </template>
              </el-table-column>
              <el-table-column align='center' prop="eleAddress" label="电梯安装位置" width="200px"/>
              <el-table-column align='center' prop="createTime" label="添加时间"/>
              <el-table-column align='center' prop="createUserName" label="创建人" />
              <el-table-column align="center" label="识别状态" width="120px" >
                <template slot-scope="scope">
                  {{
                    scope.row.recognitionState ? 
                      (recongtion.find(item => item.value == scope.row.recognitionState) ?
                        recongtion.find(item => item.value == scope.row.recognitionState).label : '-')
                    : '-'
                  }}
                </template>
              </el-table-column>
          </el-table>
          <Pagination ref="pageCom" :total="total" :page.sync="formInline.current"
            :limit.sync="formInline.size" @pagination="dictionarPage" />
        </div>
        <div class="nullDate" v-else>
          <img src="@/assets/nullDate.png" style="width: 260px;" />
        </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <!-- 单位关联 -->
      <template v-if="eleType == 'unitType' || eleType == 'otherType'">
        <template v-if="eleType == 'otherType'">
          <el-button size="small" type="primary" @click="dioSub">确 定</el-button>
          <el-button size="small" @click="closeDialog">取 消</el-button>
        </template>
        <template v-else>
          <template v-if="status == 1">
            <el-button size="small" @click="back">上一步</el-button>
            <el-button size="small" type="primary" @click="dioSub">确 定</el-button>
          </template>
          <template v-else>
            <el-button v-if="total!=0" size="small" type="primary" @click="next">下一步</el-button>
            <el-button v-else size="small" @click="closeDialog">取 消</el-button>
          </template>
        </template>
      </template>
      <!-- 人员关联 -->
      <template v-else>
          <el-button size="small" type="primary" @click="dioSubPerson">确 定</el-button>
          <el-button size="small" @click="closeDialog">取 消</el-button>
      </template>
    </div>
  </el-dialog>
</template>
<script>
import Pagination from '@/components/Pagination'
export default {
  components: {
    Pagination,
  },
  // 
  props: ['tableDataArr', 'unitType', 'eleType', 'unitId', 'userType', 'elePlaceList', 'recongtion', 'userId', 'relaredList'],
  data() {
    return {
      title: '添加电梯',
      formInline: {
        current: 1,
        size: 10
      },
      tableData: [], // 关联电梯列表
      formInPersonline: {
        current: 1,
        size: 10
      },
      total: 0,
      personData: [], // 关联人员列表
      personTotal: 0,
      eleAboutDialog: false,
      status: 2, // 关联电梯步骤  2上一步 1 下一步
      eleAbout: [], // 勾选关联电梯
      personAbout: [], // 勾选关联电梯

      noBandList: []
    };
  },
  filters: {},
  methods: {
    // 重置按钮事件
    reset () {
      console.log('重置')
      if (this.status == 2) {
        this.formInline = {
          current: 1,
          size: 10
        }
        this.onRefer()
      } else {
        this.formInPersonline = {
          current: 1,
          size: 10
        }
        this.getPerson()
      }
    },
    // 分页
    dictionarPage (data) {
      console.log('分页触发了')
      if (this.status == 2) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      } else {
        this.formInPersonline.current = data.page
        this.formInPersonline.size = data.limit
        this.getPerson()
      }
    },
    // 选中数据
    handleSelectionChange (val, row) {
      if (this.status == 2) {
        if (this.eleAbout.find((item) => item.id === row.id)) {
          this.eleAbout = this.eleAbout.filter((item) => item.id !== row.id)
        } else {
          this.eleAbout.push(row)
        }
      } else {
        if (this.personAbout.find((item) => item.id === row.id)) {
          this.personAbout = this.personAbout.filter((item) => item.id !== row.id)
        } else {
          this.personAbout.push(row)
        }
      }
    },
    selectAll(selection) {
      if (this.status == 2) {
        let stateArr = [];
				if (selection.length > 0) {
					stateArr = JSON.parse(JSON.stringify(selection));
					const arr = [...selection, ...this.eleAbout];
					// 去重
					const res = new Map();
					this.eleAbout = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1));
				} else {
					stateArr = JSON.parse(JSON.stringify(this.tableData));
					stateArr.map((item) => {
					if (this.eleAbout.find((row) => row.id === item.id)) {
						this.eleAbout = this.eleAbout.filter((row) => row.id !== item.id);
					}
					});
				}
      } else {
        let statePerArr = [];
				if (selection.length > 0) {
					statePerArr = JSON.parse(JSON.stringify(selection));
					const arr = [...selection, ...this.personAbout];
					// 去重
					const res = new Map();
					this.personAbout = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1));
				} else {
					statePerArr = JSON.parse(JSON.stringify(this.tableData));
					statePerArr.map((item) => {
					if (this.personAbout.find((row) => row.id === item.id)) {
						this.personAbout = this.personAbout.filter((row) => row.id !== item.id);
					}
					});
				}
      }
    },
    handlePensonChange (val, row) {
      if (this.noBandList.find((item) => item.id === row.id)) {
        this.noBandList = this.noBandList.filter((item) => item.id !== row.id)
      } else {
        this.noBandList.push(row)
      }
    },
    selectPensonAll(selection) {
      let selectPerArr = [];
      if (selection.length > 0) {
        selectPerArr = JSON.parse(JSON.stringify(selection));
        const arr = [...selectPerArr, ...this.noBandList];
        // 去重
        const res = new Map();
        this.noBandList = arr.filter((a) => !res.has(a.id) && res.set(a.id, 1));
      } else {
        selectPerArr = JSON.parse(JSON.stringify(this.tableData));
        selectPerArr.map((item) => {
        if (this.noBandList.find((row) => row.id === item.id)) {
          this.noBandList = this.noBandList.filter((row) => row.id !== item.id);
        }
        });
      }
    },
    // 表格方法
    onRefer (ishm) {
      if (ishm) {
        this.formInline.current = 1
      }
      const data = JSON.parse(JSON.stringify(this.formInline))

      if (this.eleType != 'unitType') {
        switch (this.unitType) {
          case 'MAINTAIN_UNIT':
            data.maintainUnitId = this.unitId
            break;
          case 'TENEMENT_UNIT':
            data.propertyUnitId = this.unitId
            break;
          case 'INSURANCE_UNIT':
            data.insuranceUnitId = this.unitId
            break;
          case 'RESCUE_UNIT':
            data.emergencyRescueUnitId = this.unitId
            break;
        }
      }
      data.isBandEleIdList = []
      if (this.tableDataArr && this.tableDataArr.length > 0) {
        this.tableDataArr.map(item => {
          data.isBandEleIdList.push(item.id)
        })
      }
      this.$http
        .post("api/ele/web/elevatorInfo/getEleUserPage", data)
        .then((res) => {
          console.log('列表', res.data)
          this.tableData = res.data.data.records
          this.total = res.data.data.total
          this.$nextTick(() => {
            if (this.total > 0 && this.$refs.multipleTable) {
              this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
            }
            if (this.tableData.length > 0) {
              this.tableData.map((ele) => {
                this.eleAbout.map((item) => {
                  if (item.code === ele.code) {
                    this.$refs.multipleTable.toggleRowSelection(ele, true)
                  }
                })
              })
            }
          })
        })
    },
    // 弹出框提交
    dioSub() {
      if (this.eleType == 'otherType') {
        console.log(this.unitId, 'this.unitId');
        const otherData = {}
        otherData.registrationAuthorityId = this.userId
        otherData.eleIdList = []
        this.eleAbout.map((item) => {
          otherData.eleIdList.push(item.id)
        })
        this.$http
          .post('/api/ele/web/elevatorInfo/updateElevatorAndOrgUser', otherData)
          .then((res) => {
            if (res.data.success) {
              this.closeDialog();
            } else {
              this.$message.error(res.data.msg)
            }
          })
      } else {
        if (this.personAbout && this.personAbout.length > 0) {
          const data = {}
          // 单位关联
          switch (this.unitType) {
            case 'MAINTAIN_UNIT':
              data.maintainUnitId = this.unitId
              break;
            case 'TENEMENT_UNIT':
              data.propertyUnitId = this.unitId
              break;
            case 'INSURANCE_UNIT':
              data.insuranceUnitId = this.unitId
              break;
            case 'RESCUE_UNIT':
              data.emergencyRescueUnitId = this.unitId
              break;
          }
          data.userType = this.unitType
          data.eleIdList = []
          data.userIdList = []
          this.eleAbout.map((item) => {
            data.eleIdList.push(item.id)
          })
          this.personAbout.map((item) => {
            data.userIdList.push(item.id)
          })
          console.log(data, 'data');
          this.$http
            .post('/api/ele/web/elevatorInfo/updateElevatorAndOrgUser', data)
            .then((res) => {
              if (res.data.success) {
                this.closeDialog();
              } else {
                this.$message.error(res.data.msg)
              }
            })
        } else {
          this.$message.error('请勾选关联的人员')
        }
      }
    },
    // 人员绑定电梯
    dioSubPerson () {
      const data = {}
      data.userId = this.userId
      data.userType = this.unitType
      data.noBandEleIdList = []
      if (this.noBandList && this.noBandList.length > 0) {
      // if (this.personAbout && this.personAbout.length > 0) {
        this.noBandList.map((item) => {
          data.noBandEleIdList.push(item.id)
        })
        console.log(data,'data');
        this.$http
          .post('/api/ele/web/elevatorInfo/setElevatorUser', data)
          .then((res) => {
            if (res.data.success) {
              this.closeDialog();
            } else {
              this.$message.error(res.data.msg)
            }
          })
      } else {
        this.$message.error('请勾选关联的电梯')
      }
    },
    //上一步
    back() {
      this.title = '添加电梯'
      this.status = 2
      this.onRefer()
    },
    // 下一步
    next() {
      if (this.eleAbout.length == 0) {
        this.$message.error('请勾选关联的电梯')
        return
      }
      this.title = '绑定人员'
      this.status = 1
      this.getPerson()
    },
    // 绑定人员
    getPerson (ishm) {
      if (ishm) {
        this.formInPersonline.current = 1
      }
      const data = JSON.parse(JSON.stringify(this.formInPersonline))
        data.name = this.formInPersonline.name ? `*${this.formInPersonline.name}*` : ''
        data.orgId = this.unitId
				this.$http
					.post('/api/system/web/appUser/getPage', data)
					.then((res) => {
						if (res.data.success) {
							this.personTotal = res.data.data.total
							this.personData = res.data.data.records ?? []
							this.$nextTick(() => {
								if (this.personTotal.length > 0 && this.$refs.multipleTable) {
									this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
								}
							})
						} else {
							this.$message.error(res.data.msg)
						}
					})
    },
    
    // 关闭弹框
    closeDialog() {
      this.eleAboutDialog = false;
      this.$emit("colseAddEle");
    },
  },
  created() {
    this.eleAboutDialog = true
    this.onRefer()
  },
  mounted() {
    this.$nextTick(() => {
    });
  },
  destroyed(){
    console.log('销毁');
  }
};
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBtn {
  margin-left: 15px;
}

.wid90 {
  width: 95%;
}

/deep/.el-checkbox {
  color: #fff;
}

/deep/.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #c7c7c7;
}
</style>
